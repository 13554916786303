/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, Group, NumberInput, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

interface DigitalCilindricoFormItemProps {
  form: UseFormReturnType<any>;
}

export function DigitalCilindricoEditItem({
  form,
}: DigitalCilindricoFormItemProps) {
  return (
    <>
      <Text size="sm" fw="normal">
        Tamanho da gravação
      </Text>
      <Group grow>
        <NumberInput
          label="Altura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="height"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.height')}
        />
        <NumberInput
          label="Largura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="width"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.width')}
        />
      </Group>
      <Checkbox
        label="Tem aplicação de verniz ?"
        color="green"
        name="hasVarnish"
        mb={16}
        checked={form.values.details.hasVarnish}
        {...form.getInputProps('details.hasVarnish')}
      />
      <Checkbox.Group
        defaultValue={['frente']}
        label="Local da gravação"
        description="você pode selecionar mais de um"
        withAsterisk
        name="personalizationSides"
        mb={16}
        {...form.getInputProps('details.personalizationSides')}
      >
        <Checkbox value="frente" label="Frente" color="green" />
        <Checkbox value="verso" label="Verso" color="green" />
      </Checkbox.Group>
    </>
  );
}

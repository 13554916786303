import { ThemeIcon, NavLink } from '@mantine/core';
import { IconPackage } from '@tabler/icons';
import { AppRoutePaths } from '../../../utils/enums';

interface OrderMaterialsLinkProps {
  navigate(to: string): void;
  themeColor: 'dark' | 'light';
}

export function OrderMaterialsLink({
  navigate,
  themeColor,
}: OrderMaterialsLinkProps) {
  return (
    <NavLink
      styles={{
        label: {
          color: themeColor === 'light' ? '#373A40' : '#FBFBFB',
        },
      }}
      label="ARs"
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="green.8" variant="light">
          <IconPackage size={16} />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      <NavLink
        label="Nova"
        onClick={() => navigate(AppRoutePaths.ORDER_MATERIALS_CREATE)}
      />
      <NavLink
        label="Listagem"
        onClick={() => navigate(AppRoutePaths.ORDER_MATERIALS)}
      />
      <NavLink
        label="Pendentes"
        onClick={() => navigate(AppRoutePaths.ORDER_MATERIALS_PENDING)}
      />
      <NavLink
        label="Entradas"
        onClick={() => navigate(AppRoutePaths.ORDER_MATERIALS_ENTRIES)}
      />
    </NavLink>
  );
}

import { AxiosResponse } from 'axios';
import { ProductEntry, ProductEntryResponse } from '../../models/product-entry';
import { api } from '../../providers/base-api';
import {
  CreateProductEntryRequestData,
  ListProductEntryRequestQuery,
  UpdateProductEntryRequestData,
} from '../../utils/types/data/services/product-entries';
import { PaginatedResponse } from '../../utils/types';

export async function createProductEntryRequest(
  data: CreateProductEntryRequestData,
): Promise<AxiosResponse<ProductEntry>> {
  const response = await api.post<ProductEntry>(`product-entry`, data);

  return response;
}

export async function updateProductEntryRequest(
  id: number,
  data: UpdateProductEntryRequestData,
): Promise<AxiosResponse<ProductEntry>> {
  const response = await api.patch<ProductEntry>(`product-entry/${id}`, data);

  return response;
}

export async function showProductEntryRequest(
  id: number,
): Promise<AxiosResponse<ProductEntry>> {
  const response = await api.get<ProductEntry>(`product-entry/${id}`);

  return response;
}

export async function listProductEntryRequest(
  query: ListProductEntryRequestQuery,
): Promise<AxiosResponse<PaginatedResponse<ProductEntryResponse>>> {
  const response = await api.get<PaginatedResponse<ProductEntryResponse>>(
    `product-entry`,
    {
      params: query,
    },
  );

  return response;
}

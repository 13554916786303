import { Badge, Button, Flex, Group, Paper, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DataTable } from 'mantine-datatable';
import { useEffect, useRef, useState } from 'react';

import { CustomDateRangePicker } from '../../components/CustomDateRangePicker';
import { Page } from '../../components/Page';
import { useGetActivityReport } from '../../data/hooks/activities';
import {
  getFilterCompaniesListRequest,
  getFilterUsersListRequest,
} from '../../data/services/filters';
import { getTeamsRequest } from '../../data/services/teams';
import { Team } from '../../models/team';
import { UserRole } from '../../models/user';
import { dayjsPlugins } from '../../providers/dayjs-plugins';
import { errorNotification } from '../../providers/mantine-notifications';
import {
  activityComplexityColor,
  activityComplexityHumanized,
  activityStatusColor,
  activityStatusHumanized,
} from '../../utils/constants/activity';

import { IconDownload } from '@tabler/icons';
import { ExportableModal } from '../../components/ExportableModal';
import { useArtFinalListExportable } from '../../data/hooks/exportables';
import { useFileDownload } from '../../hooks/use-file-download';
import { formatDateTime } from '../../utils/helpers';
import {
  GetFilterCompaniesListResponse,
  GetFilterUsersListResponse,
} from '../../utils/types/data/services/filters';

export type PageModalState = 'exportable-art-final' | null;

export function ArtReport() {
  const page = useRef(1);
  const limit = useRef(10);
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const [teamList, setTeamList] = useState<Team[]>([]);
  const [pageModal, setPageModal] = useState<PageModalState>(null);
  const [filterUserList, setFilterUserList] = useState<
    GetFilterUsersListResponse[]
  >([]);
  const [filteredArtFinal, setFilteredArtFinal] = useState<boolean>(false);
  const {
    fetch: getActivityReportFetcher,
    reponseData: getActivityReportData,
    loading: getActivityReportLoader,
  } = useGetActivityReport();
  const {
    fetch: artFinalListExportFetcher,
    loading: artFinalListExportLoader,
  } = useArtFinalListExportable();
  const { download } = useFileDownload();

  const formFilter = useForm({
    initialValues: {
      company: '',
      team: '',
      artUser: '',
      finishedAt: undefined,
    },
  });

  function handleClearFilter() {
    page.current = 1;
    limit.current = 10;
    formFilter.reset();
    setFilteredArtFinal(() => false);
    getLayoutReport();
  }

  function handlePaginate(pageNumber: number) {
    page.current = pageNumber;
    getLayoutReport();
  }

  function handleLimitChange(limitNumber: number) {
    limit.current = limitNumber;
    getLayoutReport();
  }

  async function getFilterUser() {
    try {
      const response = await getFilterUsersListRequest({
        role: UserRole.FINAL_ART,
      });
      setFilterUserList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar usuários.',
        message: 'tente novamente',
      });
    }
  }

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  async function getTeams() {
    const response = await getTeamsRequest();
    setTeamList(response);
  }

  async function getLayoutReport() {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    await getActivityReportFetcher({
      query: {
        page: page.current,
        limit: limit.current,
        ...validatedFilter,
        clockIn: Date.parse(validatedFilter.clockIn?.[0])
          ? `${dayjsPlugins(validatedFilter.clockIn[0]).format(
              'YYYY-MM-DD',
            )},${dayjsPlugins(validatedFilter.clockIn[1]).format('YYYY-MM-DD')}`
          : undefined,
      },
    });
  }

  async function handleRequestArtFinalListExport() {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    if (getActivityReportData?.items?.length === 0) {
      errorNotification({
        title: 'Erro ao gerar realatorio.',
        message: 'não há resultados para exportar.',
      });
    } else {
      artFinalListExportFetcher({
        query: { ...validatedFilter },
        onSuccess: (data) => {
          setPageModal('exportable-art-final');
          download(data, 'arte-finalistas.xlsx');
        },
      });
    }
  }

  async function handleSubmit() {
    if (
      formFilter.values.artUser ||
      formFilter.values.company ||
      formFilter.values.team ||
      formFilter.values.finishedAt
    ) {
      setFilteredArtFinal(() => true);
    } else {
      setFilteredArtFinal(() => false);
    }
    await getLayoutReport();
  }

  useEffect(() => {
    getFilterUser();
    getCompanies();
    getTeams();
    getLayoutReport();
  }, []);

  return (
    <Page title="Relatório de Arte-Final">
      <Flex direction="column" w="99%">
        <Group position="right">
          <Button
            disabled={!filteredArtFinal}
            variant="subtle"
            color="orange"
            leftIcon={<IconDownload />}
            onClick={handleRequestArtFinalListExport}
          >
            Exportar
          </Button>
        </Group>
        <form onSubmit={formFilter.onSubmit(handleSubmit)}>
          <Paper p={16} mb={16} mt={16} withBorder>
            <Flex>
              <Select
                clearable
                name="artUser"
                searchable
                label="Usuário"
                placeholder="selecione o usuário"
                data={filterUserList.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('artUser')}
              />
              <Select
                searchable
                clearable
                name="team"
                label="Equipe"
                placeholder="selecione uma equipe"
                data={teamList?.map((item) => ({
                  label: item.name,
                  value: String(item.id),
                }))}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('team')}
              />
              <Select
                searchable
                clearable
                name="company"
                label="Cliente"
                placeholder="selecione um cliente"
                data={companyList?.map((item) => ({
                  label: item.name,
                  value: String(item.id),
                }))}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('company')}
              />
              <CustomDateRangePicker
                allowSingleDateInRange
                label="Data"
                placeholder="selecione um intervalo"
                mr={8}
                {...formFilter.getInputProps('finishedAt')}
              />
            </Flex>
            <Button
              mt={25}
              color="ltpBlue.9"
              type="button"
              variant="outline"
              onClick={handleClearFilter}
            >
              Limpar
            </Button>
            <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
              Filtrar
            </Button>
          </Paper>
        </form>
        <DataTable
          recordsPerPageLabel="Itens por página"
          recordsPerPageOptions={[10, 50, 100, 500, 700]}
          onRecordsPerPageChange={handleLimitChange}
          fetching={getActivityReportLoader}
          height={440}
          noRecordsText="Sem usuários"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          page={page.current}
          recordsPerPage={
            getActivityReportData?.meta.itemsPerPage ?? limit.current
          }
          totalRecords={getActivityReportData?.meta.totalItems}
          onPageChange={handlePaginate}
          records={getActivityReportData?.items}
          columns={[
            {
              accessor: 'os',
              title: 'OS',
            },
            {
              accessor: 'companyName',
              title: 'Cliente',
            },
            {
              accessor: 'teamName',
              title: 'Equipe',
            },
            {
              accessor: 'targetName',
              title: 'Arte-finalista',
            },
            {
              accessor: 'activity_status',
              title: 'Status da atividade',
              render: ({ status }) => (
                <Badge color={activityStatusColor[status]}>
                  {activityStatusHumanized[status]}
                </Badge>
              ),
            },
            {
              accessor: 'activity_complexity',
              title: 'Nível de Complexidade',
              render: ({ complexity }) => (
                <Badge color={activityComplexityColor[complexity]}>
                  {activityComplexityHumanized[complexity]}
                </Badge>
              ),
            },
            {
              accessor: 'clockIn',
              title: 'Data',
              render: ({ createdAt }) => formatDateTime(createdAt),
            },
            {
              accessor: 'clockOut',
              title: 'Finalizado em',
              render: ({ finishedAt }) => formatDateTime(finishedAt),
            },
          ]}
        />
      </Flex>
      <ExportableModal
        title="Exportável de Arte Final"
        loading={artFinalListExportLoader}
        opened={pageModal === 'exportable-art-final'}
        onClose={() => setPageModal(null)}
      />
    </Page>
  );
}

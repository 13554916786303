import { TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';

interface DynamicTextInputProps {
  entrieId?: number;
  name: string;
  value: string | number;
  handleSubmitEditValue(entrieId: number, values: any): void;
  canEdit?: boolean;
}

export function DynamicTextInput({
  entrieId,
  name,
  value,
  handleSubmitEditValue,
  canEdit,
}: DynamicTextInputProps) {
  const [isFocused, setIsFocused] = useState(false);

  const inputForm = useForm({
    initialValues: {
      [name]: value,
    },
  });

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (!entrieId || !canEdit) return;
    handleSubmitEditValue(entrieId, inputForm.values);
    setIsFocused(false);
  };

  return (
    <TextInput
      name={name}
      {...inputForm.getInputProps(name)}
      autoComplete="off"
      autoSave="off"
      onFocus={handleOnFocus}
      onBlur={handleBlur}
      radius="md"
      style={{
        border: isFocused && canEdit ? '1px solid #2600ff72' : '',
        borderRadius: isFocused ? '2px' : '',
        padding: '0px 5px 0px 5px',
        height: '37px',
      }}
      variant="unstyled"
      readOnly={!canEdit}
    />
  );
}

import { Checkbox, Group, NumberInput, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

interface DecalqueFormItemProps {
  form: UseFormReturnType<any>;
}

export function DecalqueEditItem({ form }: DecalqueFormItemProps) {
  return (
    <>
      <Group grow>
        <TextInput
          required
          withAsterisk
          label="Detalhe o local de aplicação"
          placeholder="ex: frente, verso, fundo, borda e etc..."
          mb={16}
          name="personalizationArea"
          maxLength={255}
          {...form.getInputProps('details.personalizationArea')}
        />
        <NumberInput
          required={form.values.details?.isChromia ? false : true}
          withAsterisk={form.values.details?.isChromia ? false : true}
          label="Cores"
          placeholder="quantidade de cores"
          mb={16}
          min={0}
          name="colorsAmount"
          {...form.getInputProps('details.colorsAmount')}
        />
      </Group>
      <Checkbox
        label="Tem cromia de cores ?"
        color="green"
        name="isChromia"
        mb={16}
        checked={form.values.details?.isChromia}
        {...form.getInputProps('details.isChromia')}
      />
    </>
  );
}

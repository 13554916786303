import {
  Alert,
  Badge,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  NumberInput,
  Radio,
  Select,
  Stepper,
  Text,
  TextInput,
  Textarea,
  Title,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  IconAlertCircle,
  IconArrowLeft,
  IconRefreshDot,
  IconTestPipe2,
} from '@tabler/icons';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import { CustomDateTimePicker } from '../../../components/CustoDateTimePicker';
import { CustomMultipleFileInput } from '../../../components/CustomMutipleFileInput';
import { rootNavigateGoBack } from '../../../components/CustomRouter';
import { getFilterCompaniesListRequest } from '../../../data/services/filters';
import { Option } from '../../../models/option';
import { Order, OrderStatus } from '../../../models/order';
import { FileType, OrderFile } from '../../../models/order-file';
import { TeamConfigAlias } from '../../../models/team-config';
import { RootState } from '../../../providers/store';
import {
  orderStatusColor,
  orderStatusHumanized,
  statusOrder,
} from '../../../utils/constants';
import { orderPaymentTypeList } from '../../../utils/constants/order';
import {
  formatBRL,
  realToUSCash,
  textCapitalize,
} from '../../../utils/helpers';
import { GetFilterCompaniesListResponse } from '../../../utils/types/data/services/filters';
import { FileManager } from '../subcomponents/file-manager';

interface VendorEditOrderMobileProps {
  orderStatus: OrderStatus;
  navigation: NavigateFunction;
  form: UseFormReturnType<any>;
  handleSubmit: (values: any) => void;
  handleSelectService: (id: string) => void;
  currentStep: number;
  nextStep: () => void;
  prevStep: () => void;
  services: {
    label: string;
    value: string;
  }[];
  files: OrderFile[];
  selectedService: string;
  servicerFormRender: any;
  canGoToNextStep: boolean;
  handleRemoveFile(id: number): void;
  locationOptions: Option[] | undefined;
  isRework: boolean | undefined;
  isSample: boolean | undefined;
  order: Order;
}

export function VendorEditOrderMobile({
  form,
  handleSubmit,
  currentStep,
  services,
  handleSelectService,
  selectedService,
  servicerFormRender,
  orderStatus,
  files,
  handleRemoveFile,
  locationOptions,
  isRework,
  isSample,
  order,
}: VendorEditOrderMobileProps) {
  const [companyList, setCompanyList] =
    useState<GetFilterCompaniesListResponse[]>();
  const companyOptions = companyList?.map((item) => ({
    label: item.name,
    value: String(item.id),
  }));
  const { user, userTeam } = useSelector((state: RootState) => state.auth);

  const teamConfilterFilter = useMemo(() => {
    return userTeam?.configs.find(
      (item) => item.alias === TeamConfigAlias.TEAM_FILTER,
    );
  }, []);

  const teamConfigEditOrder = useMemo(() => {
    return userTeam?.configs.find(
      (item) => item.alias === TeamConfigAlias.EDIT_ORDER_POST_PRODUCTION,
    );
  }, []);

  async function getCompanies() {
    const response = await getFilterCompaniesListRequest({
      team:
        teamConfilterFilter?.value === 'false' ? undefined : Number(user?.team),
    });
    setCompanyList(response);
  }

  const colorsAmount = form.values.details?.colorsAmount;

  const price = Number(realToUSCash(String(form.values.price))) || 0;
  const samplePrice =
    Number(realToUSCash(String(form.values.samplePrice))) || 0;
  const photolitoPrice =
    Number(realToUSCash(String(form.values.pholitoPrice))) || 0;
  const manipulationPrice =
    Number(realToUSCash(String(form.values.manipulationPrice))) || 0;
  const vectorizationPrice =
    Number(realToUSCash(String(form.values.vectorizationPrice))) || 0;
  const clichePrice =
    Number(realToUSCash(String(form.values.clichePrice))) || 0;
  const unitChangeColorPrice =
    Number(realToUSCash(String(form.values.changeColorPrice))) || 0;
  const changeColorPriceTotal = unitChangeColorPrice * colorsAmount || 0;
  const quantity = Number(form.values.quantity);
  const unitPrice = Math.fround(price / quantity);
  const orderUnitPrice = formatBRL(unitPrice.toFixed(2));
  const orderTotalPrice = formatBRL(
    (
      price +
      samplePrice +
      photolitoPrice +
      manipulationPrice +
      vectorizationPrice +
      clichePrice +
      changeColorPriceTotal
    ).toFixed(2),
  );

  const disableEdit = useMemo(() => {
    if (
      teamConfigEditOrder?.value === 'true' &&
      statusOrder[orderStatus] < statusOrder[OrderStatus.FINISHED]
    ) {
      return false;
    } else if (
      teamConfigEditOrder?.value === 'true' &&
      statusOrder[orderStatus] >= statusOrder[OrderStatus.FINISHED]
    ) {
      return true;
    } else if (
      !teamConfigEditOrder?.value &&
      statusOrder[orderStatus] >= statusOrder[OrderStatus.RELEASED_PRODUCTION]
    ) {
      return true;
    } else if (
      !teamConfigEditOrder?.value &&
      statusOrder[orderStatus] < statusOrder[OrderStatus.RELEASED_PRODUCTION]
    ) {
      return false;
    } else {
      return true;
    }
  }, [orderStatus]);

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    const photolitoServices: string[] = [];
    const clicheServices: string[] = [];
    const silkService: string[] = [];

    if (!companyList?.find((compItem) => compItem.name === '360')) {
      services.forEach((item) => {
        if (item.label === 'Tampografia') {
          photolitoServices.push(item.value);
        } else if (item.label === 'Hot stamping') {
          clicheServices.push(item.value);
        } else if (item.label === 'Silkscreen') {
          silkService.push(item.value);
        }
      });

      form.setFieldValue('pholitoPrice', formatBRL(''));
      form.setFieldValue('clichePrice', formatBRL(''));

      if (photolitoServices.includes(form.values.service)) {
        let initialPrice = 7000;
        const additionalPrice = 2500;
        const colorsAmount = Number(form.values?.details?.colorsAmount);

        if (colorsAmount > 1) {
          const additionalValue = (colorsAmount - 1) * additionalPrice;
          initialPrice += additionalValue;
        }

        form.setFieldValue('clichePrice', formatBRL(initialPrice.toString()));
        form.setFieldValue('pholitoPrice', formatBRL(''));
      } else if (clicheServices.includes(form.values.service)) {
        form.setFieldValue('pholitoPrice', formatBRL(''));
        form.setFieldValue(
          'clichePrice',
          formatBRL(
            form.values.clichePrice !== ''
              ? form.values.clichePrice
              : order.clichePrice,
          ),
        );
        form.setFieldValue(
          'clichePriceSelector',
          form.values.clichePriceSelector !== ''
            ? form.values.clichePriceSelector
            : order.clichePrice?.replace('.', ''),
        );
      } else if (silkService.includes(form.values.service)) {
        form.setFieldValue('clichePrice', formatBRL(''));
        form.setFieldValue('pholitoPrice', formatBRL('3500'));
      }
    } else {
      form.setFieldValue('pholitoPrice', formatBRL(''));
      form.setFieldValue('clichePrice', formatBRL(''));
    }
  }, [
    form.values.pholitoPrice,
    form.values.clichePrice,
    form.values.service,
    form.values.details?.colorsAmount,
    companyList?.length,
    services.length,
  ]);

  useEffect(() => {
    if (form.values.isSample && Number(form.values.quantity) > 5) {
      form.setFieldValue('quantity', 5);
    }
  }, [form.values]);

  return (
    <Container>
      <Grid gutter="xs" columns={5}>
        <Grid.Col>
          <Flex align="center" justify="space-between">
            <Button
              onClick={() => rootNavigateGoBack()}
              color="dark.1"
              variant="subtle"
              w={40}
              p={0}
            >
              <IconArrowLeft />
            </Button>
          </Flex>
        </Grid.Col>
      </Grid>
      <Flex justify="center" align="center">
        <Title size={40} fw="bolder" color="orange" order={1} align="center">
          Editar Pedido
        </Title>
        {isRework && (
          <Badge
            leftSection={<IconRefreshDot size={24} />}
            p={16}
            color="grape"
            variant="light"
          >
            Retrabalho
          </Badge>
        )}
        {isSample && (
          <Badge
            leftSection={<IconTestPipe2 size={16} />}
            p={16}
            color="green"
            variant="light"
          >
            Amostra
          </Badge>
        )}
      </Flex>
      <Divider mt={16} mb={16} />
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Stepper
          color="orange"
          orientation="horizontal"
          iconSize={37}
          active={currentStep}
        >
          <Stepper.Step label="Pedido" description="dados do pedido">
            <Group>
              <Text size={14}>Status:</Text>
              <Badge color={orderStatusColor[orderStatus]} mb={6}>
                {orderStatusHumanized[orderStatus]}
              </Badge>
            </Group>
            <TextInput
              label="Identificação interna"
              placeholder="código customizavel para identificar o pedido"
              mb={16}
              type="text"
              name="internalNumber"
              value={form.values.internalNumber}
              maxLength={255}
              {...form.getInputProps('internalNumber')}
            />
            <Select
              disabled={disableEdit}
              searchable
              required
              withAsterisk
              name="company"
              label="Cliente"
              placeholder="selecione o cliente do pedido"
              data={companyOptions ?? []}
              mb={16}
              {...form.getInputProps('company')}
            />
            <TextInput
              disabled
              label="OS"
              placeholder="os do pedido"
              mb={16}
              type="text"
              name="os"
              maxLength={255}
              {...form.getInputProps('os')}
            />
            {form.values.isSample && (
              <Group grow>
                <Alert
                  icon={<IconAlertCircle size={16} />}
                  title="Atenção"
                  color="red"
                  mb={16}
                >
                  Amostras limitadas a 5 peças por pedido.
                </Alert>
              </Group>
            )}
            <Group grow>
              <TextInput
                required
                withAsterisk
                label="Produto"
                placeholder="descreva o produto ex: Caneta, Caneca"
                mb={16}
                type="text"
                name="product"
                value={form.values.product}
                maxLength={255}
                onChange={(event) =>
                  form.setFieldValue(
                    'product',
                    textCapitalize(event.target.value),
                  )
                }
              />
              <NumberInput
                disabled={disableEdit}
                required
                name="quantity"
                withAsterisk
                label="Quantidade"
                placeholder="digite a quantidade"
                mb={16}
                min={0}
                max={form.values.isSample && 5}
                {...form.getInputProps('quantity')}
              />
            </Group>
            <Group grow>
              <TextInput
                required
                withAsterisk
                label="Cor"
                placeholder="cor do produto"
                mb={16}
                type="text"
                name="productColor"
                value={form.values.productColor}
                maxLength={255}
                onChange={(event) =>
                  form.setFieldValue(
                    'productColor',
                    textCapitalize(event.target.value),
                  )
                }
              />
              <TextInput
                required
                withAsterisk
                label="Material"
                placeholder="descreva se ex: plástico, metal e etc..."
                mb={16}
                type="text"
                name="productMaterial"
                value={form.values.productMaterial}
                maxLength={255}
                onChange={(event) =>
                  form.setFieldValue(
                    'productMaterial',
                    textCapitalize(event.target.value),
                  )
                }
              />
            </Group>
            <Group grow>
              <TextInput
                readOnly
                label="Preço Clichê"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="clichePrice"
                value={formatBRL(form.values.clichePrice ?? '')}
                onChange={(e) =>
                  form.setValues({ clichePrice: formatBRL(e.target.value) })
                }
              />
              <TextInput
                readOnly
                label="Preço Fotolito"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="pholitoPrice"
                value={formatBRL(form.values.pholitoPrice ?? '')}
                onChange={(e) =>
                  form.setValues({ pholitoPrice: formatBRL(e.target.value) })
                }
              />
            </Group>
            <Group grow>
              <TextInput
                disabled={disableEdit}
                label="Preço Manuseio"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="manipulationPrice"
                value={formatBRL(form.values.manipulationPrice ?? '')}
                onChange={(e) =>
                  form.setValues({
                    manipulationPrice: formatBRL(e.target.value),
                  })
                }
              />
              <TextInput
                disabled={disableEdit}
                label="Preço Vetorização"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="vectorizationPrice"
                value={formatBRL(form.values.vectorizationPrice ?? '')}
                onChange={(e) =>
                  form.setValues({
                    vectorizationPrice: formatBRL(e.target.value),
                  })
                }
              />
            </Group>
            <Group grow>
              <TextInput
                disabled={disableEdit}
                label="Preço Amostra"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="samplePrice"
                value={formatBRL(form.values.samplePrice ?? '')}
                onChange={(e) =>
                  form.setValues({ samplePrice: formatBRL(e.target.value) })
                }
              />
              <TextInput
                disabled={disableEdit}
                required
                withAsterisk
                label="Preço (total gravação)"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="price"
                value={formatBRL(form.values.price ?? '')}
                onChange={(e) =>
                  form.setFieldValue('price', formatBRL(e.target.value))
                }
              />
            </Group>
            <Group grow>
              <div>
                <Text>Preço unitário: </Text>
                <Text color="gray">{orderUnitPrice}</Text>
              </div>
              <div>
                <Text>Preço final: </Text>
                <Text color="gray">{orderTotalPrice}</Text>
              </div>
            </Group>
            <Group grow>
              <Select
                required
                name="paymentType"
                withAsterisk
                label="Forma de pagamento"
                placeholder="selecione uma forma de pagamento"
                data={orderPaymentTypeList}
                mb={16}
                {...form.getInputProps('paymentType')}
              />
            </Group>
            <TextInput
              label="Número do JOB"
              placeholder="ex: 1/3"
              mb={16}
              type="text"
              name="job"
              maxLength={255}
              {...form.getInputProps('job')}
            />
            <Group grow>
              <Textarea
                maxLength={255}
                mb={16}
                label="Observações Fiscais"
                placeholder="escreva aqui qualquer observação fiscal que queira fazer sobre o pedido"
                name="financialNote"
                {...form.getInputProps('financialNote')}
              />
            </Group>
            <Select
              disabled={disableEdit}
              required
              name="service"
              withAsterisk
              searchable
              label="Serviço"
              placeholder="selecione um serviço"
              data={services}
              mt={16}
              mb={24}
              value={form.values.service}
              onChange={handleSelectService}
            />
            {selectedService !== '' &&
              servicerFormRender?.[selectedService as any] &&
              servicerFormRender[selectedService as any]()}
            <TextInput
              withAsterisk
              label="Nome do logotipo"
              placeholder="texto da gravação"
              mb={16}
              type="text"
              name="engravingText"
              maxLength={255}
              {...form.getInputProps('engravingText')}
            />
            <Text size={14}>Possui Correio</Text>
            <Text size={12} color="gray">
              este pedido será enviado por correio?
            </Text>
            <Group mb={16}>
              <Button
                color={form.values.needsPostal === 'true' ? 'green' : 'gray'}
                onClick={() => form.setFieldValue('needsPostal', 'true')}
              >
                Sim
              </Button>
              <Button
                color={form.values.needsPostal === 'false' ? 'red' : 'gray'}
                onClick={() => form.setFieldValue('needsPostal', 'false')}
              >
                Não
              </Button>
            </Group>
            <TextInput
              label="Fornecedor"
              placeholder="nome do fornecedor"
              mb={16}
              type="text"
              name="supplier"
              value={form.values.supplier}
              maxLength={255}
              onChange={(event) =>
                form.setFieldValue(
                  'supplier',
                  textCapitalize(event.target.value),
                )
              }
            />
            <TextInput
              label="Código do produto"
              placeholder="para identificação do produto no fornecedor"
              mb={16}
              type="text"
              name="productCode"
              value={form.values.productCodeSupplier}
              maxLength={255}
              onChange={(event) =>
                form.setFieldValue(
                  'productCodeSupplier',
                  textCapitalize(event.target.value),
                )
              }
            />
            {form.values.finishForecast && (
              <CustomDateTimePicker
                disabled
                key="date-picker-finishForecast"
                label="Prazo de finalização (cliente)"
                date={form.values.finishForecast}
                onChange={() => {}}
              />
            )}
            {form.values.finishVendorForecast && (
              <CustomDateTimePicker
                disabled={disableEdit}
                key="date-picker-finishVendorForecast"
                label="Prazo de finalização"
                date={form.values.finishVendorForecast}
                onChange={(dateTime) =>
                  form.setFieldValue('finishVendorForecast', dateTime)
                }
              />
            )}
            <Radio.Group
              name="orderAccess"
              label="Entrega do material"
              description="nos diga como vamos receber o material"
              withAsterisk
              {...form.getInputProps('orderAccess')}
            >
              <Radio value="delivered" label="será entregue na empresa" />
              <Radio value="collected" label="será retirado pela empresa" />
            </Radio.Group>
            {form.values.orderAccess === 'collected' ? (
              <>
                <Group grow>
                  <TextInput
                    disabled
                    withAsterisk
                    label="Local da retirada"
                    placeholder="endereço completo"
                    mb={16}
                    type="text"
                    name="pickUpLocation"
                    maxLength={255}
                    {...form.getInputProps('pickUpLocation')}
                  />
                  <TextInput
                    disabled
                    withAsterisk
                    label="Resposável pela retirada"
                    placeholder="com quem falar na retirada"
                    mb={16}
                    type="text"
                    name="pickUpName"
                    maxLength={255}
                    {...form.getInputProps('pickUpName')}
                  />
                </Group>
                <TextInput
                  disabled
                  withAsterisk
                  label="Codigo para identificação no fornecedor"
                  placeholder="necessário para retirada de pedidos na SPOT,ASIA"
                  mb={16}
                  type="text"
                  name="pickUpNumber"
                  maxLength={255}
                  {...form.getInputProps('pickUpNumber')}
                />
                <CustomDateTimePicker
                  key="date-picker-dispatchForecast"
                  label="Previsão de retirada do material"
                  date={form.values.pickUpForecast}
                  onChange={(dateTime) =>
                    form.setValues({ pickUpForecast: dateTime })
                  }
                />
              </>
            ) : (
              <>
                <TextInput
                  withAsterisk
                  label="Portador"
                  placeholder="nome do portador"
                  mb={16}
                  type="text"
                  name="carrierName"
                  maxLength={255}
                  {...form.getInputProps('carrierName')}
                />
                <CustomDateTimePicker
                  key="date-picker-receiptForecast"
                  label="Previsão de entrega do material"
                  date={form.values.receiptForecast}
                  onChange={(dateTime) =>
                    form.setValues({ receiptForecast: dateTime })
                  }
                />
              </>
            )}
            <Select
              required
              name="dispatchLocation"
              withAsterisk
              label="Unidade para retirada"
              placeholder="selecione um local"
              data={
                locationOptions?.map((item) => ({
                  label: item.name,
                  value: item.value,
                })) ?? []
              }
              mt={16}
              mb={24}
              {...form.getInputProps('dispatchLocation')}
            />
            <Text>Arquivos</Text>
            <Group maw={440}>
              <FileManager
                files={files.filter((item) => item.type === FileType.FILE)}
                handleRemoveFile={handleRemoveFile}
              />
            </Group>
            <CustomMultipleFileInput
              name="newFiles"
              inputName="newFiles"
              accept="application/pdf,image/png,image/jpeg,image/jpg,.eps,.ai,.cdr,.svg,.txt,.ps,.bin"
              label="Arquivos"
              placeholder="selecione um arquivo por vez"
              formRef={form}
            />
            <Textarea
              maxLength={255}
              mb={16}
              label="Observações"
              placeholder="escreva aqui qualquer observação que queira fazer sobre seu pedido"
              name="note"
              {...form.getInputProps('note')}
            />
            {/*
            <Text>Comprovantes</Text>
            <Group maw={440}>
              <FileManager
                files={files.filter((item) => item.type === FileType.RECEIPT)}
                handleRemoveFile={handleRemoveFile}
              />
            </Group>
            <CustomMultipleFileInput
              name="newFileReceipts"
              accept="application/pdf,image/png,image/jpeg,image/jpg"
              label="Comprovantes"
              placeholder="selecione um arquivo por vez"
              inputName="newFileReceipts"
              formRef={form}
            />
             */}
          </Stepper.Step>
        </Stepper>
        <Group position="right" mt="xl">
          <Button color="ltpBlue.9" type="submit">
            Salvar
          </Button>
        </Group>
      </form>
    </Container>
  );
}

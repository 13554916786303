import {
  ActionIcon,
  Badge,
  Flex,
  LoadingOverlay,
  Menu,
  Modal,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconList,
  IconPlus,
  IconRotate2,
  IconSearch,
  IconTrash,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { useUpdateProductEntry } from '../../../data/hooks/product-entries';
import {
  useDeleteTag,
  useListTag,
  useRecoverTag,
} from '../../../data/hooks/tags';
import { Tag } from '../../../models/tag';
import { successNotification } from '../../../providers/mantine-notifications';
import { useSelector } from 'react-redux';
import { RootState } from '../../../providers/store';
import { UserType } from '../../../models/user';

type TagPageModalVisible = 'list' | null;

interface TagMenuProps {
  entrieId?: number;
  item: {
    name: string;
    color: string;
  };
  target: any;
  openModalCreateTag: (type: string) => void;
  canEdit?: boolean;
  title?: string;
}

export function TagMenu({
  entrieId,
  target,
  item,
  openModalCreateTag,
  canEdit,
  title,
}: TagMenuProps) {
  const { user } = useSelector((state: RootState) => state.auth);

  const [pageModalVisible, setPageModalVisible] =
    useState<TagPageModalVisible>(null);
  const [currentValue, setCurrentValue] = useState<{
    name: string;
    color: string;
  }>();
  const [opened, setOpened] = useState<boolean>(false);

  const {
    fetch: listTagFetcher,
    loading: listTagLoader,
    reponseData: listTagData,
  } = useListTag();

  const { fetch: deleteTagFetcher, loading: deleteTagLoader } = useDeleteTag();

  const { fetch: recoverTagFetcher, loading: recoverTagLoader } =
    useRecoverTag();

  const { fetch: updateProductEntryFetcher } = useUpdateProductEntry();

  const searchForm = useForm({
    initialValues: {
      text: '',
    },
  });

  function handleSubmitEditTag(values: any) {
    if (!entrieId || !canEdit) return;
    updateProductEntryFetcher({
      id: entrieId,
      data: values,
      onSuccess: () => {
        setCurrentValue({
          color: values.color,
          name: values.name,
        });
      },
    });
  }

  const filtered = listTagData?.filter((item) =>
    item.name.toLowerCase().startsWith(searchForm.values.text.toLowerCase()),
  );

  function handleOpenMenu() {
    if (!opened) {
      listTagFetcher({
        query: {
          type: target,
        },
      });
    }

    setOpened(() => !opened);
  }

  function handleDeleteTag(id: number) {
    deleteTagFetcher({
      id,
      onSuccess: () => {
        listTagFetcher({
          query: {
            type: target,
          },
        });
        successNotification({
          title: 'Tudo certo!',
          message: 'tag desativada com sucesso ✅',
        });
      },
    });
  }

  function handleRecoverTag(id: number) {
    recoverTagFetcher({
      id,
      onSuccess: () => {
        listTagFetcher({
          query: {
            type: target,
          },
        });
        successNotification({
          title: 'Tudo certo!',
          message: 'tag reativada com sucesso ✅',
        });
      },
    });
  }

  useEffect(() => {
    setCurrentValue({ color: item.color, name: item.name });
  }, [target]);

  return (
    <>
      <Menu opened={opened} onChange={() => handleOpenMenu()}>
        <Menu.Target>
          <Badge
            style={{
              backgroundColor: currentValue?.color || '#FFF',
              color: '#FFF',
              cursor: 'pointer',
              width: currentValue?.name ? '' : '100%',
            }}
            variant="filled"
          >
            {currentValue?.name || '--'}
          </Badge>
        </Menu.Target>

        <Menu.Dropdown>
          <Flex align={'center'} gap={'5px'} mb={'xs'}>
            <TextInput
              name="search"
              autoComplete="off"
              autoSave="off"
              icon={<IconSearch size={14} />}
              {...searchForm.getInputProps('text')}
            />
            <ActionIcon
              variant="subtle"
              size={'md'}
              color={'blue'}
              onClick={() => {
                setOpened(false);
                setPageModalVisible('list');
              }}
            >
              <IconList />
            </ActionIcon>
          </Flex>

          <LoadingOverlay visible={listTagLoader} overlayBlur={2} />

          {filtered?.map(
            (item: Tag) =>
              !item.deletedAt && (
                <Menu.Item
                  key={item.id}
                  onClick={() =>
                    handleSubmitEditTag({
                      [target]: item.id,
                      name: item.name,
                      color: item.color,
                    })
                  }
                >
                  <Badge
                    style={{
                      backgroundColor: item.color,
                      color: '#FFF',
                    }}
                    variant="filled"
                  >
                    {item.name}
                  </Badge>
                </Menu.Item>
              ),
          )}

          {!filtered?.length && (
            <Flex align={'center'} justify={'center'}>
              <Text color="dimmed" fw={'bold'} size={'xs'}>
                Sem tags
              </Text>
            </Flex>
          )}

          <Menu.Divider />
          <Menu.Item
            icon={<IconPlus size={14} />}
            onClick={() => openModalCreateTag(target)}
            disabled={!canEdit}
          >
            Nova
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <Modal
        size={500}
        opened={pageModalVisible === 'list'}
        onClose={() => setPageModalVisible(null)}
        title={`Tags (${title})`}
      >
        <TextInput
          name="search"
          autoComplete="off"
          autoSave="off"
          icon={<IconSearch size={14} />}
          {...searchForm.getInputProps('text')}
          mb={'md'}
        />
        <DataTable
          minHeight="200px"
          records={filtered}
          loaderVariant="oval"
          loaderBackgroundBlur={2}
          noRecordsText="Sem tags"
          columns={[
            {
              accessor: '',
              render: ({ name, color }) => (
                <Badge
                  style={{
                    backgroundColor: color,
                    color: '#FFF',
                  }}
                  variant="filled"
                >
                  {name}
                </Badge>
              ),
            },
            {
              accessor: '',
              render: ({ deletedAt }) => (
                <Badge color={deletedAt ? 'red' : 'blue'}>
                  {deletedAt ? 'inativo' : 'ativo'}
                </Badge>
              ),
            },
            {
              accessor: '',
              render: (tag) => (
                <Flex gap={'xs'}>
                  <ActionIcon
                    variant="subtle"
                    size={'md'}
                    color={'blue'}
                    disabled={!tag.deletedAt || user?.type !== UserType.MASTER}
                    loading={recoverTagLoader}
                    onClick={() => handleRecoverTag(tag.id)}
                  >
                    <IconRotate2 />
                  </ActionIcon>
                  <ActionIcon
                    variant="subtle"
                    size={'md'}
                    color={'red'}
                    loading={deleteTagLoader}
                    disabled={!!tag.deletedAt || user?.type !== UserType.MASTER}
                    onClick={() => handleDeleteTag(tag.id)}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Flex>
              ),
            },
          ]}
        />
      </Modal>
    </>
  );
}

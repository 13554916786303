import { useFetch } from '../../../hooks/use-fetch';
import { Team } from '../../../models/team';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  UseShowTeam,
  UseUpdateTeam,
} from '../../../utils/types/data/hooks/teams';
import { showTeamRequest, updateTeamsRequest } from '../../services/teams';

export function useShowTeam() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<Team>();

  const fetch = ({ id, onSuccess }: UseShowTeam) =>
    fetchData({
      fetcher: () => showTeamRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar equipe.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useUpdateTeam() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<Team>();

  const fetch = ({ data, id, onSuccess }: UseUpdateTeam) =>
    fetchData({
      fetcher: () => updateTeamsRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao atualizar equipe.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

import {
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Group,
  Modal,
  Paper,
  ScrollArea,
  Select,
  Text,
  TextInput,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconArrowLeft,
  IconClock,
  IconPlayerPlay,
  IconPlayerStop,
} from '@tabler/icons';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomLoader } from '../../components/CustomLoader';
import { rootNavigateGoBack } from '../../components/CustomRouter';
import { OrderDetails } from '../../components/OrderDetails';

import { Page } from '../../components/Page';
import { useListOptions } from '../../data/hooks/options';
import {
  useCreateOrderHandlings,
  useGetLastOrderHandlings,
  useGetOrderHandlings,
  useOrderHandlingsClockout,
} from '../../data/hooks/order-handlings';

import {
  getOrderDetailsRequest,
  getOrderRequest,
  setOrderProductionFinishedRequest,
} from '../../data/services/orders';
import { useTimer } from '../../hooks/use-timer';
import { OptionSubTypes, OptionTypes } from '../../models/option';
import { Order, OrderStatus } from '../../models/order';
import { OrderDetail } from '../../models/order-detail';
import { HandlingType } from '../../models/order-handling';
import { formatLocale } from '../../providers/dayjs-plugins';
import { errorNotification } from '../../providers/mantine-notifications';
import { orderStatusColor, orderStatusHumanized } from '../../utils/constants';
import { orderHandlingTypeHumanized } from '../../utils/constants/order-handling';
import { secondsToTime, translateServerHttpErrors } from '../../utils/helpers';

type PageModalState = 'begin-production' | 'end-production' | null;

export function OrderHandling() {
  const { id } = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [pageModalVisible, setPageModalVisible] =
    useState<PageModalState>(null);
  const [order, setOrder] = useState<Order>();
  const [orderDetails, setOrderDetails] = useState<OrderDetail>();
  const [isOnProgress, setisOnProgress] = useState(false);
  const {
    fetch: getOrderHandlingsFetcher,
    loading: getOrderHandlingsLoader,
    reponseData: getOrderHandlingsData,
  } = useGetOrderHandlings();
  const {
    fetch: getLastOrderHandlingFetcher,
    loading: getLastOrderHandlingLoader,
    reponseData: getLastOrderHandlingData,
  } = useGetLastOrderHandlings();
  const {
    fetch: listStepOptionsFetcher,
    reponseData: listStepOptionsData,
    loading: listStepOptionsLoader,
  } = useListOptions();
  const {
    fetch: createOrderHandlingFetcher,
    loading: createOrderHandlingLoader,
  } = useCreateOrderHandlings();
  const { fetch: orderHandlingClockoutFetcher } = useOrderHandlingsClockout();

  const form = useForm({
    initialValues: {
      type: '',
      supervisor: '',
      machine: '',
    },
  });

  const formStopProduction = useForm({
    initialValues: {
      amount: '',
    },
  });

  async function getOrder() {
    setPageLoading(true);
    const orderResponse = await getOrderRequest(Number(id));
    const orderDetailResponse = await getOrderDetailsRequest(Number(id));
    setPageLoading(false);

    setOrder(orderResponse);
    setOrderDetails(orderDetailResponse);
  }

  async function getOrderHandlings() {
    await getOrderHandlingsFetcher({ orderId: Number(id) });
    await getLastOrderHandlingFetcher({ orderId: Number(id) });
  }

  async function handleSubmit(values: typeof form.values) {
    if (values.type === 'finish') {
      try {
        setPageLoading(true);
        await setOrderProductionFinishedRequest(Number(id));
        setPageLoading(false);
      } catch (error: any) {
        setPageLoading(false);
        errorNotification({
          title: translateServerHttpErrors(error, 'Erro ao finalizar produção'),
          message: 'tente novamente',
        });
      }
    } else {
      if (!isOnProgress) {
        await createOrderHandlingFetcher({
          orderId: Number(id),
          data: {
            type: values.type as HandlingType,
          },
        });
        getOrderHandlings();
        setisOnProgress(true);
        setPageModalVisible(null);
      }
    }
    await getOrder();
  }

  async function handleSubmitStopProduction(
    values: typeof formStopProduction.values,
  ) {
    await orderHandlingClockoutFetcher({
      id: Number(getLastOrderHandlingData?.id),
      data: {
        amount: values.amount ? Number(values.amount) : undefined,
      },
      onSuccess: () => {
        getOrderHandlings();
        form.reset();
        formStopProduction.reset();
        setisOnProgress(false);
        setPageModalVisible(null);
      },
    });
  }

  async function getStepOptions() {
    await listStepOptionsFetcher({
      query: {
        type: OptionTypes.HANDLING,
        subtype: OptionSubTypes.STEPS,
      },
    });
  }

  function handleStartProduction() {
    if (form.values.type === '') return;

    if (form.values.type === 'production') {
      setPageModalVisible('begin-production');
    } else {
      handleSubmit(form.values);
    }
  }

  function handleStopProduction() {
    if (form.values.type === '') return;

    if (form.values.type !== 'pause') {
      setPageModalVisible('end-production');
    } else {
      handleSubmitStopProduction(formStopProduction.values);
    }
  }

  const isAllowedToProduction =
    order?.status === OrderStatus.RELEASED_PRODUCTION ||
    order?.status === OrderStatus.WAITING_PACKAGING ||
    order?.status === OrderStatus.ON_PRODUCTION ||
    order?.status === OrderStatus.MATERIAL_RECEIVED;

  useEffect(() => {
    getOrder();
    getOrderHandlings();
    getStepOptions();
  }, [id]);

  const productionMetrics = useMemo(() => {
    let pauseTime = 0;
    let productionTime = 0;

    getOrderHandlingsData?.forEach((item) => {
      if (item.clockOut !== null) {
        if (item.type === HandlingType.PAUSE) {
          pauseTime += item.timeAmount;
        }
        if (item.type !== HandlingType.PAUSE) {
          productionTime += item.timeAmount;
        }
      }
    });

    const {
      hours: pauseHours,
      minutes: pauseMinutes,
      seconds: pauseSeconds,
    } = secondsToTime(pauseTime);
    const {
      hours: productionHours,
      minutes: productionMinutes,
      seconds: productionSeconds,
    } = secondsToTime(productionTime);

    return {
      pause: {
        hours: pauseHours,
        minutes: pauseMinutes,
        seconds: pauseSeconds,
      },
      production: {
        hours: productionHours,
        minutes: productionMinutes,
        seconds: productionSeconds,
      },
    };
  }, [getOrderHandlingsData]);

  useEffect(() => {
    if (
      getLastOrderHandlingData &&
      getLastOrderHandlingData.clockOut === null
    ) {
      setisOnProgress(true);
      form.setFieldValue('type', getLastOrderHandlingData.type);
    }
  }, [getLastOrderHandlingData]);

  const { days, hours, minutes, seconds } = useTimer({
    deadline: getLastOrderHandlingData?.clockIn ?? '',
  });

  if (!order || !orderDetails) return null;

  return (
    <Page>
      <CustomLoader
        loading={
          pageLoading ||
          getOrderHandlingsLoader ||
          listStepOptionsLoader ||
          getLastOrderHandlingLoader ||
          createOrderHandlingLoader
        }
      />
      <Grid gutter="xs" columns={5} w="82vw">
        <Grid.Col span={1}>
          <Flex align="center" justify="start">
            <Button
              onClick={() => rootNavigateGoBack()}
              color="dark.1"
              variant="subtle"
              w={40}
              p={0}
            >
              <IconArrowLeft />
            </Button>
          </Flex>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex align="center" justify="center">
            <Badge color={orderStatusColor[order.status]} mb={6}>
              {orderStatusHumanized[order.status]}
            </Badge>
          </Flex>
        </Grid.Col>
      </Grid>
      <Grid gutter="xs" columns={6} w="82vw">
        <Grid.Col span={3}>
          <Paper p={8} withBorder>
            {order && (
              <OrderDetails
                orderId={order.id}
                sections={['basic', 'service-details']}
              />
            )}
          </Paper>
        </Grid.Col>
        <Grid.Col span={3}>
          <Paper p={16} shadow="md">
            <Text fw="bold">Controle de Manuseio</Text>
            <Divider />
            <Text>
              você deve controlar através destes botões abaixo o andamento do
              manuseio deste pedido
            </Text>
            <Text>1- selecione a etapa</Text>
            <Text>2- clique no botão "inicar"</Text>
            <Text>3- caso precise parar clique no botão "parar"</Text>
            <Text>
              4- ao terminar todo o manuseio selecione a etapa "finalizar" e
              clique em "salvar"
            </Text>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
              {isAllowedToProduction && orderDetails.hasManipulation && (
                <>
                  <Select
                    w={170}
                    disabled={isOnProgress}
                    required
                    withAsterisk
                    name="type"
                    label="Etapa"
                    placeholder="selecione a etapa"
                    data={
                      listStepOptionsData?.map((option) => ({
                        label: option.name,
                        value: option.value,
                      })) ?? []
                    }
                    mb={16}
                    mr={8}
                    {...form.getInputProps('type')}
                  />
                  {form.values.type === 'finish' ? (
                    <Button type="submit">Salvar</Button>
                  ) : (
                    <Flex align="center">
                      {getLastOrderHandlingData?.clockOut === null ? (
                        <Title mr={24} color="dark.3">
                          {days > 0 && `${days} ${days > 1 ? 'dias' : 'dia'}`}
                          {` ${hours}:${minutes}:${seconds}`}
                        </Title>
                      ) : (
                        <Title mr={24} color="dark.3">
                          00:00:00
                        </Title>
                      )}
                      <Button
                        onClick={() => handleStartProduction()}
                        disabled={isOnProgress}
                        p={0}
                        w={36}
                        h={36}
                        type="button"
                        mb={-8}
                        radius={18}
                        mr={8}
                      >
                        <IconPlayerPlay color="white" size={26} />
                      </Button>
                      <Button
                        onClick={() => handleStopProduction()}
                        disabled={!isOnProgress}
                        color="red"
                        p={0}
                        w={36}
                        h={36}
                        type="button"
                        mb={-8}
                        radius={18}
                      >
                        <IconPlayerStop color="white" size={26} />
                      </Button>
                    </Flex>
                  )}
                </>
              )}
              <Divider m={8} />
              <ScrollArea mt={8} h={120}>
                {getOrderHandlingsData?.reverse()?.map((prodItem, index) => {
                  const { hours, minutes, seconds } = secondsToTime(
                    prodItem.timeAmount,
                  );

                  return (
                    <Flex key={index} mt={8} mb={8}>
                      <ThemeIcon p={4} mr={8} color="green" variant="light">
                        <IconClock size={26} />
                      </ThemeIcon>
                      <Text mr={8}>
                        {formatLocale(prodItem.clockIn, 'DD/MM/YY')}
                      </Text>
                      <Text mr={8}>
                        {orderHandlingTypeHumanized[prodItem.type]} -
                      </Text>
                      <Text
                        maw={160}
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                        mr={8}
                      >
                        {prodItem.userName}
                      </Text>
                      <Badge>
                        {prodItem.clockOut !== null &&
                          `${hours}h ${minutes}m ${seconds}s`}
                      </Badge>
                    </Flex>
                  );
                })}
              </ScrollArea>
            </form>
          </Paper>
          <Paper mt={36} p={16} withBorder>
            <Text fw="bold">Métricas de tempo</Text>
            <Divider />
            <Flex mt={8} align="center">
              <Text>em pausa:</Text>
              <Badge>{`${productionMetrics.pause.hours}h ${productionMetrics.pause.minutes}m ${productionMetrics.pause.seconds}s`}</Badge>
            </Flex>
            <Flex mt={8} align="center">
              <Text>em manuseio:</Text>
              <Badge>{`${productionMetrics.production.hours}h ${productionMetrics.production.minutes}m ${productionMetrics.production.seconds}s`}</Badge>
            </Flex>
          </Paper>
        </Grid.Col>
      </Grid>
      <Modal
        size={500}
        opened={pageModalVisible === 'end-production'}
        onClose={() => setPageModalVisible(null)}
        title="Finalizar Etapa"
      >
        <form
          onSubmit={formStopProduction.onSubmit((values) =>
            handleSubmitStopProduction(values),
          )}
        >
          <Grid gutter="xs" columns={2}>
            <Grid.Col mt={8} mb={8} span={1}>
              <TextInput
                max={order && order.quantity}
                label="Quantidade"
                placeholder="digite a quantidade"
                mb={16}
                type="number"
                name="amount"
                {...formStopProduction.getInputProps('amount')}
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button color="ltpBlue.9" type="submit" loading={false}>
              Confirmar
            </Button>
          </Group>
        </form>
      </Modal>
    </Page>
  );
}

import { Page } from '../../components/Page';
import { UserRole, UserType } from '../../models/user';

import {
  Badge,
  Button,
  ColorPicker,
  DEFAULT_THEME,
  Flex,
  Grid,
  Group,
  Modal,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useCreateProductEntry,
  useListProductEntry,
  useUpdateProductEntry,
} from '../../data/hooks/product-entries';
import { useCreateTag } from '../../data/hooks/tags';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import { ReceptionistOrderMaterialEntries } from './receptionist';
import { VendorOrderMaterialEntries } from './vendor';
import { AdminOrderMaterialEntries } from './admin';

export type OrderMaterialEntriesPageModalVisible =
  | 'create-entrie'
  | 'create-tag'
  | null;

export type FormFilterType = {
  client?: string;
  volumeName?: string;
  team?: string;
  code?: string;
  receivedBy?: string;
  status?: string;
  type?: string;
};

export function OrderMaterialEntries() {
  const { user } = useSelector((state: RootState) => state.auth);
  const [pageModalVisible, setPageModalVisible] =
    useState<OrderMaterialEntriesPageModalVisible>(null);
  const [filtersCollapse, setFiltersCollapse] = useState(false);
  const currentPage = useRef(1);
  const currentPageLimit = useRef(10);

  const { fetch: createTagFetcher, loading: createTagLoader } = useCreateTag();
  const {
    fetch: listProductEntryFetcher,
    loading: listProductEntryLoader,
    reponseData: listProductEntryData,
  } = useListProductEntry();
  const {
    fetch: createProductEntryFetcher,
    loading: createProductEntryLoader,
  } = useCreateProductEntry();
  const { fetch: updateProductEntryFetcher } = useUpdateProductEntry();

  const formCreateEntrie = useForm({
    initialValues: {
      client: '',
      quantity: '',
      quantityVolume: '',
      product: '',
      deliveredBy: '',
      document: '',
    },
  });

  const formCreateTag = useForm({
    initialValues: {
      name: '',
      color: '',
      type: '',
    },
  });

  const formFilter = useForm<FormFilterType>({
    initialValues: {
      client: '',
      volumeName: '',
      team: '',
      code: '',
      receivedBy: '',
      status: '',
      type: '',
    },
  });

  function handleSubmitCreateEntrie(values: typeof formCreateEntrie.values) {
    createProductEntryFetcher({
      data: { ...values },
      onSuccess: () => {
        handleCloseModal();
        successNotification({
          title: 'Tudo certo!',
          message: 'entrada criada com sucesso ✅',
        });
        getPaginatedProductEntries();
      },
    });
  }

  function handleSubmitCreateTag(values: typeof formCreateTag.values) {
    if (!values.color) {
      errorNotification({
        message: 'Selecione uma cor',
        title: '',
      });
      return;
    }
    createTagFetcher({
      data: {
        name: values.name,
        type: values.type,
        color: values.color,
      },
      onSuccess: () => {
        handleCloseModal();
        successNotification({
          title: 'Tudo certo!',
          message: 'tag criada com sucesso ✅',
        });
      },
    });
  }

  function handleSubmitEditValue(entrieId: number, values: any) {
    updateProductEntryFetcher({
      id: entrieId,
      data: values,
      onSuccess: () => {},
    });
  }

  function handleCloseModal() {
    formCreateTag.reset();
    formCreateEntrie.reset();
    setPageModalVisible(null);
  }

  function openModalCreateTag(type: string) {
    formCreateTag.setFieldValue('type', type);
    setPageModalVisible('create-tag');
  }

  async function getPaginatedProductEntries(isReset = false) {
    let dynamicFields: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        dynamicFields[key] = val;
      }
    });

    if (isReset) {
      dynamicFields = {};
    }

    await listProductEntryFetcher({
      query: {
        ...dynamicFields,
        page: currentPage.current,
        limit: currentPageLimit.current,
      },
    });
  }

  function handleChangePage(pageNumber: number) {
    currentPage.current = pageNumber;
    getPaginatedProductEntries();
  }

  function handleChangePageLimit(pageLimitNumber: number) {
    currentPageLimit.current = pageLimitNumber;
    getPaginatedProductEntries();
  }

  function handleSubmit() {
    currentPage.current = 1;
    getPaginatedProductEntries();
  }

  function handleClearFilter() {
    currentPage.current = 1;
    currentPageLimit.current = 10;
    formFilter.reset();
    getPaginatedProductEntries(true);
  }

  useEffect(() => {
    getPaginatedProductEntries();
  }, []);

  return (
    <Page title="Entradas">
      {user?.type === UserType.MASTER && (
        <AdminOrderMaterialEntries
          items={listProductEntryData}
          handleSubmitEditValue={handleSubmitEditValue}
          setPageModalVisible={setPageModalVisible}
          openModalCreateTag={openModalCreateTag}
          loading={listProductEntryLoader}
          handlePaginated={handleChangePage}
          handleChangePageLimit={handleChangePageLimit}
          currentPage={currentPage}
          handleSubmit={handleSubmit}
          handleClear={handleClearFilter}
          formFilter={formFilter}
          filtersCollapse={filtersCollapse}
          setFiltersCollapse={setFiltersCollapse}
          currentPageLimit={currentPageLimit}
        />
      )}
      {user?.role === UserRole.RECEPTIONIST && (
        <ReceptionistOrderMaterialEntries
          items={listProductEntryData}
          handleSubmitEditValue={handleSubmitEditValue}
          setPageModalVisible={setPageModalVisible}
          openModalCreateTag={openModalCreateTag}
          loading={listProductEntryLoader}
          handlePaginated={handleChangePage}
          handleChangePageLimit={handleChangePageLimit}
          currentPage={currentPage}
          handleSubmit={handleSubmit}
          handleClear={handleClearFilter}
          formFilter={formFilter}
          filtersCollapse={filtersCollapse}
          setFiltersCollapse={setFiltersCollapse}
          currentPageLimit={currentPageLimit}
        />
      )}
      {user?.role === UserRole.VENDOR && (
        <VendorOrderMaterialEntries
          items={listProductEntryData}
          handleSubmitEditValue={handleSubmitEditValue}
          openModalCreateTag={openModalCreateTag}
          loading={listProductEntryLoader}
          handlePaginated={handleChangePage}
          handleChangePageLimit={handleChangePageLimit}
          currentPage={currentPage}
          handleSubmit={handleSubmit}
          handleClear={handleClearFilter}
          formFilter={formFilter}
          filtersCollapse={filtersCollapse}
          setFiltersCollapse={setFiltersCollapse}
          currentPageLimit={currentPageLimit}
        />
      )}

      <Modal
        size={500}
        opened={pageModalVisible === 'create-entrie'}
        onClose={() => handleCloseModal()}
        title="Nova Entrada"
      >
        <form
          onSubmit={formCreateEntrie.onSubmit((values) =>
            handleSubmitCreateEntrie(values),
          )}
        >
          <Grid gutter="xs" columns={2} mb={'xs'}>
            <Grid.Col span={1}>
              <TextInput
                label="Cliente"
                placeholder="nome do cliente"
                type="text"
                name="client"
                required
                withAsterisk
                {...formCreateEntrie.getInputProps('client')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                label="Produto"
                placeholder="nome do produto"
                type="text"
                name="product"
                required
                withAsterisk
                {...formCreateEntrie.getInputProps('product')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                label="Quantidade"
                placeholder="quantidade do material"
                type="number"
                name="quantity"
                required
                withAsterisk
                {...formCreateEntrie.getInputProps('quantity')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                label="Qtde. Volumes"
                placeholder="quantidade de volumes"
                type="number"
                name="quantityVolume"
                required
                withAsterisk
                {...formCreateEntrie.getInputProps('quantityVolume')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                label="Entregue por"
                placeholder="quem entregou?"
                type="text"
                name="deliveredBy"
                {...formCreateEntrie.getInputProps('deliveredBy')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                label="Documento"
                placeholder="documento do entregador"
                type="text"
                name="document"
                {...formCreateEntrie.getInputProps('document')}
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button
              color="dark"
              type="submit"
              loading={createProductEntryLoader}
            >
              Salvar
            </Button>
          </Group>
        </form>
      </Modal>

      <Modal
        size={400}
        opened={pageModalVisible === 'create-tag'}
        onClose={() => handleCloseModal()}
        title="Nova Tag"
      >
        <form
          onSubmit={formCreateTag.onSubmit((values) =>
            handleSubmitCreateTag(values),
          )}
        >
          <Grid columns={2} mb={'xs'}>
            <Grid.Col span={2}>
              <TextInput
                label="Nome"
                placeholder="digite a nova tag"
                type="text"
                name="name"
                required
                withAsterisk
                {...formCreateTag.getInputProps('name')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Flex align={'center'} justify={'center'}>
                <Badge
                  style={{
                    backgroundColor: formCreateTag.values.color
                      ? formCreateTag.values.color
                      : '#FFF',
                    color: formCreateTag.values.color ? '#FFF' : '#000',
                  }}
                  color={formCreateTag.values.color}
                  variant="filled"
                  size="lg"
                >
                  {formCreateTag.values.name}
                </Badge>
              </Flex>
            </Grid.Col>
            <Grid.Col span={2}>
              <ColorPicker
                {...formCreateTag.getInputProps('color')}
                withPicker={false}
                fullWidth
                swatches={[
                  ...DEFAULT_THEME.colors.red,
                  ...DEFAULT_THEME.colors.green,
                  ...DEFAULT_THEME.colors.blue,
                  ...DEFAULT_THEME.colors.yellow,
                  ...DEFAULT_THEME.colors.dark,
                ]}
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button color="dark" type="submit" loading={createTagLoader}>
              Salvar
            </Button>
          </Group>
        </form>
      </Modal>
    </Page>
  );
}

import {
  Badge,
  Button,
  Flex,
  Group,
  Paper,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useForm } from '@mantine/form';
import { IconDownload } from '@tabler/icons';
import { useSelector } from 'react-redux';
import { CustomDateRangePicker } from '../../components/CustomDateRangePicker';
import { CustomLoader } from '../../components/CustomLoader';
import { ExportableModal } from '../../components/ExportableModal';
import { Page } from '../../components/Page';
import { useOrderPaymentReportListExportable } from '../../data/hooks/exportables';
import { getFilterCompaniesListRequest } from '../../data/services/filters';
import { getOrderPaymentsReportRequest } from '../../data/services/financial';
import { getTeamsRequest } from '../../data/services/teams';
import { useFileDownload } from '../../hooks/use-file-download';
import { OrderPaymentCustomResponse } from '../../models/order-payment';
import { Team } from '../../models/team';
import { dayjsPlugins, formatLocale } from '../../providers/dayjs-plugins';
import { errorNotification } from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import { statusList } from '../../utils/constants';
import {
  orderFinancialStatusList,
  orderPaymentStatusList,
  orderPaymentTypeHumanized,
  paymentStatusColor,
  paymentStatusTypeHumanized,
} from '../../utils/constants/order';
import { formatBRL } from '../../utils/helpers';
import { PaginatedResponse } from '../../utils/types';
import { GetFilterCompaniesListResponse } from '../../utils/types/data/services/filters';

export type PageModalState = 'payment-exportable' | null;

export type FormFilterType = {
  status?: string | null;
  company?: string | null;
  os?: string | null;
  limit: number;
  page: number;
  paymentStatus?: string;
  paymentDeadline?: [Date, Date];
  financialStatus?: string;
  team?: string;
  paidAt?: [Date, Date];
  issueDate?: [Date, Date];
};

export function PaymentReport() {
  const [pageModal, setPageModal] = useState<PageModalState>(null);
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [teamList, setTeamList] = useState<Team[]>([]);
  const [paymentList, setPaymentList] =
    useState<PaginatedResponse<OrderPaymentCustomResponse>>();
  const currentPage = useRef(1);
  const currentPageLimit = useRef(10);
  const {
    fetch: orderPaymentReportListExportFetcher,
    loading: orderPaymentReportListExportLoader,
  } = useOrderPaymentReportListExportable();
  const { download } = useFileDownload();
  const { user } = useSelector((state: RootState) => state.auth);

  const formFilter = useForm<FormFilterType>({
    initialValues: {
      limit: 10,
      page: 1,
      company: null,
      os: '',
      status: null,
      paymentDeadline: undefined,
      paidAt: undefined,
      issueDate: undefined,
      paymentStatus: undefined,
      financialStatus: '',
      team: '',
    },
  });

  const canExportData = useMemo(
    () =>
      !formFilter.values.issueDate?.length &&
      !formFilter.values.paidAt?.length &&
      !formFilter.values.paymentDeadline?.length,
    [formFilter.values],
  );

  function handleSubmit() {
    currentPage.current = 1;
    currentPageLimit.current = 10;
    getOrderPaymentsPaginated();
  }

  function handlePaginate(page: number) {
    currentPage.current = page;
    getOrderPaymentsPaginated();
  }

  function handleChangePageLimit(limit: number) {
    currentPageLimit.current = limit;
    getOrderPaymentsPaginated();
  }

  function handleClearFilter() {
    formFilter.reset();
    getOrderPaymentsPaginated(true);
  }

  async function getOrderPaymentsPaginated(isReset = false) {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    try {
      setPageLoading(true);
      if (isReset) {
        const result = await getOrderPaymentsReportRequest({
          page: 1,
          limit: 10,
        });
        setPaymentList(result);
      } else {
        const result = await getOrderPaymentsReportRequest({
          ...validatedFilter,
          page: currentPage.current,
          limit: currentPageLimit.current,
          issueDate: Date.parse(validatedFilter.issueDate?.[0])
            ? `${dayjsPlugins(validatedFilter.issueDate[0]).format(
                'YYYY-MM-DD',
              )},${dayjsPlugins(validatedFilter.issueDate[1]).format(
                'YYYY-MM-DD',
              )}`
            : undefined,
          paidAt: Date.parse(validatedFilter.paidAt?.[0])
            ? `${dayjsPlugins(validatedFilter.paidAt[0]).format(
                'YYYY-MM-DD',
              )},${dayjsPlugins(validatedFilter.paidAt[1]).format(
                'YYYY-MM-DD',
              )}`
            : undefined,
          paymentDeadline: Date.parse(validatedFilter.paymentDeadline?.[0])
            ? `${dayjsPlugins(validatedFilter.paymentDeadline[0]).format(
                'YYYY-MM-DD',
              )},${dayjsPlugins(validatedFilter.paymentDeadline[1]).format(
                'YYYY-MM-DD',
              )}`
            : undefined,
        });
        setPaymentList(result);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
    }
  }

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({
        team: user?.role === 'vendor' ? Number(user?.team) : undefined,
      });
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  async function getTeams() {
    try {
      setPageLoading(true);
      const response = await getTeamsRequest();
      setPageLoading(false);
      setTeamList(response);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar equipes.',
        message: 'tente novamente',
      });
    }
  }

  async function handleGetPaymentExportable() {
    setPageModal('payment-exportable');
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    await orderPaymentReportListExportFetcher({
      query: {
        ...validatedFilter,
        issueDate: Date.parse(validatedFilter.issueDate?.[0])
          ? `${dayjsPlugins(validatedFilter.issueDate[0]).format(
              'YYYY-MM-DD',
            )},${dayjsPlugins(validatedFilter.issueDate[1]).format(
              'YYYY-MM-DD',
            )}`
          : undefined,
        paidAt: Date.parse(validatedFilter.paidAt?.[0])
          ? `${dayjsPlugins(validatedFilter.paidAt[0]).format(
              'YYYY-MM-DD',
            )},${dayjsPlugins(validatedFilter.paidAt[1]).format('YYYY-MM-DD')}`
          : undefined,
        paymentDeadline: Date.parse(validatedFilter.paymentDeadline?.[0])
          ? `${dayjsPlugins(validatedFilter.paymentDeadline[0]).format(
              'YYYY-MM-DD',
            )},${dayjsPlugins(validatedFilter.paymentDeadline[1]).format(
              'YYYY-MM-DD',
            )}`
          : undefined,
      },
      onSuccess: (data) => {
        download(data, 'relatorio-pagamento.xlsx');
      },
    });
  }

  const teamOptions = teamList?.map((item) => ({
    label: item.name,
    value: String(item.id),
  }));

  useEffect(() => {
    getCompanies();
    getOrderPaymentsPaginated();
    getTeams();
  }, []);

  if (!companyList) return null;

  return (
    <Page title="Relatório de Pagamento">
      <CustomLoader loading={pageLoading} />
      <Flex direction="column" w="99%">
        <Group position="right">
          <Button
            disabled={canExportData}
            variant="subtle"
            color="orange"
            leftIcon={<IconDownload />}
            onClick={handleGetPaymentExportable}
          >
            Exportar
          </Button>
        </Group>
        <form onSubmit={formFilter.onSubmit(() => handleSubmit())}>
          <Paper p={16} mb={16} mt={16} withBorder>
            <Flex wrap="wrap">
              <TextInput
                label="OS"
                placeholder="numero da OS"
                mb={16}
                mr={8}
                type="text"
                name="os"
                {...formFilter.getInputProps('os')}
              />
              {user?.role !== 'vendor' && (
                <Select
                  clearable
                  name="team"
                  label="Equipe"
                  placeholder="selecione a equipe do usuário"
                  data={teamOptions ?? []}
                  mb={16}
                  mr={8}
                  {...formFilter.getInputProps('team')}
                />
              )}
              <Select
                searchable
                name="company"
                label="Clientes"
                placeholder="selecione o cliente"
                data={companyList?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('company')}
              />
              <Select
                name="status"
                label="Status"
                placeholder="selecione o status do pedido"
                data={statusList}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('status')}
              />
              <Select
                clearable
                name="paymentStatus"
                label="Status de Pagamento"
                placeholder="selecione o status de pagamento"
                data={orderPaymentStatusList}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('paymentStatus')}
              />
              <Select
                clearable
                name="financialStatus"
                label="Status Fiscal"
                placeholder="selecione o status de fiscal"
                data={orderFinancialStatusList}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('financialStatus')}
              />
              <CustomDateRangePicker
                allowSingleDateInRange
                label="Data de Emissão"
                placeholder="selecione um intervalo"
                mr={8}
                {...formFilter.getInputProps('issueDate')}
              />
              <CustomDateRangePicker
                allowSingleDateInRange
                label="Prazo de Pagamento"
                placeholder="selecione um intervalo"
                mr={8}
                {...formFilter.getInputProps('paymentDeadline')}
              />
              <CustomDateRangePicker
                allowSingleDateInRange
                label="Pago em"
                placeholder="selecione um intervalo"
                mr={8}
                {...formFilter.getInputProps('paidAt')}
              />
            </Flex>
            <Button
              mt={25}
              color="ltpBlue.9"
              type="button"
              variant="outline"
              onClick={handleClearFilter}
            >
              Limpar
            </Button>
            <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
              Filtrar
            </Button>
          </Paper>
        </form>
        <DataTable
          recordsPerPageLabel="Itens por página"
          recordsPerPageOptions={[10, 50, 100, 500]}
          onRecordsPerPageChange={(recordRange) =>
            handleChangePageLimit(recordRange)
          }
          height={440}
          noRecordsText="Sem pagamentos"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          page={currentPage.current}
          totalRecords={paymentList?.meta?.totalItems}
          recordsPerPage={currentPageLimit.current}
          onPageChange={handlePaginate}
          records={paymentList?.items}
          columns={[
            {
              accessor: 'company_name',
              title: 'Cliente',
            },
            {
              accessor: 'team_name',
              title: 'Equipe',
            },
            {
              accessor: 'nfNumber',
              title: 'NF',
            },
            {
              accessor: 'paymentStatus',
              title: 'Status de Pagamento',
              render: ({ paymentStatus }) =>
                paymentStatus && (
                  <Badge color={paymentStatusColor[paymentStatus]} mb={6}>
                    {paymentStatusTypeHumanized[paymentStatus]}
                  </Badge>
                ),
              width: 110,
            },
            {
              accessor: 'totalprice',
              title: 'Preço total',
              render: ({ totalPrice }) => `${formatBRL(totalPrice.toFixed(2))}`,
              width: 110,
            },
            {
              accessor: 'paymentValue',
              title: 'Valor pago',
              render: ({ paymentValue }) =>
                `${formatBRL(paymentValue.toFixed(2))}`,
              width: 110,
            },
            {
              accessor: 'taxValue',
              title: 'Taxa',
              render: ({ taxValue }) => `${formatBRL(taxValue.toFixed(2))}`,
              width: 110,
            },
            {
              accessor: 'payment_type',
              title: 'Forma de Pagamento',
              render: ({ payment_type }) =>
                payment_type && orderPaymentTypeHumanized[payment_type],
              width: 110,
            },
            {
              accessor: 'issueDate',
              title: 'Data de Emissão',
              render: ({ issueDate }) =>
                issueDate && formatLocale(issueDate, 'DD/MM/YY'),
              width: 110,
            },
            {
              accessor: 'paymentDeadline',
              title: 'Prazo Pagamento',
              render: ({ paymentDeadline }) =>
                paymentDeadline && formatLocale(paymentDeadline, 'DD/MM/YY'),
              width: 110,
            },
            {
              accessor: 'paidAt',
              title: 'Pago em',
              render: ({ paidAt }) =>
                paidAt && formatLocale(paidAt, 'DD/MM/YY'),
              width: 110,
            },
            {
              accessor: 'billetNumber',
              title: 'Numero do Boleto',
            },
            {
              accessor: 'paymentNote',
              title: 'Obs de Pagamento',
              width: 200,
            },
          ]}
        />
        <Group position="right">
          <Text>
            preço total:{' '}
            {formatBRL(Number(paymentList?.meta?.custom?.total).toFixed(2))}
          </Text>
          <Text>
            valor pago:{' '}
            {formatBRL(Number(paymentList?.meta?.custom?.totalPaid).toFixed(2))}
          </Text>
        </Group>
      </Flex>
      <ExportableModal
        title="Exportável de Pagamentos"
        loading={orderPaymentReportListExportLoader}
        opened={pageModal === 'payment-exportable'}
        onClose={() => setPageModal(null)}
      />
    </Page>
  );
}

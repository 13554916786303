import {
  Badge,
  Button,
  Flex,
  Menu,
  Modal,
  Text,
  TextInput,
} from '@mantine/core';
import {
  IconBusinessplan,
  IconCirclePlus,
  IconDotsVertical,
  IconEdit,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useForm } from '@mantine/form';
import { useSelector } from 'react-redux';
import { CustomDatePicker } from '../../components/CustomDatePicker';
import { CustomLoader } from '../../components/CustomLoader';
import { Page } from '../../components/Page';
import {
  useCreateSaleGoal,
  useGetSaleGoals,
  useRemoveSaleGoal,
} from '../../data/hooks/sale-goals';
import { getTeamsRequest } from '../../data/services/teams';
import { Team, TeamType } from '../../models/team';
import { UserType } from '../../models/user';
import { dayjsPlugins, formatLocale } from '../../providers/dayjs-plugins';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import { teamTypeHumanized } from '../../utils/constants';
import { AppRoutePaths } from '../../utils/enums';
import { formatBRL, realToUSCash } from '../../utils/helpers';

export type TeamListPageModalVisible = 'sale-goal' | null;

type CreateGoal = {
  teamId: number | undefined;
  period: string;
  goal: string;
};

export function TeamList() {
  const { user } = useSelector((state: RootState) => state.auth);
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [teamList, setTeamList] = useState<Team[]>([]);
  const [pageModalVisible, setPageModalVisible] =
    useState<TeamListPageModalVisible>(null);
  const [isCreateGoal, setIsCreateGoal] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<number>();

  const formCreateGoal = useForm<CreateGoal>({
    initialValues: {
      teamId: undefined,
      period: '',
      goal: '',
    },
  });

  async function getTeams() {
    try {
      setLoading(true);
      const response = await getTeamsRequest();
      setTeamList(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao buscar equipes!',
        message: 'tente novamente.',
      });
    }
  }

  const {
    fetch: getSaleGoalsFetcher,
    loading: getSaleGoalsLoader,
    response: getSaleGoalsResponse,
  } = useGetSaleGoals();

  const { fetch: createSaleGoalFetcher, loading: createSaleGoalLoader } =
    useCreateSaleGoal();

  const { fetch: removeSaleGoalFetcher, loading: removeSaleGoalLoader } =
    useRemoveSaleGoal();

  async function handleSelectTeam(teamId: number) {
    setSelectedTeam(teamId);
    setPageModalVisible('sale-goal');
  }

  async function handleCreateSaleGoal() {
    if (!formCreateGoal.values.period) {
      errorNotification({
        title: '',
        message: 'Periodo é obrigatório',
      });
      return;
    }

    const validatedForm: any = {};

    Object.entries(formCreateGoal.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedForm[key] = val;
      }
    });

    if (selectedTeam) {
      await createSaleGoalFetcher({
        teamId: selectedTeam,
        data: {
          goal: realToUSCash(validatedForm.goal),
          period: validatedForm.period,
        },
        onSuccess: () => {
          successNotification({
            title: 'tudo certo!',
            message: 'Meta criada com sucesso ✅',
          });
          handleGetSaleGoals();
          setIsCreateGoal(false);
          formCreateGoal.reset();
        },
      });
    }
  }

  async function handleGetSaleGoals() {
    if (selectedTeam) {
      await getSaleGoalsFetcher({ query: { team: selectedTeam } });
    }
  }

  async function handleRemoveSaleGoal(saleGoalId: number) {
    await removeSaleGoalFetcher({ id: saleGoalId });
    await handleGetSaleGoals();
  }

  useEffect(() => {
    getTeams();
  }, []);

  useMemo(() => {
    handleGetSaleGoals();
  }, [selectedTeam]);

  return (
    <Page title="Equipes">
      <CustomLoader
        loading={getSaleGoalsLoader || loading || removeSaleGoalLoader}
      />
      <Flex direction="column" w="99%">
        <Flex align="center" justify="end" mb={8}>
          <Button
            color="dark"
            leftIcon={<IconCirclePlus />}
            onClick={() => navigation(AppRoutePaths.TEAMS_CREATE)}
          >
            Novo
          </Button>
        </Flex>
        <DataTable
          fetching={loading}
          minHeight={500}
          noRecordsText="Sem usuários"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          records={teamList}
          columns={[
            {
              accessor: 'name',
              title: 'Nome',
            },
            {
              accessor: 'email',
              title: 'Email',
            },
            {
              accessor: 'type',
              title: 'Tipo',
              render: ({ type }) => <Badge>{teamTypeHumanized[type]}</Badge>,
            },
            {
              accessor: '',
              title: '',
              render: ({ id, type }) => (
                <Menu>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      onClick={() => navigation(`edit/${id}`)}
                      icon={<IconEdit size={14} />}
                    >
                      Editar
                    </Menu.Item>
                    <Menu.Item
                      disabled={type !== TeamType.VENDOR}
                      onClick={() => handleSelectTeam(id)}
                      icon={<IconBusinessplan size={14} />}
                    >
                      Metas
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />
      </Flex>

      <Modal
        size={500}
        opened={pageModalVisible === 'sale-goal'}
        onClose={() => setPageModalVisible(null)}
        title="Metas"
      >
        <>
          {!isCreateGoal && user?.type === UserType.MASTER && (
            <Flex justify={'flex-end'} align={'center'}>
              <Button mb={'xs'} onClick={() => setIsCreateGoal(true)}>
                Novo
              </Button>
            </Flex>
          )}

          {isCreateGoal && (
            <form
              onSubmit={formCreateGoal.onSubmit(() => handleCreateSaleGoal())}
            >
              <Flex align={'center'} justify={'center'} direction={'column'}>
                <Flex gap={'xs'}>
                  <CustomDatePicker
                    label="Periodo"
                    placeholder="Selecione o mês"
                    withAsterisk={true}
                    required={true}
                    lang="br"
                    name="period"
                    onChange={(value) => {
                      value
                        ? formCreateGoal.setFieldValue(
                            'period',
                            dayjsPlugins(value).format('YYYY-MM-DD'),
                          )
                        : formCreateGoal.setFieldValue(
                            'period',
                            dayjsPlugins().format('YYYY-MM-DD'),
                          );
                    }}
                  />
                  <TextInput
                    required
                    withAsterisk
                    label="Meta"
                    placeholder="Valor da meta"
                    mb={16}
                    type="text"
                    name="goal"
                    value={formatBRL(formCreateGoal.values.goal ?? '')}
                    onChange={(e) =>
                      formCreateGoal.setFieldValue(
                        'goal',
                        formatBRL(e.target.value),
                      )
                    }
                  />
                </Flex>

                <Flex gap={'xs'}>
                  <Button
                    onClick={() => {
                      setIsCreateGoal(false);
                      formCreateGoal.reset();
                    }}
                    color="dark"
                    size="xs"
                  >
                    Cancelar
                  </Button>
                  <Button
                    loading={createSaleGoalLoader}
                    color="ltpblue.9"
                    type="submit"
                    size="xs"
                  >
                    Salvar
                  </Button>
                </Flex>
              </Flex>
            </form>
          )}

          <DataTable
            fetching={loading}
            minHeight={500}
            noRecordsText="Sem metas"
            withBorder
            borderRadius="sm"
            striped
            highlightOnHover
            records={getSaleGoalsResponse ?? []}
            mt={'xs'}
            columns={[
              {
                accessor: 'period',
                title: 'Periodo',
                render: ({ period }) => (
                  <Text>{period && formatLocale(period, 'DD/MM/YYYY')}</Text>
                ),
              },
              {
                accessor: 'goal',
                title: 'Meta',
                render: ({ goal }) => <Text>{formatBRL(goal)}</Text>,
              },
              {
                accessor: '',
                title: '',
                render: ({ id }) => (
                  <Menu>
                    <Menu.Target>
                      <Button color="blue" variant="subtle" w={40} p={0}>
                        <IconDotsVertical />
                      </Button>
                    </Menu.Target>
                    <Menu.Dropdown style={{ position: 'absolute' }}>
                      <Menu.Item
                        icon={<IconEdit size={14} />}
                        onClick={() => handleRemoveSaleGoal(id)}
                      >
                        Remover
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
        </>
      </Modal>
    </Page>
  );
}

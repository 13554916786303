import { useFetch } from '../../../hooks/use-fetch';
import { Tag } from '../../../models/tag';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  UseCreateTagProps,
  UseDeleteTagProps,
  UseListTagProps,
  UseRecoverTagProps,
  UseUpdateTagProps,
} from '../../../utils/types/data/hooks/tags';
import {
  createTagRequest,
  deleteTagRequest,
  listTagRequest,
  recoverTagRequest,
  showTagRequest,
  updateTagRequest,
} from '../../services/tags';

export function useCreateTag() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<Tag>();

  const fetch = ({ data, onSuccess }: UseCreateTagProps) =>
    fetchData({
      fetcher: () => createTagRequest(data),
      onError: () => {
        errorNotification({
          title: 'Erro ao criar tag.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useUpdateTag() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<Tag>();

  const fetch = ({ id, data, onSuccess }: UseUpdateTagProps) =>
    fetchData({
      fetcher: () => updateTagRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao atualizar tag.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useShowTag() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<Tag>();

  const fetch = ({ id }: { id: number }) =>
    fetchData({
      fetcher: () => showTagRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar tag.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useListTag() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<Tag[]>();

  const fetch = ({ query }: UseListTagProps) =>
    fetchData({
      fetcher: () => listTagRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar tags.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useDeleteTag() {
  const {
    fetchData,
    state: { error, loading },
  } = useFetch<void>();

  const fetch = ({ id, onSuccess }: UseDeleteTagProps) =>
    fetchData({
      fetcher: () => deleteTagRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao deletar tag.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    error,
    loading,
  };
}

export function useRecoverTag() {
  const {
    fetchData,
    state: { error, loading },
  } = useFetch<void>();

  const fetch = ({ id, onSuccess }: UseRecoverTagProps) =>
    fetchData({
      fetcher: () => recoverTagRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao recuperar tag.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    error,
    loading,
  };
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Flex,
  Grid,
  Group,
  Modal,
  ScrollArea,
  Select,
  Table,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
} from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import { IconAlertCircle, IconRefreshDot, IconTestPipe2 } from '@tabler/icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { CustomDateTimePicker } from '../../components/CustoDateTimePicker';
import { CustomFileInput } from '../../components/CustomFileInput';
import { CustomLoader } from '../../components/CustomLoader';
import { rootNavigate } from '../../components/CustomRouter';
import { ExportableModal } from '../../components/ExportableModal';
import { OrderDetails } from '../../components/OrderDetails';
import { OrderFile } from '../../components/OrderFile';
import { Page } from '../../components/Page';
import { useGetUserCompanies } from '../../data/hooks/companies';
import {
  useInvoiceOrderListExportable,
  useOrderListExportable,
} from '../../data/hooks/exportables';
import { useListOptions } from '../../data/hooks/options';
import {
  useChangeOrderPrices,
  useReleaseToProduction,
  useSetOrderFinancialNote,
} from '../../data/hooks/orders';
import { uploadMultipleFilesRequest } from '../../data/services/files';
import { getCompanyToFinance } from '../../data/services/financial';
import {
  addOrderLayoutRequest,
  orderLayoutRequest,
  updateOrderLayoutRequest,
} from '../../data/services/order-layout';
import {
  associateOrderMaterialRequest,
  paginatedOrderMaterialsRequest,
} from '../../data/services/order-material';
import { useFileDownload } from '../../hooks/use-file-download';
import { useUrlParams } from '../../hooks/use-url-params';
import { Company } from '../../models/company';
import { OptionSubTypes, OptionTypes } from '../../models/option';
import { Order, OrderStatus } from '../../models/order';
import { MaterialStatus, OrderMaterial } from '../../models/order-material';
import { TeamConfigAlias } from '../../models/team-config';
import { UserRole, UserType } from '../../models/user';
import { dayjsPlugins } from '../../providers/dayjs-plugins';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import {
  createReworkOrderDispatcher,
  getOrdersPaginatedDispatcher,
  setOrderChangeDispatchDispatcher,
  setOrderDispatchmentDispatcher,
  setOrderFinishDispatcher,
  setOrderPausedDispatcher,
  setOrderReceivementDispatcher,
  updateOrderDatesDispatcher,
  updateOrderStatusDispatcher,
} from '../../store/orders';
import { statusList, statusOrder } from '../../utils/constants';
import {
  formatBRL,
  getNFSEDiscrimination,
  getOrderPrices,
  realToUSCash,
} from '../../utils/helpers';
import { AdminHomeWeb } from './admin/web';
import { CompanyHomeWeb } from './company/web';
import { FinalArtHomeWeb } from './final-art/web';
import { FinancialOrderListWeb } from './financial/web';
import { HandlerOrderListWeb } from './handler/web';
import { PackerHomeWeb } from './packer/web';
import { ReceptionistHomeWeb } from './receptionist/web';
import { TechnicalHomeWeb } from './technical/web';
import { VendorHomeWeb } from './vendor/web';
import { useValidationPaymentSlipGroupByOrder } from '../../data/hooks/payment-slip';

export type FormFilterType = {
  status?: string | null;
  finishForecast?: string | null;
  receiptForecast?: string | null;
  dispatchedAt?: string | null;
  company?: string | undefined;
  os?: string | null;
  financialStatus?: string | null;
  internalNumber?: string | null;
  limit: number;
  page: number;
  services?: string | null;
  paymentStatus?: string;
  paymentDeadline?: string;
  createdAt?: string | undefined;
  issueDate?: string;
  financialNote?: string;
  paymentType?: string;
  service?: string | null;
  product?: string;
  quantity?: number;
  engravingText?: string;
  showRework?: boolean;
};

type CreateReworkOrderForm = {
  reworkUser: string;
};

export type OrderListPageModalVisible =
  | 'view-nfse'
  | 'create-nfse'
  | 'associate-oie'
  | 'payment'
  | 'change-financial-note'
  | 'exportable-loading'
  | 'create-rework'
  | 'change-order-values'
  | null;

export type DateModalState =
  | 'receipt'
  | 'dispatch'
  | 'receipt-vendor'
  | 'dispatch-vendor'
  | null;

function MaterialTable({
  items,
  form,
  order,
}: {
  items: OrderMaterial[];
  form: UseFormReturnType<any>;
  order: Order | undefined;
}) {
  return (
    <Flex direction="column">
      <Text fw="bold">Lista de ARs</Text>
      <Table highlightOnHover withBorder striped>
        <thead>
          <tr>
            <th>Código</th>
            <th>Produto</th>
            <th>Qtde</th>
            <th>Volume</th>
          </tr>
        </thead>
        <tbody>
          {items &&
            items.map((item) => (
              <tr
                onClick={() =>
                  item.status === MaterialStatus.APPROVED &&
                  form.setValues({
                    orderId: String(order?.id),
                    materialId: String(item.id),
                  })
                }
                style={{
                  backgroundColor:
                    form.values.materialId === String(item.id)
                      ? '#228be640'
                      : '',
                  cursor:
                    item.status === MaterialStatus.APPROVED
                      ? 'pointer'
                      : 'not-allowed',
                }}
              >
                <td>{item.code}</td>
                <td>{`${item?.product}, ${item?.productColor}, ${item?.productMaterial}`}</td>
                <td>{item.quantity}</td>
                <td>{item.quantityVolume}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Flex>
  );
}

export function OrderList() {
  const navigation = useNavigate();
  const { items, loading, meta } = useSelector(
    (state: RootState) => state.orders,
  );
  const [filtersCollapse, setFiltersCollapse] = useState(false);
  const { user, userTeam } = useSelector((state: RootState) => state.auth);
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const currentPageLimit = useRef(10);
  const [orderMaterialList, setOrderMaterialList] = useState<OrderMaterial[]>(
    [],
  );
  const [changeDateModalVisible, setChangeDateModalVisible] =
    useState<DateModalState>(null);
  const dispatch = useDispatch<any>();
  const [pageLoading, setPageLoading] = useState(false);
  const [changeStatusModalVisible, setChangeStatusModalVisible] =
    useState(false);
  const [changeDispatchModalVisible, setChangeDispatchModalVisible] =
    useState(false);
  const [clientChageDisptachModalVisible, setClientChageDisptachModalVisible] =
    useState(false);
  const [orderFilesModalVisible, setOrderFilesModalVisible] = useState(false);
  const [orderViewModalVisible, setOrderViewModalVisible] = useState(false);
  const [orderLayoutModalVisible, setOrderLayoutModalVisible] = useState<
    'send' | null
  >(null);
  const [companyFinancialFields, setCompanyFinancialFields] =
    useState<Company>();
  const [pageModalVisible, setPageModalVisible] =
    useState<OrderListPageModalVisible>(null);
  const { fetch: setFinancialNoteFetcher, loading: setFinancialNoteLoading } =
    useSetOrderFinancialNote();
  const {
    fetch: orderListExportableFetcher,
    loading: orderListExportableLoader,
  } = useOrderListExportable();
  const {
    fetch: getListOptionsFetcher,
    loading: getListOptionsLoader,
    reponseData: getListOptionsData,
  } = useListOptions();
  const { download } = useFileDownload();
  const {
    fetch: getUserCompaniesFetcher,
    loading: getUserCompaniesLoading,
    response: getUserCompaniesData,
  } = useGetUserCompanies();
  const {
    fetch: invoiceOrderListExportFetcher,
    loading: invoiceOrderListExportLoader,
  } = useInvoiceOrderListExportable();
  const {
    fetch: releaseToProductionFetcher,
    loading: releaseToProductionLoader,
  } = useReleaseToProduction();
  const { fetch: changeOrderPriceFetcher, loading: changeOrderPriceLoader } =
    useChangeOrderPrices();
  const {
    fetch: validationPaymentSlipGroupFetcher,
    response: validationPaymentSlipGroupResponse,
    loading: validationPaymentSlipGroupLoader,
  } = useValidationPaymentSlipGroupByOrder();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = useRef(Number(searchParams.get('page')) || 1);
  const [osList, setOsList] = useState<string[]>([]);

  const fromSetFinancialNote = useForm({
    initialValues: {
      financialNote: '',
      job: '',
    },
  });

  const formOrderLayout = useForm({
    initialValues: {
      file: null,
    },
  });

  const formStatusChange = useForm({
    initialValues: {
      status: '',
      quantityVolume: '',
      description: '',
      carrierName: '',
      incorrectQuantity: '',
      isInconrrect: false,
      finishVendorForecast: new Date(),
    },
  });

  const formDatesChange = useForm({
    initialValues: {
      carrierName: '',
      incorrectQuantity: '',
      quantityVolume: '',
      isInconrrect: false,
    },
  });

  const reworkOrderForm = useForm<CreateReworkOrderForm>({
    initialValues: {
      reworkUser: '',
    },
  });

  const formFilter = useForm<FormFilterType>({
    initialValues: {
      limit: 10,
      services: userTeam?.configs.find(
        (item) => item.alias === TeamConfigAlias.SERVICE_FILTER,
      )?.value,
      page: Number(searchParams.get('page')) || 1,
      company: searchParams.get('company') || '',
      dispatchedAt: searchParams.get('dispatchedAt') || null,
      finishForecast: searchParams.get('finishForecast') || null,
      internalNumber: searchParams.get('internalNumber') || '',
      os: searchParams.get('os') || '',
      financialStatus: searchParams.get('financialStatus') || null,
      receiptForecast: searchParams.get('receiptForecast') || null,
      status: searchParams.get('status') || '',
      paymentDeadline: searchParams.get('paymentDeadline') || undefined,
      paymentStatus: searchParams.get('paymentStatus') || undefined,
      createdAt: searchParams.get('createdAt') || undefined,
      issueDate: searchParams.get('issueDate') || undefined,
      paymentType: searchParams.get('paymentType') || '',
      product: searchParams.get('product') || '',
      quantity: Number(searchParams.get('quantity')) || undefined,
      engravingText: searchParams.get('engravingText') || '',
      service: searchParams.get('service') || null,
      financialNote: searchParams.get('financialNote') || '',
    },
  });

  useUrlParams(formFilter, currentPage, searchParams, setSearchParams);

  const formClientChangeDispatch = useForm({
    initialValues: {
      receiptForecast: new Date(),
      dispatchForecast: new Date(),
      carrierName: '',
    },
  });

  const formDispatchChange = useForm({
    initialValues: {
      dispatchLocation: selectedOrder?.dispatchLocation ?? '',
      dispatchForecast:
        dayjsPlugins(selectedOrder?.dispatchForecast).toDate() ?? new Date(),
    },
  });

  const formEmitNFSe = useForm({
    initialValues: {
      discrimination: '',
      taxesPercentage: '',
      serviceCode: '06912',
      orderPrice: '',
      financialNote: '',
      nfPaymentDeadline: new Date(),
    },
  });

  const formAssociate = useForm({
    initialValues: {
      orderId: '',
      materialId: '',
    },
  });

  const formOrderPayment = useForm({
    initialValues: {
      financialNote: '',
      paymentStatus: '',
      paymentDeadline: new Date(),
    },
  });

  const formChangePrices = useForm({
    initialValues: {
      price: '',
      samplePrice: '',
      clichePrice: '',
      pholitoPrice: '',
      manipulationPrice: '',
      vectorizationPrice: '',
    },
  });

  const { orderUnitPrice, orderTotalPrice } = useMemo(() => {
    const price =
      Number(realToUSCash(String(formChangePrices.values.price))) || 0;
    const samplePrice =
      Number(realToUSCash(String(formChangePrices.values.samplePrice))) || 0;
    const photolitoPrice =
      Number(realToUSCash(String(formChangePrices.values.pholitoPrice))) || 0;
    const manipulationPrice =
      Number(realToUSCash(String(formChangePrices.values.manipulationPrice))) ||
      0;
    const vectorizationPrice =
      Number(
        realToUSCash(String(formChangePrices.values.vectorizationPrice)),
      ) || 0;
    const clichePrice =
      Number(realToUSCash(String(formChangePrices.values.clichePrice))) || 0;
    const quantity = Number(selectedOrder?.quantity);
    const unitPrice = Math.fround(price / quantity);
    const orderUnitPrice = formatBRL(unitPrice.toFixed(2));
    const orderTotalPrice = formatBRL(
      (
        price +
        samplePrice +
        photolitoPrice +
        manipulationPrice +
        vectorizationPrice +
        clichePrice
      ).toFixed(2),
    );

    return { orderUnitPrice, orderTotalPrice };
  }, [formChangePrices.values]);

  const teamConfigEditOrder = useMemo(() => {
    return userTeam?.configs.find(
      (item) => item.alias === TeamConfigAlias.EDIT_ORDER_POST_PRODUCTION,
    );
  }, []);

  function handleSelectToFinancialNote(order: Order) {
    setSelectedOrder(order);
    fromSetFinancialNote.setFieldValue(
      'financialNote',
      order.financialNote ?? '',
    );
    fromSetFinancialNote.setFieldValue('job', order.job ?? '');
    setPageModalVisible('change-financial-note');
  }

  function handleSubmitChangeStatus(values: typeof formStatusChange.values) {
    if (!selectedOrder) return;

    if (
      values.status === OrderStatus.RELEASED_PRODUCTION &&
      Number(selectedOrder.price) <= 0
    ) {
      errorNotification({
        title: 'Ops!',
        message: 'não é possível liberar produção sem Preço (total gravação).',
      });
      return;
    }

    if (
      values.status === OrderStatus.RELEASED_PRODUCTION &&
      selectedOrder.paymentType === null
    ) {
      errorNotification({
        title: 'não é possível liberar produção sem forma de pagamento.',
        message: 'edite o pedido',
      });
      return;
    }

    if (
      values.status === OrderStatus.RELEASED_PRODUCTION &&
      (selectedOrder.paymentType === 'virtual' ||
        selectedOrder.paymentType === 'service_sample')
    ) {
      errorNotification({
        title: 'não é possível liberar produção de virtual ou amostra.',
        message: 'fale com a gerencia',
      });
      return;
    }

    if (values.status === OrderStatus.PAUSED) {
      dispatch(
        setOrderPausedDispatcher(selectedOrder.id, {
          description: values.description,
        }),
      );
      formStatusChange.reset();
      setChangeStatusModalVisible(false);
      return;
    }

    const changeStatusHandler = () => {
      if (values.status === OrderStatus.FINISHED) {
        dispatch(
          setOrderFinishDispatcher(selectedOrder.id, {
            quantityVolume: Number(values.quantityVolume),
          }),
        );
      } else if (values.status === OrderStatus.MATERIAL_RECEIVED) {
        dispatch(
          setOrderReceivementDispatcher(selectedOrder.id, {
            carrierName: values.carrierName,
            quantityVolume: Number(values.quantityVolume),
            incorrectQuantity: Number(values.incorrectQuantity),
            orderPreviusQuantity: Number(selectedOrder.quantity),
          }),
        );
      } else if (values.status === OrderStatus.DISPATCHED) {
        dispatch(
          setOrderDispatchmentDispatcher(selectedOrder.id, {
            carrierName: values.carrierName,
            quantityVolume: Number(values.quantityVolume),
          }),
        );
      } else if (values.status === OrderStatus.RELEASED_PRODUCTION) {
        handleReleaseToProduction();
      } else {
        dispatch(
          updateOrderStatusDispatcher(selectedOrder.id, {
            status: values.status as OrderStatus,
          }),
        );
      }

      formStatusChange.reset();
      setChangeStatusModalVisible(false);
    };

    const validateStatusSequence =
      values.status &&
      statusOrder[values.status as OrderStatus] >
        statusOrder[selectedOrder.status];

    if (
      teamConfigEditOrder?.value === 'true' &&
      statusOrder[selectedOrder.status] < statusOrder[OrderStatus.FINISHED]
    ) {
      changeStatusHandler();
    } else if (!teamConfigEditOrder?.value && validateStatusSequence) {
      changeStatusHandler();
    } else if (!teamConfigEditOrder?.value && !validateStatusSequence) {
      errorNotification({
        title: 'Ops!',
        message: 'você nao pode alterar para um status anterior ao atual',
      });
    } else {
      errorNotification({
        title: 'Ops!',
        message: 'você nao pode alterar status de um pedido finalizado',
      });
    }
  }

  function handleSubmitChangeDates(values: typeof formDatesChange.values) {
    if (!selectedOrder) return;

    if (changeDateModalVisible === 'dispatch') {
      dispatch(
        setOrderDispatchmentDispatcher(selectedOrder.id, {
          carrierName: values.carrierName,
          quantityVolume: Number(values.quantityVolume),
        }),
      );
      formDatesChange.reset();
    } else if (changeDateModalVisible === 'receipt') {
      dispatch(
        setOrderReceivementDispatcher(selectedOrder.id, {
          carrierName: values.carrierName,
          quantityVolume: Number(values.quantityVolume),
          incorrectQuantity: Number(values.incorrectQuantity),
          orderPreviusQuantity: Number(selectedOrder.quantity),
        }),
      );
      formDatesChange.reset();
    }
    setChangeDateModalVisible(null);
  }

  function handleSubmit() {
    currentPage.current = 1;
    getOrderPaginated();
  }

  function handleSubmitClientChangeDispatch(
    values: typeof formClientChangeDispatch.values,
  ) {
    if (!selectedOrder) return;

    dispatch(
      updateOrderDatesDispatcher(Number(selectedOrder.id), {
        dispatchForecast: values.dispatchForecast,
        carrierName: values.carrierName,
      }),
    );
    formClientChangeDispatch.reset();
    setClientChageDisptachModalVisible(false);
  }

  function handleClearFilter() {
    setSearchParams(new URLSearchParams());
    Object.entries(formFilter.values).forEach(([key]) => {
      if (key !== 'page' && key !== 'limit') {
        formFilter.setFieldValue(key, '');
      }
    });
    setOsList([]);
    currentPage.current = 1;
    currentPageLimit.current = 10;
    getOrderPaginated(true);
  }

  function handlePaginate(page: number) {
    currentPage.current = page;
    getOrderPaginated();
  }

  function handleChangePageLimit(limit: number) {
    currentPageLimit.current = limit;
    getOrderPaginated();
  }

  async function getOrderPaginated(isReset = false) {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    if (isReset) {
      dispatch(
        getOrdersPaginatedDispatcher({
          page: 1,
          limit: 10,
          services: validatedFilter.services,
        }),
      );
    } else {
      dispatch(
        getOrdersPaginatedDispatcher({
          ...validatedFilter,
          page: currentPage.current,
          limit: currentPageLimit.current,
        }),
      );
    }
  }

  function handleSubmitChangeDispatchData(
    values: typeof formDispatchChange.values,
  ) {
    if (!selectedOrder) return;

    dispatch(setOrderChangeDispatchDispatcher(selectedOrder.id, values));
    formDatesChange.reset();
    setChangeDispatchModalVisible(false);
  }

  function requestOrderListExportable() {
    setPageModalVisible('exportable-loading');
    handleGetExportable();
  }

  function setOrderToChangePrices(order: Order) {
    setSelectedOrder(order);
    setPageModalVisible('change-order-values');
    formChangePrices.setValues({
      clichePrice: formatBRL(order.clichePrice ?? '0'),
      manipulationPrice: formatBRL(order.manipulationPrice ?? '0'),
      pholitoPrice: formatBRL(order.pholitoPrice ?? '0'),
      price: formatBRL(order.price ?? '0'),
      samplePrice: formatBRL(order.samplePrice ?? '0'),
      vectorizationPrice: formatBRL(order.vectorizationPrice ?? '0'),
    });
  }

  async function handleCreateReworkOrder(reworkUser: string) {
    if (!selectedOrder || !reworkUser) return;
    dispatch(
      createReworkOrderDispatcher({
        originOrderId: selectedOrder.id,
        reworkUser: reworkUser,
      }),
    );
  }

  async function getOrderMaterial(companyId: string) {
    try {
      setPageLoading(true);
      const response = await paginatedOrderMaterialsRequest({
        limit: 20,
        page: 1,
        company: companyId,
      });
      setOrderMaterialList(response.items);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar ARs!',
        message: 'tente novamente.',
      });
    }
  }

  async function handleAssociateMaterial(values: typeof formAssociate.values) {
    try {
      setPageLoading(true);
      await associateOrderMaterialRequest(Number(values.materialId), {
        orderId: Number(values.orderId),
      });
      setPageLoading(false);
      formAssociate.reset();
      setPageModalVisible(null);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao associar AR!',
        message: 'tente novamente.',
      });
    }
  }

  async function handleRequestLayout(id: number) {
    try {
      await orderLayoutRequest(id);
      successNotification({
        title: 'Layout solicitado!',
        message: 'em breve o arte-finalista receberá o e-mail.',
      });
    } catch (error) {
      errorNotification({
        title: 'Erro ao solicitar layout!',
        message: 'ops tente novamente.',
      });
    }
  }

  async function handleSubmitOrderLayout(
    values: typeof formOrderLayout.values,
    type: 'update' | 'create',
  ) {
    if (values.file) {
      try {
        setPageLoading(true);
        const fileIds = await uploadMultipleFilesRequest({
          files: [values.file],
        });
        if (type === 'create') {
          await addOrderLayoutRequest(Number(selectedOrder?.id), {
            file: { id: fileIds[0] },
          });
        } else if (type === 'update') {
          await updateOrderLayoutRequest(Number(selectedOrder?.id), {
            file: { id: fileIds[0] },
          });
          getOrderPaginated();
          setOrderFilesModalVisible(false);
        }
        setPageLoading(false);
        setOrderLayoutModalVisible(null);
        formOrderLayout.reset();
      } catch (error) {
        setPageLoading(false);
        errorNotification({
          title: 'Erro ao enviar layout',
          message: 'ops! algo deu errado, tente novamente.',
        });
      }
    }
  }

  async function handleSubmitFinancialNote(
    values: typeof fromSetFinancialNote.values,
  ) {
    if (selectedOrder) {
      setFinancialNoteFetcher({
        id: selectedOrder?.id,
        data: { financialNote: values.financialNote, job: values.job },
        onSuccess: () => {
          setPageModalVisible(null);
          setSelectedOrder(undefined);
          fromSetFinancialNote.reset();
          getOrderPaginated();
        },
      });
    }
  }

  async function handleGetExportable() {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    await orderListExportableFetcher({
      query: validatedFilter,
      onSuccess: (res) => {
        download(res, 'lista-de-pedidos.xlsx');
      },
    });
  }

  async function getUserCompanies() {
    await getUserCompaniesFetcher({
      userId: Number(user?.id),
    });
  }

  async function getLocationOptions() {
    await getListOptionsFetcher({
      query: {
        type: OptionTypes.ORDER,
        subtype: OptionSubTypes.ORDER_DISPATCH_LOCATIONS,
      },
    });
  }

  async function handleReleaseToProduction() {
    if (selectedOrder) {
      await releaseToProductionFetcher({
        id: selectedOrder?.id,
        data: {
          finishVendorForecast: formStatusChange.values.finishVendorForecast,
        },
        onSuccess: () => {
          formStatusChange.reset();
          getOrderPaginated();
          setChangeStatusModalVisible(false);
        },
      });
    }
  }

  async function handleRequestInvoiceOrderListExport() {
    if (items) {
      invoiceOrderListExportFetcher({
        query: {
          os: items.map((item) => item.os).join(','),
        },
        onSuccess: (data) => {
          download(data, 'pedidos-faturamento.xlsx');
        },
      });
    }
  }

  async function handleChangePrices(values: typeof formChangePrices.values) {
    if (selectedOrder) {
      changeOrderPriceFetcher({
        id: selectedOrder?.id,
        data: {
          clichePrice: realToUSCash(values.clichePrice),
          manipulationPrice: realToUSCash(values.manipulationPrice),
          pholitoPrice: realToUSCash(values.pholitoPrice),
          price: realToUSCash(values.price),
          samplePrice: realToUSCash(values.samplePrice),
          vectorizationPrice: realToUSCash(values.vectorizationPrice),
        },
        onSuccess: () => {
          setPageModalVisible(null);
          formChangePrices.reset();
          getOrderPaginated();
        },
      });
    }
  }

  useEffect(() => {
    if (selectedOrder && pageModalVisible === 'create-nfse') {
      if (selectedOrder.company.id !== companyFinancialFields?.id) {
        getCompanyToFinance(selectedOrder.company.id).then((company) =>
          setCompanyFinancialFields(company),
        );
      }

      const orderPrice = getOrderPrices(selectedOrder);
      formEmitNFSe.setFieldValue('orderPrice', orderPrice.totalPrice);
      formEmitNFSe.setFieldValue(
        'discrimination',
        getNFSEDiscrimination(selectedOrder, orderPrice).join(),
      );
    } else if (selectedOrder && pageModalVisible === 'payment') {
      formOrderPayment.setFieldValue(
        'financialNote',
        selectedOrder.financialNote ?? '',
      );
    } else if (selectedOrder && changeStatusModalVisible) {
      formStatusChange.setFieldValue(
        'finishVendorForecast',
        dayjsPlugins(selectedOrder.finishVendorForecast ?? new Date()).toDate(),
      );
    }
  }, [selectedOrder]);

  useEffect(() => {
    getOrderPaginated();
    getLocationOptions();
    if (user?.role === UserRole.COMPANY) {
      getUserCompanies();
    }
  }, []);

  return (
    <Page title="Pedidos [Andamento]">
      <CustomLoader
        loading={
          loading ||
          setFinancialNoteLoading ||
          pageLoading ||
          getListOptionsLoader ||
          getUserCompaniesLoading ||
          invoiceOrderListExportLoader ||
          releaseToProductionLoader ||
          changeOrderPriceLoader
        }
      />
      {user?.role === UserRole.COMPANY && (
        <CompanyHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          handleChangePageLimit={handleChangePageLimit}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={rootNavigate as NavigateFunction}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setOrderFilesModalVisible={setOrderFilesModalVisible}
          setOrderViewModalVisible={setOrderViewModalVisible}
          getUserCompaniesData={getUserCompaniesData}
        />
      )}
      {user?.role === UserRole.VENDOR && (
        <VendorHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          handleChangePageLimit={handleChangePageLimit}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={rootNavigate as NavigateFunction}
          handleClear={handleClearFilter}
          setChangeStatusModalVisible={setChangeStatusModalVisible}
          setSelectedOrder={setSelectedOrder}
          setOrderFilesModalVisible={setOrderFilesModalVisible}
          setOrderViewModalVisible={setOrderViewModalVisible}
          handleRequestLayout={handleRequestLayout}
          getOrderMaterial={getOrderMaterial}
          setPageModalVisible={setPageModalVisible}
          setChangeDateModalVisible={setChangeDateModalVisible}
          requestOrderListExportable={requestOrderListExportable}
          filtersCollapse={filtersCollapse}
          setFiltersCollapse={setFiltersCollapse}
          onInvoiceOrderListExport={handleRequestInvoiceOrderListExport}
        />
      )}
      {user?.role === UserRole.RECEPTIONIST && (
        <ReceptionistHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          formDispatchChange={formDispatchChange}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={rootNavigate as NavigateFunction}
          handleClear={handleClearFilter}
          setChangeDateModalVisible={setChangeDateModalVisible}
          setSelectedOrder={setSelectedOrder}
          setChangeDispatchModalVisible={setChangeDispatchModalVisible}
          setOrderFilesModalVisible={setOrderFilesModalVisible}
          setOrderViewModalVisible={setOrderViewModalVisible}
          handleSelectToFinancialNote={handleSelectToFinancialNote}
          validationPaymentSlipGroupFetcher={validationPaymentSlipGroupFetcher}
        />
      )}
      {user?.type === UserType.MASTER && (
        <AdminHomeWeb
          handleChangePageLimit={handleChangePageLimit}
          currentPage={currentPage}
          formFilter={formFilter}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={rootNavigate as NavigateFunction}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setOrderFilesModalVisible={setOrderFilesModalVisible}
          setOrderViewModalVisible={setOrderViewModalVisible}
          filtersCollapse={filtersCollapse}
          setFiltersCollapse={setFiltersCollapse}
          setOrderToChangePrices={setOrderToChangePrices}
          requestOrderListExportable={requestOrderListExportable}
          onInvoiceOrderListExport={handleRequestInvoiceOrderListExport}
        />
      )}
      {user?.role === UserRole.TECHNICAL && (
        <TechnicalHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          formDispatchChange={formDispatchChange}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={rootNavigate as NavigateFunction}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setOrderFilesModalVisible={setOrderFilesModalVisible}
        />
      )}
      {user?.role === UserRole.FINAL_ART && (
        <FinalArtHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          formDispatchChange={formDispatchChange}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={navigation}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setOrderFilesModalVisible={setOrderFilesModalVisible}
          setOrderLayoutModalVisible={setOrderLayoutModalVisible}
        />
      )}
      {user?.role === UserRole.PACKER && (
        <PackerHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          formDispatchChange={formDispatchChange}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={rootNavigate as NavigateFunction}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setOrderFilesModalVisible={setOrderFilesModalVisible}
        />
      )}
      {user?.role === UserRole.FINANCIAL && (
        <FinancialOrderListWeb
          currentPage={currentPage}
          formFilter={formFilter}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={rootNavigate as NavigateFunction}
          handleClear={handleClearFilter}
          handleSelectToFinancialNote={handleSelectToFinancialNote}
          handleChangePageLimit={handleChangePageLimit}
          setSelectedOrder={setSelectedOrder}
          setOrderViewModalVisible={setOrderViewModalVisible}
          osList={osList}
          setOsList={setOsList}
          setOrderToChangePrices={setOrderToChangePrices}
          user={user}
        />
      )}
      {user?.role === UserRole.HANDLER && (
        <HandlerOrderListWeb
          currentPage={currentPage}
          formFilter={formFilter}
          formDispatchChange={formDispatchChange}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={rootNavigate as NavigateFunction}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setOrderFilesModalVisible={setOrderFilesModalVisible}
        />
      )}
      <Modal
        opened={changeStatusModalVisible}
        onClose={() => setChangeStatusModalVisible(false)}
        title="Alterar status do pedido"
        size={
          formStatusChange.values.status === OrderStatus.RELEASED_PRODUCTION
            ? 'lg'
            : 'md'
        }
      >
        <form
          onSubmit={formStatusChange.onSubmit((values) =>
            handleSubmitChangeStatus(values),
          )}
        >
          <Grid gutter="xs" columns={2}>
            <Grid.Col span={2}>
              <Select
                required
                withAsterisk
                name="status"
                label="Status"
                placeholder="selecione o novo status do pedido"
                data={
                  user?.role === UserRole.VENDOR
                    ? statusList.filter(
                        (obj) =>
                          obj.value !== OrderStatus.FINISHED &&
                          obj.value !== OrderStatus.PRODUCED &&
                          obj.value !== OrderStatus.DISPATCHED,
                      )
                    : statusList
                }
                mb={16}
                {...formStatusChange.getInputProps('status')}
              />
            </Grid.Col>
            {formStatusChange.values.status === OrderStatus.FINISHED && (
              <Grid.Col span={1}>
                <TextInput
                  withAsterisk
                  required
                  label="Volumes"
                  placeholder="digite os volumes"
                  mb={16}
                  type="number"
                  name="quantityVolume"
                  {...formStatusChange.getInputProps('quantityVolume')}
                />
              </Grid.Col>
            )}
            {formStatusChange.values.status === OrderStatus.PAUSED && (
              <Grid.Col span={2} mb={16}>
                <Textarea
                  maxLength={255}
                  withAsterisk
                  required
                  label="Observações"
                  placeholder="escreva aqui qualquer observação sobre o status pausado"
                  name="description"
                  {...formStatusChange.getInputProps('description')}
                />
              </Grid.Col>
            )}
          </Grid>
          {formStatusChange.values.status === OrderStatus.MATERIAL_RECEIVED && (
            <Grid gutter="xs" columns={2}>
              <Grid.Col span={2}>
                <TextInput
                  withAsterisk
                  required
                  label="Portador"
                  placeholder="nome do portador"
                  mb={16}
                  type="text"
                  name="carrierName"
                  {...formStatusChange.getInputProps('carrierName')}
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <Checkbox
                  label="Quantidade esta incorreta"
                  color="green"
                  name="isInconrrect"
                  mb={16}
                  {...formStatusChange.getInputProps('isInconrrect')}
                />
              </Grid.Col>
              {formStatusChange.values.isInconrrect && (
                <>
                  <Grid.Col span={1}>
                    <TextInput
                      required
                      withAsterisk
                      label="Quantidade"
                      placeholder="digite a quantidade"
                      mb={16}
                      type="number"
                      name="incorrectQuantity"
                      {...formStatusChange.getInputProps('incorrectQuantity')}
                    />
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <TextInput
                      required
                      withAsterisk
                      label="Volumes"
                      placeholder="digite os volumes"
                      mb={16}
                      type="number"
                      name="quantityVolume"
                      {...formStatusChange.getInputProps('quantityVolume')}
                    />
                  </Grid.Col>
                </>
              )}
            </Grid>
          )}
          {formStatusChange.values.status === OrderStatus.DISPATCHED && (
            <Grid gutter="xs" columns={2}>
              <Grid.Col span={2}>
                <TextInput
                  required
                  withAsterisk
                  label="Portador"
                  placeholder="nome do portador"
                  mb={16}
                  type="text"
                  name="carrierName"
                  {...formStatusChange.getInputProps('carrierName')}
                />
              </Grid.Col>
              <Grid.Col span={1}>
                <TextInput
                  withAsterisk
                  required
                  label="Volumes"
                  placeholder="digite os volumes"
                  mb={16}
                  type="number"
                  name="quantityVolume"
                  {...formStatusChange.getInputProps('quantityVolume')}
                />
              </Grid.Col>
            </Grid>
          )}
          {formStatusChange.values.status ===
            OrderStatus.RELEASED_PRODUCTION && (
            <>
              <Grid gutter="xs" columns={2}>
                <Grid.Col span={1}>
                  <CustomDateTimePicker
                    date={formStatusChange.values.finishVendorForecast}
                    label="Prazo de finalização"
                    onChange={(date) =>
                      formStatusChange.setValues({ finishVendorForecast: date })
                    }
                  />
                </Grid.Col>
              </Grid>
              <Card withBorder mb={16}>
                <ScrollArea h={400}>
                  <Text fw="bold" mb={16}>
                    Confirme as informações abaixo antes de liberar para
                    produção:{' '}
                  </Text>
                  {selectedOrder && (
                    <OrderDetails
                      sections={['basic', 'prices', 'service-details']}
                      orderId={selectedOrder?.id}
                    />
                  )}
                </ScrollArea>
              </Card>
            </>
          )}
          <Flex justify="flex-end">
            <Button color="ltpBlue.9" type="submit">
              Salvar
            </Button>
          </Flex>
        </form>
      </Modal>
      <Modal
        opened={
          changeDateModalVisible === 'dispatch' ||
          changeDateModalVisible === 'receipt'
        }
        onClose={() => setChangeDateModalVisible(null)}
        title={`Registrar Retirada`}
      >
        {validationPaymentSlipGroupResponse?.validation !== null &&
          !validationPaymentSlipGroupResponse?.validation && (
            <Alert
              icon={<IconAlertCircle size={16} />}
              title="Atenção"
              color="red"
              mb={16}
            >
              Identificamos que há comprovantes em análise para este pedido.
              Favor entrar em contato com financeiro para mais informações.
            </Alert>
          )}

        <form
          onSubmit={formDatesChange.onSubmit((values) =>
            handleSubmitChangeDates(values),
          )}
        >
          <Grid gutter="xs" columns={2}>
            <Grid.Col span={2}>
              <TextInput
                required
                withAsterisk
                label="Portador"
                placeholder="nome do portador"
                mb={16}
                type="text"
                name="carrierName"
                {...formDatesChange.getInputProps('carrierName')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                withAsterisk
                required
                label="Volumes"
                placeholder="digite os volumes"
                mb={16}
                type="number"
                name="quantityVolume"
                {...formDatesChange.getInputProps('quantityVolume')}
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end">
            <Button
              color="dark"
              type="submit"
              loading={validationPaymentSlipGroupLoader}
              disabled={
                validationPaymentSlipGroupResponse?.validation !== null &&
                !validationPaymentSlipGroupResponse?.validation
              }
            >
              Salvar
            </Button>
          </Flex>
        </form>
      </Modal>
      <Modal
        opened={changeDispatchModalVisible}
        onClose={() => setChangeDispatchModalVisible(false)}
        title={`Alterar Retirada`}
      >
        <form
          onSubmit={formDispatchChange.onSubmit((values) =>
            handleSubmitChangeDispatchData(values),
          )}
        >
          <Grid gutter="xs" columns={2}>
            <Grid.Col span={2}>
              <Select
                required
                name="dispatchLocation"
                withAsterisk
                label="Unidade para retirada"
                placeholder="selecione um local"
                data={getListOptionsData?.map((item) => ({
                  label: item.name,
                  value: item.value,
                }))}
                mt={16}
                mb={24}
                {...formDispatchChange.getInputProps('dispatchLocation')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <CustomDateTimePicker
                date={formDispatchChange.values.dispatchForecast}
                label="Data de Retirada"
                onChange={(date) =>
                  formDispatchChange.setValues({ dispatchForecast: date })
                }
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end">
            <Button color="ltpBlue.9" type="submit">
              Salvar
            </Button>
          </Flex>
        </form>
      </Modal>
      <Modal
        opened={clientChageDisptachModalVisible}
        onClose={() => setClientChageDisptachModalVisible(false)}
        title={`Definir data de retirada`}
      >
        <form
          onSubmit={formClientChangeDispatch.onSubmit((values) =>
            handleSubmitClientChangeDispatch(values),
          )}
        >
          <CustomDateTimePicker
            date={formClientChangeDispatch.values.dispatchForecast}
            label="Data de Retirada"
            onChange={(date) =>
              formClientChangeDispatch.setValues({ dispatchForecast: date })
            }
          />
          <TextInput
            withAsterisk
            required
            label="Portador"
            placeholder="nome do portador"
            mb={16}
            type="text"
            name="carrierName"
            {...formClientChangeDispatch.getInputProps('carrierName')}
          />
          <Flex justify="flex-end">
            <Button color="ltpBlue.9" type="submit">
              Salvar
            </Button>
          </Flex>
        </form>
      </Modal>
      <Modal
        opened={orderViewModalVisible}
        onClose={() => setOrderViewModalVisible(false)}
        title={
          <Text fw="bold">
            Detalhes do Pedido
            {selectedOrder?.rework && user?.role !== UserRole.COMPANY ? (
              <ThemeIcon ml={10} color="grape" variant="light">
                <IconRefreshDot size={24} />
              </ThemeIcon>
            ) : (
              ''
            )}
            {selectedOrder?.isSample && (
              <ThemeIcon ml={10} color="green" variant="light">
                <IconTestPipe2 size={24} />
              </ThemeIcon>
            )}
          </Text>
        }
        size="lg"
      >
        <ScrollArea h={600}>
          {selectedOrder && (
            <OrderDetails
              sections={[
                'basic',
                'prices',
                'service-details',
                'historic',
                'dates',
                'payment',
                'order-receipt-files',
                'problem-reported',
              ]}
              orderId={selectedOrder?.id}
            />
          )}
        </ScrollArea>
      </Modal>
      <Modal
        opened={orderFilesModalVisible}
        onClose={() => setOrderFilesModalVisible(false)}
        title="Arquivos"
      >
        <ScrollArea h={400}>
          <OrderFile files={selectedOrder?.files ?? []} />
        </ScrollArea>
      </Modal>
      <Modal
        opened={!!orderLayoutModalVisible}
        onClose={() => setOrderLayoutModalVisible(null)}
        title="Enviar Layout"
      >
        <Grid gutter="xs" columns={2}>
          <Grid.Col mt={8} mb={8} span={2}>
            <Text color="gray">Info do pedido</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">OS</Text>
              <Text color="gray">{selectedOrder?.os ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Identificação interna</Text>
              <Text color="gray">{selectedOrder?.internalNumber ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Serviço</Text>
              <Text color="gray">{selectedOrder?.service.name ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Quantidade</Text>
              <Text color="gray">{selectedOrder?.quantity ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold">Produto</Text>
              <Text color="gray">
                {`${selectedOrder?.product}, ${selectedOrder?.productColor}, ${selectedOrder?.productMaterial}` ??
                  '--'}
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>
        <form
          onSubmit={formOrderLayout.onSubmit((values) =>
            handleSubmitOrderLayout(values, 'create'),
          )}
        >
          <Grid gutter="xs" columns={2} mt={8}>
            <Grid.Col span={2}>
              <CustomFileInput
                withAsterisk
                required
                name="file"
                value={formOrderLayout.values.file}
                label="Arquivo de Layout"
                placeholder="Anexe aqui o arquivo de layout"
                formRef={formOrderLayout}
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end" mt={16}>
            <Button color="dark" type="submit">
              Enviar
            </Button>
          </Flex>
        </form>
      </Modal>
      <Modal
        size={500}
        opened={pageModalVisible === 'associate-oie'}
        onClose={() => setPageModalVisible(null)}
        title="Associar OIE"
      >
        <Grid gutter="xs" columns={2}>
          <Grid.Col mt={8} mb={8} span={2}>
            <Text color="gray">Info do pedido</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">OS</Text>
              <Text color="gray">{selectedOrder?.os ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Identificação interna</Text>
              <Text color="gray">{selectedOrder?.internalNumber ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Serviço</Text>
              <Text color="gray">{selectedOrder?.service.name ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Quantidade</Text>
              <Text color="gray">{selectedOrder?.quantity ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold">Produto</Text>
              <Text color="gray">
                {`${selectedOrder?.product}, ${selectedOrder?.productColor}, ${selectedOrder?.productMaterial}` ??
                  '--'}
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>
        <MaterialTable
          form={formAssociate}
          items={orderMaterialList}
          order={selectedOrder && selectedOrder}
        />
        <form
          onSubmit={formAssociate.onSubmit((values) =>
            handleAssociateMaterial(values),
          )}
        >
          <Flex justify="flex-end" mt={16}>
            <Button
              disabled={!formAssociate.values.materialId}
              color="dark"
              type="submit"
            >
              Associar
            </Button>
          </Flex>
        </form>
      </Modal>
      <Modal
        size={500}
        opened={pageModalVisible === 'change-financial-note'}
        onClose={() => setPageModalVisible(null)}
        title="Alterar Observação Fiscal"
      >
        <Grid gutter="xs" columns={2}>
          <Grid.Col mt={8} mb={8} span={2}>
            <Text color="gray">Info do pedido</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">OS</Text>
              <Text color="gray">{selectedOrder?.os ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Identificação interna</Text>
              <Text color="gray">{selectedOrder?.internalNumber ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Serviço</Text>
              <Text color="gray">{selectedOrder?.service.name ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Quantidade</Text>
              <Text color="gray">{selectedOrder?.quantity ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold">Produto</Text>
              <Text color="gray">
                {`${selectedOrder?.product}, ${selectedOrder?.productColor}, ${selectedOrder?.productMaterial}` ??
                  '--'}
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>
        <form
          onSubmit={fromSetFinancialNote.onSubmit((values) =>
            handleSubmitFinancialNote(values),
          )}
        >
          <TextInput
            label="Número do JOB"
            placeholder="ex: 1/3"
            mt={16}
            type="text"
            name="job"
            {...fromSetFinancialNote.getInputProps('job')}
          />
          <Textarea
            maxLength={255}
            mt={16}
            mb={16}
            label="Observações"
            placeholder="escreva aqui qualquer observação que queira fazer"
            name="financialNote"
            {...fromSetFinancialNote.getInputProps('financialNote')}
          />
          <Group position="right">
            <Button color="dark" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Modal>
      <ExportableModal
        title="Exportável de Pedidos"
        loading={orderListExportableLoader}
        opened={pageModalVisible === 'exportable-loading'}
        onClose={() => setPageModalVisible(null)}
      />
      <Modal
        size={500}
        opened={pageModalVisible === 'create-rework'}
        onClose={() => setPageModalVisible(null)}
        title="Criar Retrabalho"
      >
        <form
          onSubmit={reworkOrderForm.onSubmit((values) =>
            handleCreateReworkOrder(values.reworkUser),
          )}
        >
          <TextInput
            withAsterisk
            required
            label="Responsável"
            placeholder="nome do responável pelo retrabalho"
            mb={16}
            type="text"
            name="reworkUser"
            maxLength={255}
            {...reworkOrderForm.getInputProps('reworkUser')}
          />
          <Group position="right" mt="xl">
            <Button
              color="ltpBlue.9"
              onClick={() => {
                reworkOrderForm.setFieldValue('reworkUser', '');
                setPageModalVisible(null);
              }}
            >
              Cancelar
            </Button>
            <Button color="ltpBlue.5" type="submit">
              Criar
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        size={500}
        opened={pageModalVisible === 'change-order-values'}
        onClose={() => setPageModalVisible(null)}
        title="Alterar Valores"
      >
        <Grid gutter="xs" columns={2}>
          <Grid.Col mt={8} mb={8} span={2}>
            <Text color="gray">Info do pedido</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">OS</Text>
              <Text color="gray">{selectedOrder?.os ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Identificação interna</Text>
              <Text color="gray">{selectedOrder?.internalNumber ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Serviço</Text>
              <Text color="gray">{selectedOrder?.service.name ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Quantidade</Text>
              <Text color="gray">{selectedOrder?.quantity ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold">Produto</Text>
              <Text color="gray">
                {`${selectedOrder?.product}, ${selectedOrder?.productColor}, ${selectedOrder?.productMaterial}` ??
                  '--'}
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>
        <form
          onSubmit={formChangePrices.onSubmit((values) =>
            handleChangePrices(values),
          )}
        >
          <Group grow>
            <TextInput
              label="Preço Clichê"
              placeholder="$$$$"
              mb={16}
              type="text"
              name="clichePrice"
              value={formatBRL(formChangePrices.values.clichePrice ?? '')}
              onChange={(e) =>
                formChangePrices.setValues({
                  clichePrice: formatBRL(e.target.value),
                })
              }
            />
            <TextInput
              label="Preço Fotolito"
              placeholder="$$$$"
              mb={16}
              type="text"
              name="pholitoPrice"
              value={formatBRL(formChangePrices.values.pholitoPrice ?? '')}
              onChange={(e) =>
                formChangePrices.setValues({
                  pholitoPrice: formatBRL(e.target.value),
                })
              }
            />
          </Group>
          <Group grow>
            <TextInput
              label="Preço Manuseio"
              placeholder="$$$$"
              mb={16}
              type="text"
              name="manipulationPrice"
              value={formatBRL(formChangePrices.values.manipulationPrice ?? '')}
              onChange={(e) =>
                formChangePrices.setValues({
                  manipulationPrice: formatBRL(e.target.value),
                })
              }
            />
            <TextInput
              label="Preço Vetorização"
              placeholder="$$$$"
              mb={16}
              type="text"
              name="vectorizationPrice"
              value={formatBRL(
                formChangePrices.values.vectorizationPrice ?? '',
              )}
              onChange={(e) =>
                formChangePrices.setValues({
                  vectorizationPrice: formatBRL(e.target.value),
                })
              }
            />
          </Group>
          <Group grow>
            <TextInput
              label="Preço Amostra"
              placeholder="$$$$"
              mb={16}
              type="text"
              name="samplePrice"
              value={formatBRL(formChangePrices.values.samplePrice ?? '')}
              onChange={(e) =>
                formChangePrices.setValues({
                  samplePrice: formatBRL(e.target.value),
                })
              }
            />
            <TextInput
              label="Preço (total gravação)"
              placeholder="$$$$"
              mb={16}
              type="text"
              name="price"
              value={formatBRL(formChangePrices.values.price ?? '')}
              onChange={(e) =>
                formChangePrices.setFieldValue(
                  'price',
                  formatBRL(e.target.value),
                )
              }
            />
          </Group>
          <Group grow>
            <div>
              <Text>Preço unitário: </Text>
              <Text color="gray">{orderUnitPrice}</Text>
            </div>
            <div>
              <Text>Preço final: </Text>
              <Text color="gray">{orderTotalPrice}</Text>
            </div>
          </Group>
          <Group position="right">
            <Button color="dark" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Modal>
    </Page>
  );
}

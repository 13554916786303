/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Badge,
  Button,
  Flex,
  Select,
  TextInput,
  Menu,
  MultiSelect,
  Group,
  Text,
  Modal,
  Grid,
  Tooltip,
  ThemeIcon,
  Collapse,
  Paper,
  NumberInput,
} from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import {
  IconCashBanknote,
  IconCheck,
  IconDotsVertical,
  IconDownload,
  IconEdit,
  IconEye,
  IconFile,
  IconRefreshDot,
  IconStatusChange,
  IconTableExport,
  IconTestPipe2,
  IconX,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import { FormFilterType } from '..';
import { CustomDateTimePicker } from '../../../components/CustoDateTimePicker';

import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { CustomDateRangePicker } from '../../../components/CustomDateRangePicker';
import { CustomLoader } from '../../../components/CustomLoader';
import { useListOptions } from '../../../data/hooks/options';
import {
  useAddOrderProductionManually,
  useChangeManyOrderProduction,
} from '../../../data/hooks/order-production';
import { useChangeOrderStatus } from '../../../data/hooks/orders';
import {
  getFilterCompaniesListRequest,
  getFilterServicesListRequest,
  getFilterTeamsListRequest,
  getFilterUsersListRequest,
} from '../../../data/services/filters';
import { getOrderProductionRequest } from '../../../data/services/order-production';
import { OptionSubTypes, OptionTypes } from '../../../models/option';
import { Order, Order as OrderModel, OrderStatus } from '../../../models/order';
import {
  OrderProduction,
  ProductionType,
} from '../../../models/order-production';
import { TeamType } from '../../../models/team';
import { UserRole } from '../../../models/user';
import { dayjsPlugins, formatLocale } from '../../../providers/dayjs-plugins';
import { errorNotification } from '../../../providers/mantine-notifications';
import { RootState } from '../../../providers/store';
import {
  orderProductionTypeHumanized,
  orderStatusColor,
  orderStatusHumanized,
  statusList,
} from '../../../utils/constants';
import {
  financialStatusColor,
  financialStatusTypeHumanized,
  orderFinancialStatusList,
  orderPaymentTypeHumanized,
  orderPaymentTypeList,
} from '../../../utils/constants/order';
import { formatBRL, getOrderPrices } from '../../../utils/helpers';
import { PageMeta } from '../../../utils/types';
import OrderListPageFilter from '../sub-components/page-filter';
import {
  GetFilterCompaniesListResponse,
  GetFilterServicesListResponse,
  GetFilterTeamsListResponse,
  GetFilterUsersListResponse,
} from '../../../utils/types/data/services/filters';

type PageModalState =
  | 'change-order-values'
  | 'change-order-status'
  | 'change-productions'
  | null;

interface AdminHomeWebProps {
  items: OrderModel[];
  handleChangePageLimit(limit: number): void;
  navigation: NavigateFunction;
  handlePaginate(page: number): void;
  handleSubmit(values: FormFilterType): void;
  currentPage: React.MutableRefObject<number>;
  meta: PageMeta | null;
  formFilter: UseFormReturnType<any>;
  filtersCollapse: boolean;
  handleClear: () => void;
  setOrderFilesModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setOrderViewModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedOrder: React.Dispatch<
    React.SetStateAction<OrderModel | undefined>
  >;
  setFiltersCollapse: React.Dispatch<React.SetStateAction<boolean>>;
  setOrderToChangePrices(order: Order): void;
  requestOrderListExportable(): void;
  onInvoiceOrderListExport?: () => void;
}

export function AdminHomeWeb({
  handleChangePageLimit,
  currentPage,
  formFilter,
  handleClear,
  handlePaginate,
  items,
  meta,
  navigation,
  handleSubmit,
  setOrderFilesModalVisible,
  setOrderViewModalVisible,
  setSelectedOrder,
  filtersCollapse,
  setFiltersCollapse,
  setOrderToChangePrices,
  requestOrderListExportable,
  onInvoiceOrderListExport,
}: AdminHomeWebProps) {
  const { user } = useSelector((state: RootState) => state.auth);
  const [selectedProductionToEdit, setSelectedProdutionToEdit] =
    useState<OrderProduction>();
  const [teamList, setTeamList] = useState<GetFilterTeamsListResponse[]>([]);
  const [serviceList, setServiceList] = useState<
    GetFilterServicesListResponse[]
  >([]);
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const [selectedOrder, setSelectedOrder1] = useState<Order>();
  const [productionList, setProductionList] = useState<OrderProduction[]>([]);
  const [technicalUsers, setTechnicalUsers] = useState<
    GetFilterUsersListResponse[]
  >([]);
  const [pageModal, setPageModal] = useState<PageModalState>(null);
  const [addProductionCollapsed, setAddProductionCollapsed] = useState(false);
  const { fetch: changeOrderStatusFetcher, loading: changeOrderStatusLoader } =
    useChangeOrderStatus();
  const {
    fetch: changeManyOrderProductionFetcher,
    loading: changeManyOrderProductionLoader,
  } = useChangeManyOrderProduction();
  const {
    fetch: listStepOptionsFetcher,
    reponseData: listStepOptionsData,
    loading: listStepOptionsLoader,
  } = useListOptions();
  const {
    fetch: listMachineOptionsFetcher,
    reponseData: listMachineOptionsData,
    loading: listMachineOptionsLoader,
  } = useListOptions();
  const { fetch: addProductionFetcher, loading: addProductionLoader } =
    useAddOrderProductionManually();

  const formStatusChange = useForm({
    initialValues: {
      status: '',
    },
  });

  const formChangeProdution = useForm({
    initialValues: {
      technical: '',
      clockIn: new Date(),
      clockOut: new Date(),
      type: '',
      supervisor: '',
      machine: '',
      amount: '',
    },
  });

  const formAddProduction = useForm({
    initialValues: {
      technical: '',
      clockIn: new Date(),
      clockOut: new Date(),
      type: '',
      supervisor: '',
      machine: '',
      amount: '',
    },
  });

  function handleViewOrderFiles(order: OrderModel) {
    setSelectedOrder(order);
    setOrderFilesModalVisible(true);
  }

  function handleChangeOrderStatus(order: Order) {
    setSelectedOrder1(order);
    setPageModal('change-order-status');
  }

  function handleSelectOrderProduction(order: Order) {
    setSelectedOrder1(order);
    getOrderProductions(order.id);
    setPageModal('change-productions');
  }

  function handleSetProductionToEdit(orderProduction: OrderProduction) {
    setSelectedProdutionToEdit(orderProduction);
    formChangeProdution.setValues({
      amount: String(orderProduction.amount ?? ''),
      clockIn: new Date(orderProduction.clockIn),
      clockOut: orderProduction?.clockOut
        ? new Date(orderProduction?.clockOut)
        : new Date(),
      type: orderProduction.type,
      technical: `${orderProduction.technical.id}`,
    });
  }

  async function handleSubmitChangeStatusAsMaster(
    values: typeof formStatusChange.values,
  ) {
    if (selectedOrder) {
      await changeOrderStatusFetcher({
        id: selectedOrder.id,
        data: {
          status: values.status as OrderStatus,
        },
        onSuccess: () => {
          setSelectedOrder(undefined);
          formStatusChange.reset();
          setPageModal(null);
          handleSubmit(formFilter.values);
        },
      });
    }
  }

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  async function getServices() {
    try {
      const response = await getFilterServicesListRequest();
      setServiceList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar serviços.',
        message: 'tente novamente',
      });
    }
  }

  async function getVendorTeams() {
    try {
      const response = await getFilterTeamsListRequest({
        type: TeamType.VENDOR,
      });
      setTeamList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar equipes.',
        message: 'tente novamente',
      });
    }
  }

  async function getOrderProductions(orderId: number) {
    try {
      const response = await getOrderProductionRequest(orderId);

      setProductionList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar produção.',
        message: 'tente novamente',
      });
    }
  }

  async function handleSubmitChangeOrderProduction(
    values: typeof formChangeProdution.values,
  ) {
    if (selectedProductionToEdit) {
      await changeManyOrderProductionFetcher({
        id: selectedProductionToEdit?.id,
        data: {
          clockIn: values.clockIn.toISOString(),
          clockOut: values.clockOut.toISOString(),
          technical: Number(values.technical),
          type: values.type as ProductionType,
          amount: Number(values.amount),
          supervisor: values.supervisor,
        },
        onSuccess: () => {
          setSelectedProdutionToEdit(undefined);
          selectedOrder && getOrderProductions(selectedOrder?.id);
        },
      });
    }
  }

  function handleViewOrder(order?: Order) {
    setSelectedOrder(order);
    setOrderViewModalVisible(true);
  }

  function addNewProduction() {
    setAddProductionCollapsed(true);
  }

  async function getTechnicalUsers() {
    try {
      const responseUsers = await getFilterUsersListRequest({
        role: UserRole.TECHNICAL,
      });
      setTechnicalUsers(responseUsers);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscas usuários',
        message: 'tente novamente',
      });
    }
  }

  async function getStepOptions() {
    await listStepOptionsFetcher({
      query: {
        type: OptionTypes.PRODUCTION,
        subtype: OptionSubTypes.STEPS,
      },
    });
  }

  async function getMachineOptions() {
    await listMachineOptionsFetcher({
      query: {
        type: OptionTypes.PRODUCTION,
        subtype: OptionSubTypes.PRODUCTION_MACHINE,
      },
    });
  }

  async function handleAdd(values: typeof formAddProduction.values) {
    if (selectedOrder) {
      await addProductionFetcher({
        orderId: selectedOrder?.id,
        data: {
          ...values,
          technical: Number(values.technical),
          type: values.type as ProductionType,
          amount: Number(values.amount),
        },
        onSuccess: () => {
          getOrderProductions(selectedOrder?.id);
          formAddProduction.reset();
          setAddProductionCollapsed(false);
        },
      });
    }
  }

  useEffect(() => {
    getServices();
    getVendorTeams();
    getCompanies();
    getTechnicalUsers();
    getStepOptions();
    getMachineOptions();
  }, []);

  if (!companyList) return null;

  return (
    <>
      <CustomLoader
        loading={
          changeOrderStatusLoader ||
          changeManyOrderProductionLoader ||
          listStepOptionsLoader ||
          listMachineOptionsLoader ||
          addProductionLoader
        }
      />
      <Flex direction="column" w="99%">
        <Group mb={16} position="right">
          <OrderListPageFilter.Button
            isCollapsed={filtersCollapse}
            onClick={() => setFiltersCollapse(!filtersCollapse)}
          />
          <Menu
            disabled={
              formFilter.values.createdAt === undefined &&
              formFilter.values.issueDate === undefined
            }
            shadow="md"
            width={200}
          >
            <Menu.Target>
              <Button
                variant="subtle"
                color="orange"
                mr={8}
                disabled={
                  formFilter.values.createdAt === undefined &&
                  formFilter.values.issueDate === undefined
                }
                rightIcon={<IconTableExport size={18} />}
              >
                Exportaveis
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Tipos</Menu.Label>
              <Menu.Item
                onClick={onInvoiceOrderListExport}
                icon={<IconDownload size={14} />}
              >
                Exportavel Financeiro
              </Menu.Item>
              <Menu.Item
                onClick={requestOrderListExportable}
                icon={<IconDownload size={14} />}
              >
                Exportavel de Pedidos
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
        <OrderListPageFilter
          companyList={companyList}
          formFilter={formFilter}
          handleClear={handleClear}
          handleSubmit={handleSubmit}
          isCollapsed={filtersCollapse}
          serviceList={serviceList}
          showReworkFilter
          customInputs={() => (
            <>
              <TextInput
                label="Identificador"
                placeholder="código interno do pedido"
                mb={16}
                mr={8}
                type="text"
                name="internalNumber"
                {...formFilter.getInputProps('internalNumber')}
              />
              <TextInput
                label="OS"
                placeholder="numero da OS"
                mb={16}
                mr={8}
                type="text"
                name="os"
                {...formFilter.getInputProps('os')}
              />
              <TextInput
                label="Produto"
                placeholder="nome do produto"
                mb={16}
                mr={8}
                type="text"
                name="os"
                {...formFilter.getInputProps('product')}
              />
              <Select
                required
                name="paymentType"
                withAsterisk
                label="Forma de pagamento"
                placeholder="selecione uma forma de pagamento"
                data={orderPaymentTypeList}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('paymentType')}
              />
              <MultiSelect
                clearable
                searchable
                name="company"
                label="Clientes"
                placeholder="selecione o cliente"
                data={companyList?.map((item) => ({
                  label: item.name,
                  value: String(item.id),
                }))}
                mb={16}
                mr={8}
                defaultValue={
                  formFilter.values.company &&
                  formFilter.values.company.split(',')
                }
                onChange={(values) => {
                  formFilter.setFieldValue('company', values.toString());
                }}
              />
              <Select
                searchable
                clearable
                name="service"
                label="Serviço"
                placeholder="selecione um serviço"
                defaultValue={serviceList[formFilter.values.service]?.id}
                data={serviceList?.map((item) => ({
                  label: item.name,
                  value: String(item.id),
                }))}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('service')}
              />
              <Select
                searchable
                clearable
                name="team"
                label="Equipes"
                placeholder="selecione uma equipe"
                defaultValue={
                  formFilter.values.team && teamList[formFilter.values.team]?.id
                }
                data={teamList?.map((item) => ({
                  label: item.name,
                  value: String(item.id),
                }))}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('team')}
              />
              <CustomDatePicker
                placeholder="selecione uma data"
                label="Data de Retirada"
                lang="br"
                name="receiptForecast"
                mb={16}
                mr={8}
                defaultValue={
                  formFilter.values.receiptForecast &&
                  new Date(
                    dayjsPlugins(formFilter.values.receiptForecast).toDate(),
                  )
                }
                onChange={(value) => {
                  value
                    ? formFilter.setFieldValue(
                        'receiptForecast',
                        dayjsPlugins(value).format('YYYY-MM-DD'),
                      )
                    : formFilter.setFieldValue('receiptForecast', '');
                }}
              />
              <CustomDatePicker
                placeholder="selecione uma data"
                label="Prev. de Finalização"
                name="finishForecast"
                mb={16}
                mr={8}
                defaultValue={
                  formFilter.values.finishForecast &&
                  new Date(
                    dayjsPlugins(formFilter.values.finishForecast).toDate(),
                  )
                }
                onChange={(value) => {
                  value
                    ? formFilter.setFieldValue(
                        'finishForecast',
                        dayjsPlugins(value).format('YYYY-MM-DD'),
                      )
                    : formFilter.setFieldValue('finishForecast', '');
                }}
              />
              <CustomDateRangePicker
                allowSingleDateInRange
                label="Criado em"
                placeholder="selecione um intervalo"
                mr={8}
                defaultValue={
                  formFilter.values.createdAt && [
                    new Date(
                      dayjsPlugins(
                        formFilter.values.createdAt.split(',')[0],
                      ).toDate(),
                    ),
                    new Date(
                      dayjsPlugins(
                        formFilter.values.createdAt.split(',')[1],
                      ).toDate(),
                    ),
                  ]
                }
                onChange={(value) => {
                  value[0] && value[1]
                    ? formFilter.setFieldValue(
                        'createdAt',
                        `${dayjsPlugins(value[0]).format(
                          'YYYY-MM-DD',
                        )},${dayjsPlugins(value[1]).format('YYYY-MM-DD')}`,
                      )
                    : formFilter.setFieldValue('createdAt', undefined);
                }}
              />
              <CustomDateRangePicker
                allowSingleDateInRange
                label="Faturado em"
                placeholder="selecione um intervalo"
                mr={8}
                defaultValue={
                  formFilter.values.issueDate && [
                    new Date(
                      dayjsPlugins(
                        formFilter.values.issueDate.split(',')[0],
                      ).toDate(),
                    ),
                    new Date(
                      dayjsPlugins(
                        formFilter.values.issueDate.split(',')[1],
                      ).toDate(),
                    ),
                  ]
                }
                onChange={(value) => {
                  value[0] && value[1]
                    ? formFilter.setFieldValue(
                        'issueDate',
                        `${dayjsPlugins(value[0]).format(
                          'YYYY-MM-DD',
                        )},${dayjsPlugins(value[1]).format('YYYY-MM-DD')}`,
                      )
                    : formFilter.setFieldValue('issueDate', undefined);
                }}
              />
              <MultiSelect
                maw={240}
                clearable
                searchable
                name="status"
                label="Status"
                placeholder="selecione o status do pedido"
                data={statusList}
                mb={16}
                mr={8}
                defaultValue={
                  formFilter.values.status &&
                  formFilter.values.status.split(',')
                }
                onChange={(values) => {
                  formFilter.setFieldValue('status', values.toString());
                }}
              />
              <MultiSelect
                clearable
                name="financialStatus"
                label="Status Fiscal"
                placeholder="selecione o status de fiscal"
                data={orderFinancialStatusList}
                mb={16}
                mr={8}
                defaultValue={
                  formFilter.values.financialStatus &&
                  formFilter.values.financialStatus.split(',')
                }
                onChange={(values) => {
                  formFilter.setFieldValue(
                    'financialStatus',
                    values.toString(),
                  );
                }}
              />
            </>
          )}
        />
        <DataTable
          recordsPerPageLabel="Itens por página"
          recordsPerPageOptions={[10, 50, 100, 300]}
          onRecordsPerPageChange={(recordRange) =>
            handleChangePageLimit(recordRange)
          }
          height="75vh"
          noRecordsText="Sem pedidos"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          page={currentPage.current}
          totalRecords={meta?.totalItems}
          recordsPerPage={meta?.itemsPerPage ?? 10}
          onPageChange={handlePaginate}
          records={items}
          rowStyle={(record) =>
            record.rework
              ? { backgroundColor: 'rgba(255, 17, 0, 0.027)' }
              : record.isSample
              ? { backgroundColor: 'rgba(0, 255, 0, 0.027)' }
              : {}
          }
          columns={[
            {
              accessor: 'types',
              title: '',
              render: ({ rework, isSample }) =>
                rework ? (
                  <Tooltip color="grape" label="retrabalho">
                    <ThemeIcon color="grape" variant="light">
                      <IconRefreshDot size={24} />
                    </ThemeIcon>
                  </Tooltip>
                ) : isSample ? (
                  <Tooltip color="green" label="amostra">
                    <ThemeIcon color="green" variant="light">
                      <IconTestPipe2 size={24} />
                    </ThemeIcon>
                  </Tooltip>
                ) : (
                  ''
                ),
            },
            {
              accessor: 'company.name',
              title: 'Cliente',
            },
            {
              accessor: 'company.team.name',
              title: 'Equipe',
            },
            {
              accessor: 'internalNumber',
              title: 'Código',
            },
            {
              accessor: 'os',
              title: 'OS',
            },
            {
              accessor: 'status',
              title: 'Status',
              render: ({ status }) => (
                <Badge color={orderStatusColor[status]} mb={6}>
                  {orderStatusHumanized[status]}
                </Badge>
              ),
            },
            {
              accessor: 'financialStatus',
              title: 'Status Fiscal',
              render: ({ financialStatus }) =>
                financialStatus && (
                  <Badge color={financialStatusColor[financialStatus]} mb={6}>
                    {financialStatusTypeHumanized[financialStatus]}
                  </Badge>
                ),
            },
            {
              accessor: 'issueDate',
              title: 'Faturado em',
              render: ({ issueDate }) =>
                issueDate && formatLocale(issueDate, 'DD/MM/YY HH:mm'),
            },
            {
              accessor: 'service.name',
              title: 'Serviço',
            },
            {
              accessor: 'engravingText',
              title: 'Logotipo',
            },
            {
              accessor: 'quantity',
              title: 'Qtde',
              width: 60,
            },
            {
              accessor: 'product',
              title: 'Produto',
            },
            {
              accessor: 'needsPostal',
              title: 'Correio',
              render: ({ needsPostal }) => (
                <Badge color={needsPostal ? 'green' : 'red'} mb={6}>
                  {needsPostal ? 'sim' : 'não'}
                </Badge>
              ),
            },
            {
              accessor: 'receiptedAt',
              title: 'Recebido em',
              render: ({ receiptedAt }) =>
                receiptedAt && formatLocale(receiptedAt, 'DD/MM/YY HH:mm'),
              width: 110,
            },
            {
              accessor: 'finishForecast',
              title: 'Prev. Finalização',
              render: ({ finishForecast }) =>
                finishForecast &&
                formatLocale(finishForecast, 'DD/MM/YY HH:mm'),
              width: 110,
            },
            {
              accessor: 'createdAt',
              title: 'Criado em',
              render: ({ createdAt }) =>
                createdAt && formatLocale(createdAt, 'DD/MM/YY'),
              width: 110,
            },
            {
              accessor: 'fileLink',
              title: 'Arquivos',
              render: (order) => (
                <Button
                  color="blue"
                  variant="subtle"
                  onClick={() => handleViewOrderFiles(order)}
                  w={40}
                  p={0}
                >
                  <IconFile />
                </Button>
              ),
              width: 110,
            },
            {
              accessor: 'supplier',
              title: 'Fornecedor',
            },
            {
              accessor: 'carrierName',
              title: 'Portador',
            },
            {
              accessor: 'paymentType',
              title: 'Forma de Pagamento',
              render: ({ paymentType }) =>
                paymentType && orderPaymentTypeHumanized[paymentType],
              width: 110,
            },
            {
              accessor: 'financialNote',
              title: 'Obs Fiscal',
              width: 220,
            },
            {
              accessor: 'totalprice',
              title: 'Preço total',
              render: (orderItem) => `${getOrderPrices(orderItem).totalPrice}`,
              width: 110,
            },
            {
              accessor: 'admin-table-menu',
              title: '',
              render: (orderItem) => (
                <Menu>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      onClick={() => handleViewOrder(orderItem)}
                      icon={<IconEye size={14} />}
                    >
                      Ver
                    </Menu.Item>
                    {user?.isMain && (
                      <>
                        <Menu.Item
                          onClick={() => setOrderToChangePrices(orderItem)}
                          icon={<IconCashBanknote size={14} />}
                        >
                          Alterar valores
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => handleChangeOrderStatus(orderItem)}
                          icon={<IconStatusChange size={14} />}
                        >
                          Alterar status
                        </Menu.Item>
                        <Menu.Item
                          onClick={() =>
                            navigation(`/order/edit/${orderItem.id}`)
                          }
                          icon={<IconEdit size={14} />}
                        >
                          Editar
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => handleSelectOrderProduction(orderItem)}
                          icon={<IconEdit size={14} />}
                        >
                          Editar Produção
                        </Menu.Item>
                      </>
                    )}
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />
        <Group position="right">
          <Text>
            total: {formatBRL(Number(meta?.custom?.totalPrice).toFixed(2))}
          </Text>
        </Group>
      </Flex>
      <Modal
        opened={pageModal === 'change-order-status'}
        onClose={() => setPageModal(null)}
        title="Alterar status do pedido"
      >
        <form
          onSubmit={formStatusChange.onSubmit((values) =>
            handleSubmitChangeStatusAsMaster(values),
          )}
        >
          <Grid gutter="xs" columns={2}>
            <Grid.Col span={2}>
              <Select
                required
                withAsterisk
                name="status"
                label="Status"
                placeholder="selecione o novo status do pedido"
                data={statusList}
                mb={16}
                {...formStatusChange.getInputProps('status')}
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end">
            <Button color="ltpBlue.9" type="submit">
              Salvar
            </Button>
          </Flex>
        </form>
      </Modal>
      <Modal
        size={'80%'}
        opened={pageModal === 'change-productions'}
        onClose={() => setPageModal(null)}
        title="Alterar produção"
      >
        <Grid gutter="xs" columns={2}>
          <Grid.Col mt={8} mb={8} span={2}>
            <Text color="gray">Info do pedido</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">OS</Text>
              <Text color="gray">{selectedOrder?.os ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Identificação interna</Text>
              <Text color="gray">{selectedOrder?.internalNumber ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Serviço</Text>
              <Text color="gray">{selectedOrder?.service.name ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Quantidade</Text>
              <Text color="gray">{selectedOrder?.quantity ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold">Produto</Text>
              <Text color="gray">
                {`${selectedOrder?.product}, ${selectedOrder?.productColor}, ${selectedOrder?.productMaterial}` ??
                  '--'}
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>
        <Group position="right" mb={8}>
          <Button
            disabled={addProductionCollapsed}
            onClick={() => addNewProduction()}
          >
            Adicionar
          </Button>
        </Group>
        <Collapse in={addProductionCollapsed}>
          <form
            onSubmit={formAddProduction.onSubmit((values) => handleAdd(values))}
          >
            <Paper mb={16} mt={16} bg="gray.1" withBorder p={8}>
              <Grid columns={5}>
                <Grid.Col span={1}>
                  <Select
                    clearable
                    searchable
                    label="Operador"
                    placeholder="selecione o operador"
                    data={technicalUsers.map((item) => ({
                      label: item.name,
                      value: `${item.name}`,
                    }))}
                    mb={16}
                    mr={8}
                    onChange={(value) =>
                      formAddProduction.setFieldValue(
                        'supervisor',
                        String(value),
                      )
                    }
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <Select
                    clearable
                    searchable
                    label="Técnico"
                    placeholder="selecione o técnico"
                    data={technicalUsers.map((item) => ({
                      label: item.name,
                      value: `${item.id}`,
                    }))}
                    mb={16}
                    mr={8}
                    {...formAddProduction.getInputProps('technical')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <Select
                    searchable
                    required
                    withAsterisk
                    name="machine"
                    label="Máquina"
                    placeholder="selecione a máquina"
                    data={
                      listMachineOptionsData?.map((option) => ({
                        label: option.name,
                        value: option.value,
                      })) ?? []
                    }
                    mb={16}
                    {...formAddProduction.getInputProps('machine')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <Select
                    required
                    withAsterisk
                    label="Etapa"
                    placeholder="selecione a etapa"
                    data={
                      listStepOptionsData?.map((option) => ({
                        label: option.name,
                        value: option.value,
                      })) ?? []
                    }
                    mb={16}
                    {...formAddProduction.getInputProps('type')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <NumberInput
                    w={90}
                    label="Qtde"
                    {...formAddProduction.getInputProps('amount')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <CustomDateTimePicker
                    dropdownType="modal"
                    date={new Date()}
                    label="Inicio"
                    onChange={(date) =>
                      formAddProduction.setFieldValue('clockIn', date)
                    }
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <CustomDateTimePicker
                    dropdownType="modal"
                    date={formAddProduction.values.clockOut}
                    label="Fim"
                    onChange={(date) =>
                      formAddProduction.setFieldValue('clockOut', date)
                    }
                  />
                </Grid.Col>
              </Grid>
              <Group position="right" mb={8}>
                <Button
                  type="button"
                  onClick={() => setAddProductionCollapsed(false)}
                  color="red"
                >
                  <IconX />
                </Button>
                <Button type="submit" color="green">
                  <IconCheck />
                </Button>
              </Group>
            </Paper>
          </form>
        </Collapse>
        <form
          onSubmit={formChangeProdution.onSubmit((values) =>
            handleSubmitChangeOrderProduction(values),
          )}
        >
          <DataTable
            height={350}
            noRecordsText="Sem produção"
            withBorder
            borderRadius="sm"
            striped
            highlightOnHover
            page={1}
            totalRecords={meta?.totalItems}
            recordsPerPage={meta?.itemsPerPage ?? 10}
            onPageChange={handlePaginate}
            records={productionList}
            columns={[
              {
                accessor: 'supervisor',
                title: 'Operador',
                render: (orderProduction) =>
                  orderProduction.id === selectedProductionToEdit?.id ? (
                    <Select
                      clearable
                      searchable
                      label="Operador"
                      placeholder="selecione o operador"
                      defaultValue={orderProduction.supervisor}
                      data={technicalUsers.map((item) => ({
                        label: item.name,
                        value: `${item.name}`,
                      }))}
                      mb={16}
                      mr={8}
                      onChange={(value) =>
                        formChangeProdution.setFieldValue(
                          'supervisor',
                          String(value),
                        )
                      }
                    />
                  ) : (
                    orderProduction.supervisor
                  ),
                width: 160,
              },
              {
                accessor: 'technical.username',
                title: 'Técnico',
                render: (orderProduction) =>
                  orderProduction.id === selectedProductionToEdit?.id ? (
                    <Select
                      clearable
                      searchable
                      label="Técnico"
                      placeholder="selecione o técnico"
                      data={technicalUsers.map((item) => ({
                        label: item.name,
                        value: `${item.id}`,
                      }))}
                      mb={16}
                      mr={8}
                      {...formChangeProdution.getInputProps('technical')}
                    />
                  ) : (
                    orderProduction.technical.username
                  ),
                width: 160,
              },
              {
                accessor: 'type',
                title: 'Tipo',
                render: (orderProduction) =>
                  orderProduction.id === selectedProductionToEdit?.id ? (
                    <Select
                      w={170}
                      required
                      withAsterisk
                      label="Etapa"
                      placeholder="selecione a etapa"
                      data={
                        listStepOptionsData?.map((option) => ({
                          label: option.name,
                          value: option.value,
                        })) ?? []
                      }
                      mb={16}
                      mr={8}
                      {...formChangeProdution.getInputProps('type')}
                    />
                  ) : (
                    orderProductionTypeHumanized[orderProduction.type]
                  ),
                width: 170,
              },
              {
                accessor: 'clockIn',
                title: 'Inicio',
                render: (orderProduction) =>
                  orderProduction.id === selectedProductionToEdit?.id ? (
                    <CustomDateTimePicker
                      dropdownType="modal"
                      mt={21}
                      date={new Date(orderProduction.clockIn ?? 'now')}
                      label=""
                      onChange={(date) =>
                        formChangeProdution.setFieldValue('clockIn', date)
                      }
                    />
                  ) : (
                    formatLocale(orderProduction.clockIn, 'DD/MM/YY HH:mm')
                  ),
              },
              {
                accessor: 'clockOut',
                title: 'Fim',
                render: (orderProduction) =>
                  orderProduction.id === selectedProductionToEdit?.id ? (
                    <CustomDateTimePicker
                      dropdownType="modal"
                      mt={21}
                      date={formChangeProdution.values.clockOut}
                      label=""
                      onChange={(date) =>
                        formChangeProdution.setFieldValue('clockOut', date)
                      }
                    />
                  ) : (
                    orderProduction.clockOut &&
                    formatLocale(orderProduction.clockOut, 'DD/MM/YY HH:mm')
                  ),
              },
              {
                accessor: 'quantity',
                title: 'Quantidade',
                render: (orderProduction) =>
                  orderProduction.id === selectedProductionToEdit?.id ? (
                    <TextInput
                      {...formChangeProdution.getInputProps('amount')}
                    />
                  ) : (
                    orderProduction.amount
                  ),
                width: 110,
              },
              {
                accessor: 'admin-table-menu',
                title: '',
                render: (orderItem) => (
                  <Menu>
                    <Menu.Target>
                      <Button color="blue" variant="subtle" w={40} p={0}>
                        <IconDotsVertical />
                      </Button>
                    </Menu.Target>
                    <Menu.Dropdown style={{ position: 'absolute' }}>
                      <Menu.Item
                        onClick={() => handleSetProductionToEdit(orderItem)}
                        icon={<IconEdit size={14} />}
                      >
                        Editar
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
              {
                accessor: 'admin-table-confirm',
                title: '',
                render: ({ id }) => {
                  if (
                    selectedProductionToEdit &&
                    id === selectedProductionToEdit.id
                  ) {
                    return (
                      <Button color="green" type="submit" m={0} p={0} w={30}>
                        <IconCheck />
                      </Button>
                    );
                  }
                },
              },
            ]}
          />
        </form>
      </Modal>
    </>
  );
}

import { AxiosResponse } from 'axios';
import { Tag } from '../../models/tag';
import { api } from '../../providers/base-api';
import {
  CreateTagRequestData,
  ListTagRequestQuery,
  UpdateTagRequestData,
} from '../../utils/types/data/services/tags';

export async function createTagRequest(
  data: CreateTagRequestData,
): Promise<AxiosResponse<Tag>> {
  const response = await api.post<Tag>(`tag`, data);

  return response;
}

export async function updateTagRequest(
  id: number,
  data: UpdateTagRequestData,
): Promise<AxiosResponse<Tag>> {
  const response = await api.put<Tag>(`tag/${id}`, data);

  return response;
}

export async function showTagRequest(id: number): Promise<AxiosResponse<Tag>> {
  const response = await api.get<Tag>(`tag/${id}`);

  return response;
}

export async function listTagRequest(
  query?: ListTagRequestQuery,
): Promise<AxiosResponse<Tag[]>> {
  const response = await api.get<Tag[]>(`tag`, { params: query });

  return response;
}

export async function deleteTagRequest(
  id: number,
): Promise<AxiosResponse<void>> {
  const response = await api.delete<void>(`tag/${id}`);

  return response;
}

export async function recoverTagRequest(
  id: number,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`tag/recover/${id}`);

  return response;
}

import { Button, Collapse, Flex, Group, Paper, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconFilter, IconFilterOff } from '@tabler/icons';
import { ReactNode } from 'react';
import { FormFilterType } from '..';
import { DynamicSelectInput } from './dynamic-select-input';

type ProductEntryListPageFilterProps = {
  isCollapsed: boolean;
  formFilter: UseFormReturnType<
    FormFilterType,
    (values: FormFilterType) => FormFilterType
  >;
  customInputs?: () => ReactNode;
  handleSubmit(values: FormFilterType): void;
  handleClear: () => void;
};

type ProductEntryListFilterButtonProps = {
  onClick: () => void;
  isCollapsed: boolean;
};

const FilterButton = ({
  isCollapsed,
  ...props
}: ProductEntryListFilterButtonProps) => {
  return (
    <Button
      variant="light"
      color="blue"
      leftIcon={isCollapsed ? <IconFilterOff /> : <IconFilter />}
      {...props}
    >
      Filtros
    </Button>
  );
};

const ProductEntryListPageFilter = ({
  isCollapsed,
  formFilter,
  handleSubmit,
  handleClear,
  customInputs,
}: ProductEntryListPageFilterProps) => {
  return (
    <Collapse in={isCollapsed}>
      <form onSubmit={formFilter.onSubmit((values) => handleSubmit(values))}>
        <Paper p={16} mb={16} withBorder>
          <Flex wrap="wrap">
            {customInputs ? (
              customInputs()
            ) : (
              <>
                <TextInput
                  label="Cliente"
                  placeholder="nome do cliente"
                  mb={16}
                  mr={8}
                  type="text"
                  name="client"
                  {...formFilter.getInputProps('client')}
                />
                <TextInput
                  label="Código"
                  placeholder="código interno da entrada"
                  mb={16}
                  mr={8}
                  type="text"
                  name="code"
                  {...formFilter.getInputProps('code')}
                />
                <DynamicSelectInput
                  formFilter={formFilter}
                  label="Volume"
                  placeholder="tipo de volume"
                  target="volumeName"
                />
                <DynamicSelectInput
                  formFilter={formFilter}
                  label="Andamento"
                  placeholder="status da entrada"
                  target="status"
                />
                <DynamicSelectInput
                  formFilter={formFilter}
                  label="Tipo de Entrega"
                  placeholder="tipo de entrega"
                  target="type"
                />
                <DynamicSelectInput
                  formFilter={formFilter}
                  label="Recebimento"
                  placeholder="quem recebeu?"
                  target="receivedBy"
                />
                <DynamicSelectInput
                  formFilter={formFilter}
                  label="Equipe"
                  placeholder="equipe responsável"
                  target="team"
                />
              </>
            )}
          </Flex>
          <Group>
            <Button
              mt={25}
              color="ltpBlue.9"
              type="button"
              variant="outline"
              onClick={handleClear}
            >
              Limpar
            </Button>
            <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
              Filtrar
            </Button>
          </Group>
        </Paper>
      </form>
    </Collapse>
  );
};

ProductEntryListPageFilter.Button = FilterButton;

export default ProductEntryListPageFilter;

import { useFetch } from '../../../hooks/use-fetch';
import {
  ProductEntry,
  ProductEntryResponse,
} from '../../../models/product-entry';
import { errorNotification } from '../../../providers/mantine-notifications';
import { PaginatedResponse } from '../../../utils/types';
import {
  UseCreateProductEntryProps,
  UseListProductEntryProps,
  UseUpdateProductEntryProps,
} from '../../../utils/types/data/hooks/product-entries';
import {
  createProductEntryRequest,
  listProductEntryRequest,
  showProductEntryRequest,
  updateProductEntryRequest,
} from '../../services/product-entries';

export function useCreateProductEntry() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ProductEntry>();

  const fetch = ({ data, onSuccess }: UseCreateProductEntryProps) =>
    fetchData({
      fetcher: () => createProductEntryRequest(data),
      onError: () => {
        errorNotification({
          title: 'Erro ao criar entrada de produto.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useUpdateProductEntry() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ProductEntry>();

  const fetch = ({ id, data, onSuccess }: UseUpdateProductEntryProps) =>
    fetchData({
      fetcher: () => updateProductEntryRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao atualizar entrada de produto.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useShowProductEntry() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ProductEntry>();

  const fetch = ({ id }: { id: number }) =>
    fetchData({
      fetcher: () => showProductEntryRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar entrada de produto.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useListProductEntry() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<PaginatedResponse<ProductEntryResponse>>();

  const fetch = ({ query }: UseListProductEntryProps) =>
    fetchData({
      fetcher: () => listProductEntryRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar entrada de produtos.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

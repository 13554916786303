import {
  Badge,
  Button,
  Paper,
  Flex,
  Select,
  TextInput,
  Menu,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconCalendarStats, IconDotsVertical, IconFile } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { PageModalState } from '..';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { getFilterCompaniesListRequest } from '../../../data/services/filters';
import { Order, Order as OrderModel } from '../../../models/order';
import { formatLocale } from '../../../providers/dayjs-plugins';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  orderStatusColor,
  orderStatusHumanized,
} from '../../../utils/constants';
import { PageMeta } from '../../../utils/types';
import { GetFilterCompaniesListResponse } from '../../../utils/types/data/services/filters';

interface TechnicalHomeWebProps {
  items: OrderModel[];
  navigation: NavigateFunction;
  handlePaginate(page: number): void;
  handleSubmit(values: {
    status: null;
    finishForecast: null;
    receiptForecast: null;
    dispatchedAt: null;
    limit: number;
  }): void;
  currentPage: React.MutableRefObject<number>;
  meta: PageMeta | null;
  formFilter: UseFormReturnType<any>;
  formDispatchChange: UseFormReturnType<any>;
  handleClear: () => void;
  setSelectedOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;
  setPageModalVisible: React.Dispatch<React.SetStateAction<PageModalState>>;
}

export function TechnicalHomeWeb({
  items,
  formFilter,
  currentPage,
  handleClear,
  handleSubmit,
  handlePaginate,
  meta,
  navigation,
  setPageModalVisible,
  setSelectedOrder,
}: TechnicalHomeWebProps) {
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  useEffect(() => {
    getCompanies();
  }, []);

  function handleViewOrderFiles(order: Order) {
    setSelectedOrder(order);
    setPageModalVisible('order-files');
  }

  if (!companyList) return null;

  return (
    <Flex direction="column" w="99%">
      <form onSubmit={formFilter.onSubmit((values) => handleSubmit(values))}>
        <Paper p={16} mb={16} mt={16} withBorder>
          <Flex>
            <TextInput
              label="Identificador"
              placeholder="código interno do pedido"
              mb={16}
              mr={8}
              type="text"
              name="internalNumber"
              {...formFilter.getInputProps('internalNumber')}
            />
            <TextInput
              label="OS"
              placeholder="numero da OS"
              mb={16}
              mr={8}
              type="text"
              name="os"
              {...formFilter.getInputProps('os')}
            />
            <Select
              searchable
              name="company"
              label="Clientes"
              placeholder="selecione o cliente"
              data={companyList?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('company')}
            />
            <CustomDatePicker
              placeholder="selecione uma data"
              label="Data de Retirada"
              lang="br"
              name="receiptForecast"
              mb={16}
              mr={8}
              {...formFilter.getInputProps('receiptForecast')}
            />
            <CustomDatePicker
              placeholder="selecione uma data"
              label="Prev. de Finalização"
              name="finishForecast"
              mb={16}
              mr={8}
              {...formFilter.getInputProps('finishForecast')}
            />
          </Flex>
          <Button
            mt={25}
            color="ltpBlue.9"
            type="button"
            variant="outline"
            onClick={handleClear}
          >
            Limpar
          </Button>
          <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
            Filtrar
          </Button>
        </Paper>
      </form>
      <DataTable
        minHeight={500}
        noRecordsText="Sem pedidos"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        page={currentPage.current}
        totalRecords={meta?.totalItems}
        recordsPerPage={10}
        onPageChange={handlePaginate}
        records={items}
        columns={[
          {
            accessor: 'company.name',
            title: 'Cliente',
          },
          {
            accessor: 'company.team.name',
            title: 'Equipe',
          },
          {
            accessor: 'internalNumber',
            title: 'Identificador',
          },
          {
            accessor: 'os',
            title: 'OS',
          },
          {
            accessor: 'status',
            title: 'Status',
            render: ({ status }) => (
              <Badge color={orderStatusColor[status]} mb={6}>
                {orderStatusHumanized[status]}
              </Badge>
            ),
          },
          {
            accessor: 'service.name',
            title: 'Serviço',
          },
          {
            accessor: 'engravingText',
            title: 'Logotipo',
          },
          {
            accessor: 'quantity',
            title: 'Qtde',
            width: 60,
          },
          {
            accessor: 'product',
            title: 'Produto',
          },
          {
            accessor: 'receiptedAt',
            title: 'Recebido em',
            render: ({ receiptedAt }) =>
              receiptedAt && formatLocale(receiptedAt, 'DD/MM/YY HH:mm'),
            width: 110,
          },
          {
            accessor: 'finishForecast',
            title: 'Prev. Finalização',
            render: ({ finishForecast }) =>
              finishForecast && formatLocale(finishForecast, 'DD/MM/YY HH:mm'),
            width: 110,
          },
          {
            accessor: 'fileLink',
            title: 'Arquivos',
            render: (order) => (
              <Button
                color="blue"
                variant="subtle"
                onClick={() => handleViewOrderFiles(order)}
                w={40}
                p={0}
              >
                <IconFile />
              </Button>
            ),
            width: 110,
          },
          {
            accessor: 'carrierName',
            title: 'Portador',
          },
          {
            accessor: 'technical-history-table-menu',
            title: '',
            render: (orderItem) => (
              <Menu>
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ position: 'absolute' }}>
                  <Menu.Item
                    onClick={() =>
                      navigation(`/order-production/${orderItem.id}`)
                    }
                    icon={<IconCalendarStats size={14} />}
                  >
                    Produção
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
      />
    </Flex>
  );
}

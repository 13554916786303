/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  MultiSelect,
  Select,
  Stepper,
  Switch,
  TextInput,
  Title,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft, IconCheck, IconX } from '@tabler/icons';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CustomLoader } from '../../components/CustomLoader';
import {
  rootNavigate,
  rootNavigateGoBack,
} from '../../components/CustomRouter';

import { Page } from '../../components/Page';
import { useShowTeam, useUpdateTeam } from '../../data/hooks/teams';
import { TeamType } from '../../models/team';
import { TeamConfigAlias, TeamConfigType } from '../../models/team-config';
import { successNotification } from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import { getServicesDispatcher } from '../../store/services';
import { teamTypeList } from '../../utils/constants';
import { AppRoutePaths } from '../../utils/enums';

type FormType = {
  name: string;
  email: string;
  type: string;
  services: number[];
  hasMultipleServices?: boolean;
  multipleServiceLastStep?: number[];
  editOrderPostProduction?: boolean;
};

export function TeamEdit() {
  const { id } = useParams();
  const dispatch = useDispatch<any>();
  const { items: serviceItems, loading: serviceLoading } = useSelector(
    (state: RootState) => state.services,
  );
  const { fetch: updateTeamFetcher, loading: updateTeamLoader } =
    useUpdateTeam();
  const { fetch: showTeamFetcher, loading: showTeamLoader } = useShowTeam();
  const form = useForm<FormType>({
    initialValues: {
      name: '',
      email: '',
      type: '',
      services: [],
      hasMultipleServices: false,
      editOrderPostProduction: false,
      multipleServiceLastStep: [],
    },
  });

  async function handleSubmit(values: typeof form.values) {
    const configs = [];

    if (values?.services?.length) {
      configs.push({
        alias: TeamConfigAlias.SERVICE_FILTER,
        type: TeamConfigType.LIST,
        value: values.services.join(','),
      });
    }

    if (values.hasMultipleServices) {
      configs.push({
        alias: TeamConfigAlias.HAS_MULTIPLE_SERVICES,
        type: TeamConfigType.BOOLEAN,
        value: String(values.hasMultipleServices),
      });
    }

    if (values.hasMultipleServices && values.multipleServiceLastStep?.length) {
      configs.push({
        alias: TeamConfigAlias.SERVICE_PRODUCTION_LAST_STEP,
        type: TeamConfigType.LIST,
        value: values.multipleServiceLastStep.join(','),
      });
    }

    if (values.editOrderPostProduction) {
      configs.push({
        alias: TeamConfigAlias.EDIT_ORDER_POST_PRODUCTION,
        type: TeamConfigType.BOOLEAN,
        value: String(values.editOrderPostProduction),
      });
    }

    await updateTeamFetcher({
      id: Number(id),
      data: {
        email: values.email,
        type: values.type as TeamType,
        name: values.name,
        configs,
      },
      onSuccess: () => {
        rootNavigate(AppRoutePaths.TEAMS);
        successNotification({
          title: 'Dados da equipe atualizado!',
          message: 'tudo certo.',
        });
      },
    });
  }

  async function getTeamDetail() {
    await showTeamFetcher({
      id: Number(id),
      onSuccess: (team) => {
        const techConfig = team.configs.find(
          (item) => item.alias === TeamConfigAlias.SERVICE_FILTER,
        );
        const hasMultipleServices = team.configs.find(
          (item) => item.alias === TeamConfigAlias.HAS_MULTIPLE_SERVICES,
        );
        const servicesLastStepList = team.configs.find(
          (item) => item.alias === TeamConfigAlias.SERVICE_PRODUCTION_LAST_STEP,
        );
        const editOrderPostProduction = team.configs.find(
          (item) => item.alias === TeamConfigAlias.EDIT_ORDER_POST_PRODUCTION,
        );

        form.setValues({
          email: team.email,
          name: team.name,
          type: team.type,
          services: techConfig?.value.split(',')?.map(Number),
          hasMultipleServices: hasMultipleServices?.value === 'true',
          editOrderPostProduction: editOrderPostProduction?.value === 'true',
          multipleServiceLastStep: servicesLastStepList?.value
            .split(',')
            ?.map(Number),
        });
      },
    });
  }

  useEffect(() => {
    getTeamDetail();
  }, []);

  useEffect(() => {
    if (form.values.type === TeamType.TECHNICAL) {
      dispatch(getServicesDispatcher());
    }
  }, [form.values.type]);

  return (
    <Page>
      <Container miw={300}>
        <CustomLoader
          loading={updateTeamLoader || showTeamLoader || serviceLoading}
        />
        <Grid gutter="xs" columns={5}>
          <Grid.Col span={1}>
            <Flex align="center" justify="start">
              <Button
                onClick={() => rootNavigateGoBack()}
                color="dark.1"
                variant="subtle"
                w={40}
                p={0}
              >
                <IconArrowLeft />
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex align="center" justify="center"></Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex align="center" justify="end"></Flex>
          </Grid.Col>
        </Grid>
        <Title size={40} fw="bolder" color="orange" order={1} align="center">
          Editar Equipe
        </Title>
        <Divider m={16} />
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Stepper
            color="orange"
            orientation="horizontal"
            iconSize={37}
            active={0}
          >
            <Stepper.Step label="Equipe" description="dados da equipe">
              <TextInput
                required
                withAsterisk
                label="Nome"
                placeholder="nome da equipe"
                mb={16}
                type="text"
                name="name"
                {...form.getInputProps('name')}
              />
              <TextInput
                required
                withAsterisk
                label="Email"
                placeholder="email para notificações"
                mb={16}
                type="text"
                name="email"
                {...form.getInputProps('email')}
              />
              <Select
                required
                withAsterisk
                name="type"
                label="Tipo de equipe"
                placeholder="selecione o tipo de equipe"
                data={teamTypeList}
                mb={16}
                {...form.getInputProps('type')}
              />
              {form.values.type === TeamType.TECHNICAL && (
                <>
                  <Divider mt={0} mb={14} />
                  <Text size={16} color="dark.4">
                    Configurações
                  </Text>
                  <MultiSelect
                    dropdownPosition="top"
                    maw={320}
                    data={serviceItems.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    label="Serviços relacionados"
                    description="serviços que esta equipe pode ver/produzir"
                    placeholder="selecione os serviços"
                    {...form.getInputProps('services')}
                  />
                  <Switch
                    checked={form.values.hasMultipleServices}
                    color="teal"
                    size="sm"
                    label="atua em multiplos serviços?"
                    mb={8}
                    thumbIcon={
                      form.values.hasMultipleServices ? (
                        <IconCheck size="0.8rem" color="teal" stroke={3} />
                      ) : (
                        <IconX size="0.8rem" color="red" stroke={3} />
                      )
                    }
                    {...form.getInputProps('hasMultipleServices')}
                  />
                  {form.values.hasMultipleServices && (
                    <MultiSelect
                      dropdownPosition="top"
                      maw={320}
                      data={serviceItems.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      label="Serviços de ultima etapa"
                      description="serviços onde esta equipe atua por ultimo"
                      placeholder="selecione os serviços"
                      {...form.getInputProps('multipleServiceLastStep')}
                    />
                  )}
                </>
              )}
              {form.values.type === TeamType.VENDOR && (
                <>
                  <Divider mt={0} mb={14} />
                  <Text size={16} color="dark.4">
                    Configurações
                  </Text>
                  <Switch
                    checked={form.values.editOrderPostProduction}
                    color="teal"
                    size="sm"
                    label="editar pós ˜lib. produção˜?"
                    mb={8}
                    thumbIcon={
                      form.values.editOrderPostProduction ? (
                        <IconCheck size="0.8rem" color="teal" stroke={3} />
                      ) : (
                        <IconX size="0.8rem" color="red" stroke={3} />
                      )
                    }
                    {...form.getInputProps('editOrderPostProduction')}
                  />
                </>
              )}
            </Stepper.Step>
          </Stepper>
          <Group position="right" mt="xl">
            <Button loading={updateTeamLoader} color="dark" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Container>
    </Page>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Select,
  Stepper,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomLoader } from '../../components/CustomLoader';
import { rootNavigateGoBack } from '../../components/CustomRouter';
import { Page } from '../../components/Page';
import { useListOptions } from '../../data/hooks/options';
import { useUpdateUser } from '../../data/hooks/users';
import { getTeamsRequest } from '../../data/services/teams';
import { findUserResquest } from '../../data/services/users';
import { OptionSubTypes, OptionTypes } from '../../models/option';
import { Team } from '../../models/team';
import { User, UserRole, UserType } from '../../models/user';
import { successNotification } from '../../providers/mantine-notifications';

export function UserEdit() {
  const { id } = useParams();
  const [currentStep] = useState(0);
  const [teamList, setTeamList] = useState<Team[]>();
  const [user, setUser] = useState<User>();
  const {
    fetch: listUserTypeOptionsFetcher,
    reponseData: listUserTypeOptionsData,
  } = useListOptions();
  const { fetch: updateUserFetcher, loading: updateUserLoader } =
    useUpdateUser();

  const form = useForm({
    initialValues: {
      username: '',
      email: '',
      password: '',
      type: 'user',
      role: '',
      company: undefined,
      team: undefined,
    },
  });

  async function getUserTypeOptions() {
    await listUserTypeOptionsFetcher({
      query: {
        type: OptionTypes.USER_DATA,
        subtype: OptionSubTypes.USER_TYPE,
      },
    });
  }

  async function getTeams() {
    const response = await getTeamsRequest();
    setTeamList(response);
  }

  async function handleSubmit(values: typeof form.values) {
    await updateUserFetcher({
      id: Number(id),
      data: {
        ...values,
        ...(values.company ? { company: { id: Number(values.company) } } : {}),
        ...(values.team ? { team: { id: Number(values.team) } } : {}),
        type: values.type as UserType,
        role: values.role as UserRole,
      },
      onSuccess: () => {
        rootNavigateGoBack();
        successNotification({
          title: 'Dados atualizados',
          message: 'tudo certo.',
        });
      },
    });
  }

  async function getUserInfo() {
    const response = await findUserResquest(Number(id));
    setUser(response);
  }

  const validateTeamSelector =
    form.values.role === UserRole.VENDOR ||
    form.values.role === UserRole.RECEPTIONIST ||
    form.values.role === UserRole.TECHNICAL ||
    form.values.role === UserRole.HANDLER ||
    form.values.role === UserRole.FINAL_ART ||
    form.values.role === UserRole.PACKER;

  useEffect(() => {
    if (validateTeamSelector) {
      getTeams();
    }
  }, [form.values.role]);

  useEffect(() => {
    getUserInfo();
    getUserTypeOptions();
  }, [id]);

  useEffect(() => {
    if (user) {
      form.setFieldValue('username', user.username);
      form.setFieldValue('role', user.role);
      form.setFieldValue('type', user.type);
      form.setFieldValue('email', user.email);
      form.setFieldValue('password', user.password);
      if (user.company) {
        form.setFieldValue('company', String(user.company) as any);
      }
      if (user.team) {
        form.setFieldValue('team', String(user.team) as any);
      }
    }
  }, [user]);

  const teamOptions = teamList?.map((item) => ({
    label: item.name,
    value: String(item.id),
  }));

  if (!user) return null;

  return (
    <Page>
      <Container>
        <CustomLoader loading={updateUserLoader} />
        <Grid gutter="xs" columns={5}>
          <Grid.Col span={1}>
            <Flex align="center" justify="start">
              <Button
                onClick={() => rootNavigateGoBack()}
                color="dark.1"
                variant="subtle"
                w={40}
                p={0}
              >
                <IconArrowLeft />
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex align="center" justify="center"></Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex align="center" justify="end"></Flex>
          </Grid.Col>
        </Grid>
        <Title size={40} fw="bolder" color="orange" order={1} align="center">
          Editar Usuário
        </Title>
        <Divider m={16} />
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Stepper
            color="orange"
            orientation="horizontal"
            iconSize={37}
            active={currentStep}
          >
            <Stepper.Step label="Usuário" description="dados do usuário">
              <TextInput
                required
                withAsterisk
                label="Usuário"
                placeholder="nome do usuário"
                mb={16}
                type="text"
                name="username"
                onChange={(e) => form.setFieldValue('username', e.target.value)}
                defaultValue={user?.username}
              />
              <TextInput
                required
                withAsterisk
                label="Email"
                placeholder="email do usuário"
                mb={16}
                type="text"
                name="email"
                onChange={(e) => form.setFieldValue('email', e.target.value)}
                defaultValue={user?.email}
              />
              <Select
                searchable
                required
                withAsterisk
                name="role"
                label="Tipo de usuário"
                placeholder="selecione o tipo e usuário"
                data={
                  listUserTypeOptionsData?.map((option) => ({
                    label: option.name,
                    value: option.value,
                  })) ?? []
                }
                mb={16}
                {...form.getInputProps('role')}
                defaultValue={user.role}
              />
              {validateTeamSelector && (
                <Select
                  searchable
                  required
                  withAsterisk
                  name="team"
                  label="Equipe"
                  placeholder="selecione a equipe do usuário"
                  data={teamOptions ?? []}
                  mb={16}
                  {...form.getInputProps('team')}
                  defaultValue={String(user?.team)}
                />
              )}
              <TextInput
                label="Senha"
                placeholder="senha do usuário"
                mb={16}
                type="text"
                name="password"
                minLength={6}
                onChange={(e) => form.setFieldValue('password', e.target.value)}
                defaultValue={user?.password}
              />
            </Stepper.Step>
          </Stepper>
          <Group position="right" mt="xl">
            <Button color="ltpBlue.9" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Container>
    </Page>
  );
}

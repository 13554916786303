import { AxiosResponse } from 'axios';
import { Team } from '../../models/team';
import { api } from '../../providers/base-api';
import { CreateTeamData } from '../../utils/types/data/services/teams';

export async function getTeamsRequest(): Promise<Team[]> {
  const response = await api.get<Team[]>(`teams`);

  return response.data;
}

export async function showTeamRequest(
  id: number,
): Promise<AxiosResponse<Team>> {
  const response = await api.get<Team>(`teams/${id}`);

  return response;
}

export async function createTeamsRequest(data: CreateTeamData): Promise<Team> {
  const response = await api.post<Team>(`teams`, data);

  return response.data;
}

export async function updateTeamsRequest(
  id: number,
  data: CreateTeamData,
): Promise<AxiosResponse<Team>> {
  const response = await api.put<Team>(`teams/${id}`, data);

  return response;
}

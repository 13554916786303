import { useFetch } from '../../../hooks/use-fetch';
import {
  OrderHandling,
  OrderHandlingReport,
} from '../../../models/order-handling';
import { errorNotification } from '../../../providers/mantine-notifications';
import { PaginatedResponse } from '../../../utils/types';
import {
  UseCreateOrderHandlingsProps,
  UseGetLastOrderHandlingsProps,
  UseListHandlingProps,
  UseOrderHandlingsClockoutProps,
  UseOrderHandlingsProps,
} from '../../../utils/types/data/hooks/order-handlings';
import { FindAllOrderHandlingResponse } from '../../../utils/types/data/services/order-handling';
import {
  createOrderHandlingsRequest,
  getLastOrderHandlingsRequest,
  getOrderHandlingReportRequest,
  getOrderHandlingsRequest,
  orderHandlingsClockoutRequest,
} from '../../services/order-handling';

export function useCreateOrderHandlings() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<OrderHandling>();

  const fetch = ({ orderId, data }: UseCreateOrderHandlingsProps) =>
    fetchData({
      fetcher: () => createOrderHandlingsRequest(orderId, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao registrar etapa.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useOrderHandlingsClockout() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<OrderHandling>();

  const fetch = ({ id, data, onSuccess }: UseOrderHandlingsClockoutProps) =>
    fetchData({
      fetcher: () => orderHandlingsClockoutRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao finalizar etapa.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetLastOrderHandlings() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<OrderHandling>();

  const fetch = ({ orderId }: UseGetLastOrderHandlingsProps) =>
    fetchData({
      fetcher: () => getLastOrderHandlingsRequest(orderId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar registro.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetOrderHandlings() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<FindAllOrderHandlingResponse[]>();

  const fetch = ({ orderId }: UseOrderHandlingsProps) =>
    fetchData({
      fetcher: () => getOrderHandlingsRequest(orderId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar listagem.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useListHandlings() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaginatedResponse<OrderHandlingReport>>();

  const fetch = ({ query }: UseListHandlingProps) =>
    fetchData({
      fetcher: () => getOrderHandlingReportRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar relatório de manuseio.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

import {
  Badge,
  Button,
  CopyButton,
  Flex,
  Group,
  Menu,
  MultiSelect,
  Tabs,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  IconCheck,
  IconClock,
  IconDotsVertical,
  IconExclamationMark,
  IconEye,
  IconLoader3,
  IconReceipt2,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useMemo } from 'react';
import { PaymentSlipPageModalVisible } from '..';
import CustomPageFilter from '../../../components/CustomPageFilter';
import { useGetPaymentSlipGroupOsList } from '../../../data/hooks/payment-slip';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  paymentSlipStatusColor,
  paymentSlipStatusHumanized,
} from '../../../utils/constants';
import { formatDateTime } from '../../../utils/helpers';
import { GetFilterCompaniesListResponse } from '../../../utils/types/data/services/filters';
import {
  PaymentSlipPaginatedResponse,
  PaymentSlipResponse,
} from '../../../utils/types/data/services/payment-slip';

interface ReceptionistPaymentSlipProps {
  companyList: GetFilterCompaniesListResponse[];
  filtersCollapse: boolean;
  setFiltersCollapse: React.Dispatch<React.SetStateAction<boolean>>;
  setPageModalVisible: React.Dispatch<
    React.SetStateAction<PaymentSlipPageModalVisible>
  >;
  filterForm: UseFormReturnType<any>;
  handleGetPaymentSlipGroup: (paymentSlipGroup: PaymentSlipResponse) => void;
  getPaymentSlipsGroupFinancial: (isReset?: boolean) => void;
  currentPage: React.MutableRefObject<number>;
  currentPageLimit: React.MutableRefObject<number>;
  activeTab: string | null;
  setActiveTab: React.Dispatch<React.SetStateAction<string | null>>;
  itemsPending?: PaymentSlipPaginatedResponse;
  itemsOsPending?: PaymentSlipPaginatedResponse;
  itemsApproved?: PaymentSlipPaginatedResponse;
  itemsReproved?: PaymentSlipPaginatedResponse;
  itemsToInvoice?: PaymentSlipPaginatedResponse;
  pendingLoading: boolean;
  approvedLoading: boolean;
  reprovedLoading: boolean;
  osPendingLoading: boolean;
  toInvoiceLoading: boolean;
  setOsToCopyFinancial: React.Dispatch<React.SetStateAction<string[]>>;
  osToCopyFinancial: string[];
  setSelectedOrdersToCopyFinancial: React.Dispatch<
    React.SetStateAction<PaymentSlipResponse[]>
  >;
  selectOrdersToCopyFinancial: PaymentSlipResponse[];
}

export function ReceptionistPaymentSlip({
  filterForm,
  filtersCollapse,
  setFiltersCollapse,
  setPageModalVisible,
  companyList,
  handleGetPaymentSlipGroup,
  getPaymentSlipsGroupFinancial,
  activeTab,
  currentPage,
  setActiveTab,
  itemsApproved,
  itemsOsPending,
  itemsPending,
  itemsReproved,
  itemsToInvoice,
  approvedLoading,
  osPendingLoading,
  pendingLoading,
  reprovedLoading,
  toInvoiceLoading,
  currentPageLimit,
  osToCopyFinancial,
  setOsToCopyFinancial,
  selectOrdersToCopyFinancial,
  setSelectedOrdersToCopyFinancial,
}: ReceptionistPaymentSlipProps) {
  const {
    fetch: getPaymentSlipGroupOsListFetcher,
    loading: getPaymentSlipGroupOsListLoader,
    response: getPaymentSlipGroupOsListResponse,
  } = useGetPaymentSlipGroupOsList();

  function handleFilter() {
    getPaymentSlipsGroupFinancial();
  }

  function handleChangePage(pageNumber: number) {
    currentPage.current = pageNumber;
    getPaymentSlipsGroupFinancial();
  }

  function handleChangePageLimit(pageLimitNumber: number) {
    currentPageLimit.current = pageLimitNumber;
    getPaymentSlipsGroupFinancial();
  }

  function handleClear() {
    filterForm.reset();
    getPaymentSlipsGroupFinancial(true);
  }

  function handleSelectOrdersToCopy(paymentGroup: PaymentSlipResponse[]) {
    if (!paymentGroup.length) {
      setSelectedOrdersToCopyFinancial([]);
    }

    if (
      selectOrdersToCopyFinancial.length &&
      selectOrdersToCopyFinancial[0].company.name !==
        paymentGroup[paymentGroup.length - 1].company.name
    ) {
      errorNotification({
        title: 'Não é possivel selecionar comprovantes de clientes diferentes.',
        message: 'revise os dados',
      });

      return;
    }
    setSelectedOrdersToCopyFinancial(paymentGroup);
  }

  function handleCopyOS() {
    const os = osToCopyFinancial.map((os) => os);

    return os.toString();
  }

  useMemo(() => {
    if (getPaymentSlipGroupOsListResponse) {
      setOsToCopyFinancial(getPaymentSlipGroupOsListResponse);
    }
  }, [getPaymentSlipGroupOsListResponse?.length]);

  useEffect(() => {
    if (selectOrdersToCopyFinancial.length) {
      getPaymentSlipGroupOsListFetcher({
        query: {
          paymentSlipGroupIds: selectOrdersToCopyFinancial
            .map((group) => group.paymentSlipGroup)
            .toString(),
        },
      });
    }
  }, [selectOrdersToCopyFinancial.length]);

  useEffect(() => {
    getPaymentSlipsGroupFinancial();
  }, [activeTab]);

  return (
    <Flex direction="column" w="99%">
      <Group position="right" mb={16}>
        <CopyButton value={handleCopyOS() ?? ''} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip
              label={copied ? `OS's Copiadas` : `Copiar OS's`}
              withArrow
              position="right"
            >
              <Button
                color={copied ? 'teal' : 'blue'}
                onClick={copy}
                loading={getPaymentSlipGroupOsListLoader}
                disabled={!selectOrdersToCopyFinancial.length}
              >
                {copied ? `Os's Copiadas` : `Copiar Os's`}
              </Button>
            </Tooltip>
          )}
        </CopyButton>
        <CustomPageFilter.Button
          isCollapsed={filtersCollapse}
          onClick={() => setFiltersCollapse(!filtersCollapse)}
        />
      </Group>

      <CustomPageFilter
        formFilter={filterForm}
        handleClear={handleClear}
        handleSubmit={handleFilter}
        isCollapsed={filtersCollapse}
        customInputs={() => (
          <>
            <TextInput
              label="Código"
              placeholder="digite o cod."
              mb={16}
              mr={8}
              type="text"
              name="code"
              {...filterForm.getInputProps('code')}
            />
            <MultiSelect
              clearable
              searchable
              name="companies"
              label="Clientes"
              placeholder="selecione o cliente"
              data={companyList?.map((item) => ({
                label: item.name,
                value: String(item.id),
              }))}
              mb={16}
              mr={8}
              defaultValue={
                filterForm.values.company &&
                filterForm.values.company.split(',')
              }
              onChange={(values) => {
                filterForm.setFieldValue('companies', values.toString());
              }}
            />
          </>
        )}
      />

      <Tabs
        defaultValue="to-confirm"
        variant="outline"
        radius="md"
        onTabChange={setActiveTab}
      >
        <Tabs.List>
          <Tabs.Tab value="to-confirm" icon={<IconClock size={14} />}>
            À Confirmar
          </Tabs.Tab>
          <Tabs.Tab value="approved" icon={<IconCheck size={14} />}>
            Aprovado
          </Tabs.Tab>
          <Tabs.Tab value="to-invoice" icon={<IconClock size={14} />}>
            À Faturar
          </Tabs.Tab>
          <Tabs.Tab value="to-finish" icon={<IconLoader3 size={14} />}>
            Os's à Finalizar
          </Tabs.Tab>
          <Tabs.Tab value="divergence" icon={<IconExclamationMark size={14} />}>
            Divergência
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="to-confirm" pt={'sm'}>
          <DataTable
            minHeight={500}
            noRecordsText="Sem comprovantes"
            withBorder
            borderRadius="sm"
            striped
            highlightOnHover
            page={currentPage.current}
            totalRecords={itemsPending?.meta.totalItems}
            recordsPerPage={currentPageLimit.current}
            onPageChange={handleChangePage}
            recordsPerPageLabel="Itens por página"
            recordsPerPageOptions={[10, 50, 100, 500]}
            onRecordsPerPageChange={(recordRange) =>
              handleChangePageLimit(recordRange)
            }
            onSelectedRecordsChange={(paymentGroup) => {
              handleSelectOrdersToCopy(paymentGroup);
            }}
            selectedRecords={selectOrdersToCopyFinancial}
            fetching={pendingLoading}
            records={itemsPending?.items ?? []}
            idAccessor="paymentSlipGroup"
            columns={[
              {
                accessor: 'code',
                title: 'Cod.',
              },
              {
                accessor: 'status',
                title: 'Status',
                render: ({ status }) => (
                  <Badge color={paymentSlipStatusColor[status]} mb={6}>
                    {paymentSlipStatusHumanized[status]}
                  </Badge>
                ),
              },
              {
                accessor: 'company.name',
                title: 'Cliente',
              },
              {
                accessor: 'team.name',
                title: 'Equipe',
              },
              {
                accessor: 'createdAt',
                title: 'Data de Criação',
                render: ({ createdAt }) => formatDateTime(createdAt),
              },
              {
                accessor: 'menu',
                title: '',
                render: (paymentSlip) => (
                  <Menu>
                    <Menu.Target>
                      <Button color="blue" variant="subtle" w={40} p={0}>
                        <IconDotsVertical />
                      </Button>
                    </Menu.Target>
                    <Menu.Dropdown style={{ position: 'absolute' }}>
                      <Menu.Item
                        icon={<IconEye size={14} />}
                        onClick={() => {
                          handleGetPaymentSlipGroup(paymentSlip);
                          setPageModalVisible('view');
                        }}
                      >
                        Ver
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
        </Tabs.Panel>

        <Tabs.Panel value="to-invoice" pt={'sm'}>
          <DataTable
            minHeight={500}
            noRecordsText="Sem comprovantes"
            withBorder
            borderRadius="sm"
            striped
            highlightOnHover
            page={currentPage.current}
            totalRecords={itemsToInvoice?.meta.totalItems}
            recordsPerPage={currentPageLimit.current}
            onPageChange={handleChangePage}
            recordsPerPageLabel="Itens por página"
            recordsPerPageOptions={[10, 50, 100, 500]}
            onRecordsPerPageChange={(recordRange) =>
              handleChangePageLimit(recordRange)
            }
            onSelectedRecordsChange={(paymentGroup) => {
              handleSelectOrdersToCopy(paymentGroup);
            }}
            selectedRecords={selectOrdersToCopyFinancial}
            fetching={toInvoiceLoading}
            records={itemsToInvoice?.items ?? []}
            idAccessor="paymentSlipGroup"
            columns={[
              {
                accessor: 'code',
                title: 'Cod.',
              },
              {
                accessor: 'status',
                title: 'Status',
                render: ({ status }) => (
                  <Badge color={paymentSlipStatusColor[status]} mb={6}>
                    {paymentSlipStatusHumanized[status]}
                  </Badge>
                ),
              },
              {
                accessor: 'company.name',
                title: 'Cliente',
              },
              {
                accessor: 'team.name',
                title: 'Equipe',
              },
              {
                accessor: 'createdAt',
                title: 'Data de Criação',
                render: ({ createdAt }) => formatDateTime(createdAt),
              },
              {
                accessor: 'menu',
                title: '',
                render: (paymentSlip) => (
                  <Menu>
                    <Menu.Target>
                      <Button color="blue" variant="subtle" w={40} p={0}>
                        <IconDotsVertical />
                      </Button>
                    </Menu.Target>
                    <Menu.Dropdown style={{ position: 'absolute' }}>
                      <Menu.Item
                        icon={<IconReceipt2 size={14} />}
                        onClick={() => {
                          handleGetPaymentSlipGroup(paymentSlip);
                          setPageModalVisible('analyze');
                        }}
                      >
                        Analisar
                      </Menu.Item>
                      <Menu.Item
                        icon={<IconEye size={14} />}
                        onClick={() => {
                          handleGetPaymentSlipGroup(paymentSlip);
                          setPageModalVisible('view');
                        }}
                      >
                        Ver
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
        </Tabs.Panel>

        <Tabs.Panel value="approved" pt={'sm'}>
          <DataTable
            minHeight={500}
            noRecordsText="Sem comprovantes"
            withBorder
            borderRadius="sm"
            striped
            highlightOnHover
            page={currentPage.current}
            totalRecords={itemsApproved?.meta.totalItems}
            recordsPerPage={currentPageLimit.current}
            onPageChange={(page) => handleChangePage(page)}
            recordsPerPageLabel="Itens por página"
            recordsPerPageOptions={[10, 50, 100, 500]}
            onRecordsPerPageChange={(recordRange) =>
              handleChangePageLimit(recordRange)
            }
            onSelectedRecordsChange={(paymentGroup) => {
              handleSelectOrdersToCopy(paymentGroup);
            }}
            selectedRecords={selectOrdersToCopyFinancial}
            fetching={approvedLoading}
            records={itemsApproved?.items ?? []}
            idAccessor="paymentSlipGroup"
            columns={[
              {
                accessor: 'code',
                title: 'Cod.',
              },
              {
                accessor: 'status',
                title: 'Status',
                render: ({ status }) => (
                  <Badge color={paymentSlipStatusColor[status]} mb={6}>
                    {paymentSlipStatusHumanized[status]}
                  </Badge>
                ),
              },
              {
                accessor: 'company.name',
                title: 'Cliente',
              },
              {
                accessor: 'team.name',
                title: 'Equipe',
              },
              {
                accessor: 'createdAt',
                title: 'Data de Criação',
                render: ({ createdAt }) => formatDateTime(createdAt),
              },
              {
                accessor: 'menu',
                title: '',
                render: (paymentSlip) => (
                  <Menu>
                    <Menu.Target>
                      <Button color="blue" variant="subtle" w={40} p={0}>
                        <IconDotsVertical />
                      </Button>
                    </Menu.Target>
                    <Menu.Dropdown style={{ position: 'absolute' }}>
                      <Menu.Item
                        icon={<IconEye size={14} />}
                        onClick={() => {
                          handleGetPaymentSlipGroup(paymentSlip);
                          setPageModalVisible('view');
                        }}
                      >
                        Ver
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
        </Tabs.Panel>

        <Tabs.Panel value="to-finish" pt={'sm'}>
          <DataTable
            minHeight={500}
            noRecordsText="Sem comprovantes"
            withBorder
            borderRadius="sm"
            striped
            highlightOnHover
            page={currentPage.current}
            totalRecords={itemsOsPending?.meta.totalItems}
            recordsPerPage={currentPageLimit.current}
            onPageChange={(page) => handleChangePage(page)}
            recordsPerPageLabel="Itens por página"
            recordsPerPageOptions={[10, 50, 100, 500]}
            onRecordsPerPageChange={(recordRange) =>
              handleChangePageLimit(recordRange)
            }
            onSelectedRecordsChange={(paymentGroup) => {
              handleSelectOrdersToCopy(paymentGroup);
            }}
            selectedRecords={selectOrdersToCopyFinancial}
            fetching={osPendingLoading}
            records={itemsOsPending?.items ?? []}
            idAccessor="paymentSlipGroup"
            columns={[
              {
                accessor: 'code',
                title: 'Cod.',
              },
              {
                accessor: 'status',
                title: 'Status',
                render: ({ status }) => (
                  <Badge color={paymentSlipStatusColor[status]} mb={6}>
                    {paymentSlipStatusHumanized[status]}
                  </Badge>
                ),
              },
              {
                accessor: 'company.name',
                title: 'Cliente',
              },
              {
                accessor: 'team.name',
                title: 'Equipe',
              },
              {
                accessor: 'createdAt',
                title: 'Data de Criação',
                render: ({ createdAt }) => formatDateTime(createdAt),
              },
              {
                accessor: 'menu',
                title: '',
                render: (paymentSlip) => (
                  <Menu>
                    <Menu.Target>
                      <Button color="blue" variant="subtle" w={40} p={0}>
                        <IconDotsVertical />
                      </Button>
                    </Menu.Target>
                    <Menu.Dropdown style={{ position: 'absolute' }}>
                      <Menu.Item
                        icon={<IconEye size={14} />}
                        onClick={() => {
                          handleGetPaymentSlipGroup(paymentSlip);
                          setPageModalVisible('view');
                        }}
                      >
                        Ver
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
        </Tabs.Panel>

        <Tabs.Panel value="divergence" pt={'sm'}>
          <DataTable
            minHeight={500}
            noRecordsText="Sem comprovantes"
            withBorder
            borderRadius="sm"
            striped
            highlightOnHover
            page={currentPage.current}
            totalRecords={itemsReproved?.meta.totalItems}
            recordsPerPage={currentPageLimit.current}
            onPageChange={(page) => handleChangePage(page)}
            recordsPerPageLabel="Itens por página"
            recordsPerPageOptions={[10, 50, 100, 500]}
            onRecordsPerPageChange={(recordRange) =>
              handleChangePageLimit(recordRange)
            }
            onSelectedRecordsChange={(paymentGroup) => {
              handleSelectOrdersToCopy(paymentGroup);
            }}
            selectedRecords={selectOrdersToCopyFinancial}
            fetching={reprovedLoading}
            records={itemsReproved?.items ?? []}
            idAccessor="paymentSlipGroup"
            columns={[
              {
                accessor: 'code',
                title: 'Cod.',
              },
              {
                accessor: 'status',
                title: 'Status',
                render: ({ status }) => (
                  <Badge color={paymentSlipStatusColor[status]} mb={6}>
                    {paymentSlipStatusHumanized[status]}
                  </Badge>
                ),
              },
              {
                accessor: 'company.name',
                title: 'Cliente',
              },
              {
                accessor: 'team.name',
                title: 'Equipe',
              },
              {
                accessor: 'createdAt',
                title: 'Data de Criação',
                render: ({ createdAt }) => formatDateTime(createdAt),
              },
              {
                accessor: 'menu',
                title: '',
                render: (paymentSlip) => (
                  <Menu>
                    <Menu.Target>
                      <Button color="blue" variant="subtle" w={40} p={0}>
                        <IconDotsVertical />
                      </Button>
                    </Menu.Target>
                    <Menu.Dropdown style={{ position: 'absolute' }}>
                      <Menu.Item
                        icon={<IconEye size={14} />}
                        onClick={() => {
                          handleGetPaymentSlipGroup(paymentSlip);
                          setPageModalVisible('view');
                        }}
                      >
                        Ver
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
              },
            ]}
          />
        </Tabs.Panel>
      </Tabs>
    </Flex>
  );
}

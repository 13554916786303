import { Select } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { FormFilterType } from '..';
import { useListTag } from '../../../data/hooks/tags';
import { useState } from 'react';

interface DynamicSelectInputProps {
  target: any;
  formFilter: UseFormReturnType<
    FormFilterType,
    (values: FormFilterType) => FormFilterType
  >;
  label: string;
  placeholder: string;
}

export function DynamicSelectInput({
  target,
  formFilter,
  label,
  placeholder,
}: DynamicSelectInputProps) {
  const [, setIsFocused] = useState(false);

  const { fetch: listTagFetcher, reponseData: listTagData } = useListTag();

  const handleOnFocus = () => {
    setIsFocused(true);
    listTagFetcher({
      query: {
        type: target,
      },
    });
  };

  return (
    <Select
      name={target}
      label={label}
      placeholder={placeholder}
      searchable
      mb={16}
      mr={8}
      data={
        listTagData?.map((item) => ({
          label: item.name,
          value: String(item.id),
        })) || []
      }
      {...formFilter.getInputProps(target)}
      onFocus={handleOnFocus}
    />
  );
}

import { useFetch } from '../../../hooks/use-fetch';
import {
  OrderProduction,
  OrderProductionReport,
} from '../../../models/order-production';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import { PaginatedResponse } from '../../../utils/types';
import {
  UseAddOrderProductionManuallyProps,
  UseChangeManyOrderProductionProps,
  UseIsOrderProductionPartialFinishedProps,
  UseListProductionsProps,
  UseSetClockoutOrderProductionProps,
  UseSetOrderProductionPartialFinishProps,
} from '../../../utils/types/data/hooks/order-productions';
import { IsPartialFinishedResponse } from '../../../utils/types/data/services/order-productions';
import {
  addOrderProductionManuallyRequest,
  changeManyOrderProductionRequest,
  getOrderProductionIsPartialFinishedRequest,
  getOrderProductionReportRequest,
  serOrderProductionPartialFinishRequest,
  setOutProductionOrderProductionRequest,
} from '../../services/order-production';

export function useChangeManyOrderProduction() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseChangeManyOrderProductionProps) =>
    fetchData({
      fetcher: () => changeManyOrderProductionRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao atualizar produção.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useListProductions() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaginatedResponse<OrderProductionReport>>();

  const fetch = ({ query }: UseListProductionsProps) =>
    fetchData({
      fetcher: () => getOrderProductionReportRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar equipes.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useSetClockOutOrderProduction() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<OrderProduction>();

  const fetch = ({ id, data, onSuccess }: UseSetClockoutOrderProductionProps) =>
    fetchData({
      fetcher: () => setOutProductionOrderProductionRequest(id, data),
      onError: (error: any) => {
        errorNotification({
          title: translateServerHttpErrors(error, 'Erro ao finalizar etapa.'),
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useSetOrderProductionPartialFinish() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({
    orderId,
    onSuccess,
  }: UseSetOrderProductionPartialFinishProps) =>
    fetchData({
      fetcher: () => serOrderProductionPartialFinishRequest(orderId),
      onError: (error: any) => {
        errorNotification({
          title: translateServerHttpErrors(error, 'Erro ao finalizar etapa.'),
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useIsOrderProductionPartialFinished() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<IsPartialFinishedResponse>();

  const fetch = ({
    orderId,
    onSuccess,
  }: UseIsOrderProductionPartialFinishedProps) =>
    fetchData({
      fetcher: () => getOrderProductionIsPartialFinishedRequest(orderId),
      onError: () => {
        errorNotification({
          title: 'Erro ao verificar produção.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useAddOrderProductionManually() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({
    orderId,
    data,
    onSuccess,
  }: UseAddOrderProductionManuallyProps) =>
    fetchData({
      fetcher: () => addOrderProductionManuallyRequest(orderId, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao adicionar produção.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
